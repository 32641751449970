@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");


.popup_order {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 11px -3px;
}

.order_page_text {
  padding: 2px 6px;
  border-radius: 9px;
}

.order_page_text:hover {
  background: #eaeaea;
  transition: all 0.2s linear;
}



.blocks_cards {
  background-image: url("https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIyLTA1L3RwMjU2LWJhY2tncm91bmQtMDgteC5qcGc.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 0px 12px -3px !important;
}

.charts_block {
  width: 50vw;
}

.charts_block1 {
  width: 40vw;
}

.main_caed_block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

@media screen and (max-width: 1000px) {
  .bottom_dashboard {
    flex-direction: column;
  }

  .main_caed_block {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .charts_block {
    width: 70%;
  }

  .charts_block1 {
    width: 70%;
  }
}

@media screen and (max-width: 500px) {
  .order_upper_block {
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (max-width: 630px) {
  .main_caed_block {
    grid-template-columns: 1fr 1fr;
  }

  .charts_block {
    width: 85%;
  }

  .charts_block1 {
    width: 85%;
  }


}

@media screen and (max-width: 450px) {

  .main_caed_block {
    grid-template-columns: 1fr;
  }
}

/*===GENERAL===*/
.site-text-primary {
  color: #022279;
}

.site-text-black {
  color: #000;
}

.site-text-gray {
  color: #e7e7e7;
}

.site-text-gray-light {
  color: #f9f9f9;
}

.site-text-white {
  color: #fff;
}

.site-bg-primary {
  background-color: #022279;
}

.site-bg-black {
  background-color: #000;
}

.site-bg-dark {
  background-color: #000;
}

.site-bg-gray {
  background-color: #eff3f6;
}

.site-bg-gray-light {
  background-color: #f9f9f9;
}

.site-bg-light {
  background-color: #fff9f0;
}

.site-bg-white {
  background-color: #fff;
}

body {
  color: #626262;
  font-family: "Mulish";
  line-height: 1.7;
  font-weight: normal;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  font-size: 14px;
}

a {
  color: #000;
  outline: 0px none;
  text-decoration: none;
}

a:hover,
a:focus {
  outline: 0px none;
  text-decoration: none;
}

a:active,
a:hover,
a:focus {
  color: inherit;
}

.overflow-hide {
  overflow: hidden;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

/*Float clearfix*/
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.shadow-bx {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

ol.commentlist li:after {
  content: "";
  display: table;
  clear: both;
}

section,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary {
  display: block;
}

ul {
  padding: 0px;
}

::selection {
  background: #484848;
  color: #fff;
}

.form-select option {
  font-family: tahoma;
  font-size: 14px;
}

/*===Heading & Title====*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Mulish";
  line-height: 1.2;
  color: #222;
}

h1 {
  font-size: 60px;
  font-weight: 700;
}

h2 {
  font-size: 40px;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  font-weight: 700;
}

h5 {
  font-size: 16px;
  font-weight: 700;
}

h6 {
  font-size: 14px;
  font-weight: 700;
}

/*---Text align--- */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/*===Listing Style===*/
dl,
ul,
ol {
  list-style-position: outside;
  padding: 0px;
}

ul,
ol {
  margin-bottom: 24px;
}

ul li {
  padding: 0px;
}

ol li {
  padding: 0px;
}

.list-simple li {
  margin-bottom: 10px;
}

.list-simple li ul {
  margin-left: 15px;
  margin-top: 10px;
}

.list-simple.list-unstyled li ul {
  margin-left: 30px;
  margin-top: 10px;
}

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
  margin: 0px 0px 20px 0px;
  padding: 0px;
  list-style: none;
  font-weight: 600;
}

.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-check-circle li,
.list-chevron-circle li,
.list-arrow-circle li,
.list-times-circle li {
  padding: 5px 5px 5px 20px;
  position: relative;
}

.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-check-circle li:before,
.list-chevron-circle li:before,
.list-arrow-circle li:before,
.list-times-circle li:before {
  font-family: "FontAwesome";
  position: absolute;
  left: 0px;
  top: 3px;
  display: block;
  font-size: 15px;
  color: #000;
}

.list-circle li:before {
  top: 6px;
  font-size: 10px;
  content: "\f111";
}

.list-angle-right li:before {
  content: "\f105";
}

.list-arrow li:before {
  content: "\f0da";
}

.list-check li:before {
  content: "\f00c";
}

.list-checked li:before {
  content: "\f046";
}

.list-check-circle li:before {
  content: "\f058";
}

.list-chevron-circle li:before {
  content: "\f138";
}

.list-arrow-circle li:before {
  content: "\f0a9";
}

.list-times-circle li:before {
  content: "\f057";
}

/*---Padding (around)---*/
.p-a0 {
  padding: 0px;
}

.p-a5 {
  padding: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-a40 {
  padding: 40px;
}

.p-a50 {
  padding: 50px;
}

.p-a60 {
  padding: 60px;
}

.p-a70 {
  padding: 70px;
}

.p-a80 {
  padding: 80px;
}

.p-a90 {
  padding: 90px;
}

.p-a100 {
  padding: 100px;
}

.p-a110 {
  padding: 110px;
}

.p-a120 {
  padding: 120px;
}

.p-a130 {
  padding: 130px;
}

.p-a140 {
  padding: 140px;
}

.p-a150 {
  padding: 150px;
}

/*---Padding (top)---*/
.p-t0 {
  padding-top: 0px;
}

.p-t5 {
  padding-top: 5px;
}

.p-t10 {
  padding-top: 10px;
}

.p-t15 {
  padding-top: 15px;
}

.p-t20 {
  padding-top: 20px;
}

.p-t30 {
  padding-top: 30px;
}

.p-t40 {
  padding-top: 40px;
}

.p-t50 {
  padding-top: 50px;
}

.p-t60 {
  padding-top: 60px;
}

.p-t70 {
  padding-top: 70px;
}

.p-t80 {
  padding-top: 80px;
}

.p-t90 {
  padding-top: 90px;
}

.p-t100 {
  padding-top: 100px;
}

.p-t110 {
  padding-top: 110px;
}

.p-t120 {
  padding-top: 120px;
}

.p-t130 {
  padding-top: 130px;
}

.p-t140 {
  padding-top: 140px;
}

.p-t150 {
  padding-top: 150px;
}

/*---Padding (bottom)---*/
.p-b0 {
  padding-bottom: 0px;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-b110 {
  padding-bottom: 110px;
}

.p-b120 {
  padding-bottom: 120px;
}

.p-b130 {
  padding-bottom: 130px;
}

.p-b140 {
  padding-bottom: 140px;
}

.p-b150 {
  padding-bottom: 150px;
}

/*---Padding (left)---*/
.p-l0 {
  padding-left: 0px;
}

.p-l5 {
  padding-left: 5px;
}

.p-l10 {
  padding-left: 10px;
}

.p-l15 {
  padding-left: 15px;
}

.p-l20 {
  padding-left: 20px;
}

.p-l30 {
  padding-left: 30px;
}

.p-l40 {
  padding-left: 40px;
}

.p-l50 {
  padding-left: 50px;
}

.p-l60 {
  padding-left: 60px;
}

.p-l70 {
  padding-left: 70px;
}

.p-l80 {
  padding-left: 80px;
}

.p-l90 {
  padding-left: 90px;
}

.p-l100 {
  padding-left: 100px;
}

.p-l110 {
  padding-left: 110px;
}

.p-l120 {
  padding-left: 120px;
}

.p-l130 {
  padding-left: 130px;
}

.p-l140 {
  padding-left: 140px;
}

.p-l150 {
  padding-left: 150px;
}

/*---Padding (right)---*/
.p-r0 {
  padding-right: 0px;
}

.p-r5 {
  padding-right: 5px;
}

.p-r10 {
  padding-right: 10px;
}

.p-r15 {
  padding-right: 15px;
}

.p-r20 {
  padding-right: 20px;
}

.p-r30 {
  padding-right: 30px;
}

.p-r40 {
  padding-right: 40px;
}

.p-r50 {
  padding-right: 50px;
}

.p-r60 {
  padding-right: 60px;
}

.p-r70 {
  padding-right: 70px;
}

.p-r80 {
  padding-right: 80px;
}

.p-r90 {
  padding-right: 90px;
}

.p-r100 {
  padding-right: 100px;
}

.p-r110 {
  padding-right: 110px;
}

.p-r120 {
  padding-right: 120px;
}

.p-r130 {
  padding-right: 130px;
}

.p-r140 {
  padding-right: 140px;
}

.p-r150 {
  padding-right: 150px;
}

/*---Padding (left right)---*/
.p-lr0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-lr120 {
  padding-left: 120px;
  padding-right: 120px;
}

.p-lr150 {
  padding-left: 150px;
  padding-right: 150px;
}

/*---Padding (top bottom)---*/
.p-tb0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.p-tb120 {
  padding-bottom: 120px;
  padding-top: 120px;
}

.p-tb150 {
  padding-bottom: 150px;
  padding-top: 150px;
}

/*----Margin (around)----*/
.m-a-1 {
  margin: -1px;
}

.m-a0 {
  margin: 0px;
}

.m-a5 {
  margin: 5px;
}

.m-a10 {
  margin: 10px;
}

.m-a15 {
  margin: 15px;
}

.m-a20 {
  margin: 20px;
}

.m-a30 {
  margin: 30px;
}

.m-a40 {
  margin: 40px;
}

.m-a50 {
  margin: 50px;
}

.m-a60 {
  margin: 60px;
}

.m-a70 {
  margin: 70px;
}

.m-a80 {
  margin: 80px;
}

.m-a90 {
  margin: 90px;
}

.m-a100 {
  margin: 100px;
}

.m-a110 {
  margin: 110px;
}

.m-a120 {
  margin: 120px;
}

.m-a130 {
  margin: 130px;
}

.m-a140 {
  margin: 140px;
}

.m-a150 {
  margin: 150px;
}

/*---Marging (top)----*/
.m-t0 {
  margin-top: 0px;
}

.m-t5 {
  margin-top: 5px;
}

.m-t10 {
  margin-top: 10px;
}

.m-t15 {
  margin-top: 15px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t30 {
  margin-top: 30px;
}

.m-t40 {
  margin-top: 40px;
}

.m-t50 {
  margin-top: 50px;
}

.m-t60 {
  margin-top: 60px;
}

.m-t70 {
  margin-top: 70px;
}

.m-t80 {
  margin-top: 80px;
}

.m-t90 {
  margin-top: 90px;
}

.m-t100 {
  margin-top: 100px;
}

.m-t110 {
  margin-top: 110px;
}

.m-t120 {
  margin-top: 120px;
}

.m-t130 {
  margin-top: 130px;
}

.m-t140 {
  margin-top: 140px;
}

.m-t150 {
  margin-top: 150px;
}

/*---Marging (bottom)---*/
.m-b0 {
  margin-bottom: 0px;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-b110 {
  margin-bottom: 110px;
}

.m-b120 {
  margin-bottom: 120px;
}

.m-b130 {
  margin-bottom: 130px;
}

.m-b140 {
  margin-bottom: 140px;
}

.m-b150 {
  margin-bottom: 150px;
}

/*---Marging (left)---*/
.m-l0 {
  margin-left: 0px;
}

.m-l5 {
  margin-left: 5px;
}

.m-l10 {
  margin-left: 10px;
}

.m-l15 {
  margin-left: 15px;
}

.m-l20 {
  margin-left: 20px;
}

.m-l30 {
  margin-left: 30px;
}

.m-l40 {
  margin-left: 40px;
}

.m-l50 {
  margin-left: 50px;
}

.m-l60 {
  margin-left: 60px;
}

.m-l70 {
  margin-left: 70px;
}

.m-l80 {
  margin-left: 80px;
}

.m-l90 {
  margin-left: 90px;
}

.m-l100 {
  margin-left: 100px;
}

.m-l110 {
  margin-left: 110px;
}

.m-l120 {
  margin-left: 120px;
}

.m-l130 {
  margin-left: 130px;
}

.m-l140 {
  margin-left: 140px;
}

.m-l150 {
  margin-left: 150px;
}

/*---Marging (right)---*/
.m-r0 {
  margin-right: 0px;
}

.m-r5 {
  margin-right: 5px;
}

.m-r10 {
  margin-right: 10px;
}

.m-r15 {
  margin-right: 15px;
}

.m-r20 {
  margin-right: 20px;
}

.m-r30 {
  margin-right: 30px;
}

.m-r40 {
  margin-right: 40px;
}

.m-r50 {
  margin-right: 50px;
}

.m-r60 {
  margin-right: 60px;
}

.m-r70 {
  margin-right: 70px;
}

.m-r80 {
  margin-right: 80px;
}

.m-r90 {
  margin-right: 90px;
}

.m-r100 {
  margin-right: 100px;
}

.m-r110 {
  margin-right: 110px;
}

.m-r120 {
  margin-right: 120px;
}

.m-r130 {
  margin-right: 130px;
}

.m-r140 {
  margin-right: 140px;
}

.m-r150 {
  margin-right: 150px;
}

/*---Marging (left right)---*/
.m-lr0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-lr120 {
  margin-left: 120px;
  margin-right: 120px;
}

.m-lr150 {
  margin-left: 150px;
  margin-right: 150px;
}

/*---Marging (top bottom)---*/
.m-tb0 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

.m-tb120 {
  margin-bottom: 120px;
  margin-top: 120px;
}

.m-tb150 {
  margin-bottom: 150px;
  margin-top: 150px;
}

/*---Colum gap less---*/
.no-col-gap [class*="col-xs-"],
.no-col-gap [class*="col-sm-"],
.no-col-gap [class*="col-md-"],
.no-col-gap [class*="col-lg-"] {
  padding-left: 0px;
  padding-right: 0px;
}

/*===Background Effect===*/
.bg-center {
  background-position: center;
}

.bg-top-left {
  background-position: top left;
}

.bg-top-right {
  background-position: top right;
}

.bg-top-center {
  background-position: top center;
}

.bg-bottom-left {
  background-position: bottom left;
}

.bg-bottom-right {
  background-position: bottom right;
}

.bg-bottom-center {
  background-position: bottom center;
}

.bg-left-center {
  background-position: left;
}

.bg-right-center {
  background-position: right;
}

.bg-auto {
  background-size: auto;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-full-width {
  background-size: 100% auto;
}

.bg-full-height {
  background-size: auto 100%;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-scroll {
  background-attachment: scroll;
}

/*===Overlay ( transparient box style)===*/
.overlay-wraper {
  position: relative;
}

.overlay-main {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.overlay-light .overlay-main {
  opacity: 0.3;
}

.overlay-dark .overlay-main {
  opacity: 0.9;
}

.overlay-wraper>.container,
.overlay-wraper>.container-fluid,
.overlay-wraper>.wt-icon-box-wraper,
.overlay-wraper>.wt-left-part,
.overlay-wraper>.wt-right-part {
  position: relative;
  z-index: 1;
}

/*===Boxes Css===*/
.rounded-bx,
.wt-box,
.wt-icon-box,
.wt-icon-box-small,
.wt-thum-bx,
.wt-post-thum {
  position: relative;
}

/*---Box content----*/
.wt-box {
  position: relative;
}

.wt-box.no-margin {
  margin-bottom: 0;
}

/*---For icons with box---*/
.wt-icon-box-xld {
  width: 180px;
  height: 180px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-xld i {
  font-size: 100px;
}

.wt-icon-box-xld img {
  height: 90px;
}

.wt-icon-box-xl {
  width: 150px;
  height: 150px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-xl i {
  font-size: 80px;
}

.wt-icon-box-xl img {
  height: 80px;
}

.wt-icon-box-lg {
  width: 120px;
  height: 120px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-lg i {
  font-size: 60px;
}

.wt-icon-box-lg img {
  height: 55px;
}

.wt-icon-box-md {
  width: 100px;
  height: 100px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-md i {
  font-size: 45px;
}

.wt-icon-box-md img {
  height: 40px;
}

.wt-icon-box-sm {
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-sm i {
  font-size: 30px;
}

.wt-icon-box-sm img {
  height: 30px;
}

.wt-icon-box-xs {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
}

.wt-icon-box-xs i {
  font-size: 20px;
}

.wt-icon-box-xs img {
  height: 20px;
}

.wt-icon-box-xld.radius,
.wt-icon-box-xl.radius,
.wt-icon-box-lg.radius,
.wt-icon-box-md.radius,
.wt-icon-box-sm.radius,
.wt-icon-box-xs.radius {
  border-radius: 100%;
}

.wt-icon-box-xld i,
.wt-icon-box-xl i,
.wt-icon-box-lg i,
.wt-icon-box-md i,
.wt-icon-box-sm i,
.wt-icon-box-xs i {
  vertical-align: middle;
}

.wt-icon-box-xld img,
.wt-icon-box-xl img,
.wt-icon-box-lg img,
.wt-icon-box-md img,
.wt-icon-box-sm img,
.wt-icon-box-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

/*----For only icons---*/
.icon-xld,
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
}

.icon-xld i,
.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
  vertical-align: middle;
}

.icon-xld img,
.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.icon-xld {
  width: 120px;
}

.icon-xld i {
  font-size: 100px;
  line-height: 100px;
}

.icon-xld.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-xld img {
  height: 90px;
}

.icon-xld.inline-icon {
  width: auto;
}

.icon-xl {
  width: 100px;
}

.icon-xl i {
  font-size: 80px;
  line-height: 80px;
}

.icon-xl.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-xl img {
  height: 80px;
}

.icon-xl.inline-icon {
  width: auto;
}

.icon-lg {
  width: 80px;
}

.icon-lg i {
  font-size: 60px;
  line-height: 60px;
}

.icon-lg.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-lg img {
  height: 70px;
}

.icon-lg.inline-icon {
  width: auto;
}

.icon-md {
  width: 60px;
}

.icon-md i {
  font-size: 45px;
  line-height: 45px;
}

.icon-md.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-md img {
  height: 50px;
}

.icon-md.inline-icon {
  width: auto;
}

.icon-sm {
  width: 40px;
}

.icon-sm i {
  font-size: 30px;
  line-height: 30px;
}

.icon-sm.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-sm img {
  height: 30px;
}

.icon-sm.inline-icon {
  width: auto;
}

.icon-xs {
  width: 30px;
}

.icon-xs i {
  font-size: 20px;
  line-height: 20px;
}

.icon-xs.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-xs img {
  height: 20px;
}

.icon-xs.inline-icon {
  width: auto;
}

/*---Place icon with box---*/
.wt-icon-box-wraper {
  position: relative;
}

.wt-icon-box-wraper .wt-tilte {
  margin-top: 0;
}

.wt-icon-box-wraper .after-titile-line {
  margin-bottom: 10px;
}

.wt-icon-box-wraper p:last-child {
  margin: 0px;
}

.icon-content {
  overflow: hidden;
}

.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
  display: table;
}

.wt-icon-box-xld .icon-cell,
.wt-icon-box-xl .icon-cell,
.wt-icon-box-lg .icon-cell,
.wt-icon-box-md .icon-cell,
.wt-icon-box-sm .icon-cell,
.wt-icon-box-xs .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

/*---Icon box left align---*/
.wt-icon-box-wraper.left .wt-icon-box-xld,
.wt-icon-box-wraper.left .wt-icon-box-xl,
.wt-icon-box-wraper.left .wt-icon-box-lg,
.wt-icon-box-wraper.left .wt-icon-box-md,
.wt-icon-box-wraper.left .wt-icon-box-sm,
.wt-icon-box-wraper.left .wt-icon-box-xs {
  float: left;
  margin-right: 10px;
}

.wt-icon-box-wraper.left .icon-xld,
.wt-icon-box-wraper.left .icon-xl,
.wt-icon-box-wraper.left .icon-lg,
.wt-icon-box-wraper.left .icon-md,
.wt-icon-box-wraper.left .icon-sm,
.wt-icon-box-wraper.left .icon-xs {
  float: left;
  margin-right: 10px;
}

/*---Icon box right align---*/
.wt-icon-box-wraper.right {
  text-align: right;
}

.wt-icon-box-wraper.right .wt-icon-box-xld,
.wt-icon-box-wraper.right .wt-icon-box-xl,
.wt-icon-box-wraper.right .wt-icon-box-lg,
.wt-icon-box-wraper.right .wt-icon-box-md,
.wt-icon-box-wraper.right .wt-icon-box-sm,
.wt-icon-box-wraper.right .wt-icon-box-xs {
  float: right;
  display: table;
  margin-left: 20px;
}

.wt-icon-box-wraper.right .icon-xld,
.wt-icon-box-wraper.right .icon-xl,
.wt-icon-box-wraper.right .icon-lg,
.wt-icon-box-wraper.right .icon-md,
.wt-icon-box-wraper.right .icon-sm,
.wt-icon-box-wraper.right .icon-xs {
  float: right;
  margin-left: 20px;
}

/*---Icon box center align---*/
.wt-icon-box-wraper.center {
  text-align: center;
}

.wt-icon-box-wraper.center .wt-icon-box-xld,
.wt-icon-box-wraper.center .wt-icon-box-xl,
.wt-icon-box-wraper.center .wt-icon-box-lg,
.wt-icon-box-wraper.center .wt-icon-box-md,
.wt-icon-box-wraper.center .wt-icon-box-sm,
.wt-icon-box-wraper.center .wt-icon-box-xs {
  margin-left: auto;
  margin-right: auto;
}

.wt-icon-box-wraper.bx-style-1 {
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
}

.wt-icon-box-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
}

.wt-icon-box-wraper.bx-style-2.center [class*="wt-icon-box-"] {
  position: absolute;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%);
}

.wt-icon-box-wraper.bx-style-2.left [class*="wt-icon-box-"] {
  position: absolute;
}

.wt-icon-box-wraper.bx-style-2.right [class*="wt-icon-box-"] {
  position: absolute;
}

/*---Buttons--- */
.site-button-link {
  position: relative;
  color: #ff8a00;
  font-size: 16px;
  z-index: 1;
  transition: 0.5s all ease;
  /*hover block section*/
}

.site-button-link:hover {
  color: #000;
}


/* Theme default button */
.site-button {
  outline: none;
  color: #fff;
  padding: 10px 20px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-table;
  background-color: #ff8a00;
  border: none;
  font-size: 15px;
  transition: 0.5s all ease;
  border-radius: 5px;
}

.site-button i {
  padding-right: 5px;
}

.site-button:hover,
.site-button:active,
.site-button:focus {
  color: #fff;
}

.btn-hover-animation {
  z-index: 1;
  overflow: hidden;
  display: inline-block;
}

.btn-hover-animation:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -4px;
  left: -14px;
  background: rgba(0, 0, 0, 0.1);
  height: 50px;
  width: 50px;
  border-radius: 50px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 1s ease-out;
  transition: transform 1s ease-out;
}

.btn-hover-animation:hover:before {
  transform: scale(8);
}

.site-button-secondry {
  color: #fff;
  padding: 15px 40px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  background-color: #000;
  border: none;
  font-size: 15px;
  font-weight: 700;
}

.site-button-secondry.sb-bdr-light {
  border-right: 6px solid #fff;
}

.site-button-secondry:active,
.site-button-secondry:focus,
.site-button-secondry:visited {
  background-color: #000;
  color: #fff;
}

.site-button-secondry:hover {
  background-color: #e7e7e7;
  color: #000;
}

.mfp-video.video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  margin: -25px;
  color: #fff;
  display: block;
  z-index: 10;
  font-size: 14px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.mfp-video.video-play-btn:hover {
  background-color: #0f1221;
  color: #fff;
  border: 2px solid transparent;
}

.mfp-video.video-play-btn i {
  margin-right: -5px;
}

/*Button text uppercase */
.site-button.text-uppercase {
  text-transform: uppercase;
}

/*Button size */
.button-sm {
  padding: 5px 10px;
  font-size: 12px;
}

.button-lg {
  padding: 15px 30px;
  font-size: 16px;
}

.button-xl {
  padding: 30px 50px;
  font-size: 24px;
}

/*Button rounded */
.radius-no {
  border-radius: 0px;
}

.radius-sm {
  border-radius: 10px;
}

.radius-md {
  border-radius: 20px;
}

.radius-xl {
  border-radius: 100px;
}

.radius-bx {
  border-radius: 100%;
}

/*===Owl Slider===*/
.owl-carousel .owl-nav {
  margin-top: 40px;
  text-align: center;
}

.owl-carousel .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

/*---Owl dots button---*/
.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 30px;
}

.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  outline: none !important;
}

.owl-carousel .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  margin: 5px 7px;
  background: #8a8a8a;
  border: 2px solid transparent;
  display: block;
  border-radius: 50%;
  transition: opacity 200ms ease;
}

.owl-carousel .owl-dots .owl-dot:hover span {
  background: #ff8a00;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: none;
  border: 2px solid #8a8a8a;
  margin: 2px;
  height: 12px;
  width: 12px;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #fff;
}

/*---Owl button top to center---*/
.owl-btn-top-center .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.owl-btn-top-left .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.owl-btn-top-right .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.owl-btn-top-center .owl-stage-outer {
  padding-top: 60px;
}

.owl-btn-top-left .owl-stage-outer {
  padding-top: 60px;
}

.owl-btn-top-right .owl-stage-outer {
  padding-top: 60px;
}

.owl-btn-top-center .owl-nav {
  text-align: center;
}

/*---Owl button top to left---*/
.owl-btn-top-left .owl-nav {
  text-align: left;
}

/*---Owl button top to right---*/
.owl-btn-top-right .owl-nav {
  text-align: right;
}

/*---Owl button bottom to center [it is default position ]---*/
.owl-btn-bottom-center .owl-nav {
  text-align: center;
}

/*---Owl button bottom to left---*/
.owl-btn-bottom-left .owl-nav {
  text-align: left;
}

/*Owl button bottom to right*/
.owl-btn-bottom-right .owl-nav {
  text-align: right;
}

/*---Owl button vertical to center--*/
.owl-btn-vertical-center .owl-nav {
  margin: 0px;
}

.owl-btn-vertical-center .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  margin: -15px 0;
  left: 0;
}

.owl-btn-vertical-center .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  margin: -15px 0;
  right: 0;
}

/*---Owl button show on hover---*/
.owl-btn-hover .owl-nav {
  opacity: 0;
}

.owl-btn-hover:hover .owl-nav {
  opacity: 1;
}

.owl-carousel .owl-item img {
  transform-style: inherit;
}

/*---Owl slider button---*/
.owl-carousel .owl-nav button.owl-prev {
  color: #fff !important;
  padding: 14px 14px !important;
  font-size: 11px !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  cursor: pointer;
  border-radius: 0px;
  opacity: 1;
  margin-right: 5px;
  font-weight: 500;
  outline: none;
  margin-left: 0px;
}

.owl-carousel .owl-nav button.owl-prev i {
  font-style: normal;
}

.owl-carousel .owl-nav button.owl-prev:hover {
  color: #fff;
  opacity: 0.9;
}

.owl-carousel .owl-nav button.owl-prev:active {
  color: #fff;
  opacity: 0.9;
}

.owl-carousel .owl-nav button.owl-prev:focus {
  color: #fff;
  opacity: 0.9;
}

.owl-carousel .owl-nav button.owl-next {
  color: #fff !important;
  padding: 8px 10px !important;
  font-size: 11px !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  cursor: pointer;
  border-radius: 0px;
  opacity: 1;
  margin-right: 0px;
  font-weight: 500;
  outline: none;
}

.owl-carousel .owl-nav button.owl-next i {
  font-style: normal;
}

.owl-carousel .owl-nav button.owl-next:hover {
  color: #fff;
  opacity: 0.9;
}

.owl-carousel .owl-nav button.owl-next:active {
  color: #fff;
  opacity: 0.9;
}

.owl-carousel .owl-nav button.owl-next:focus {
  color: #fff;
  opacity: 0.9;
}

/*---Owl dots nav position---*/
.owl-dots-bottom-left .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
  text-align: left;
}

.owl-dots-bottom-right .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
  text-align: right;
}

.owl-dots-bottom-center .owl-dots {
  bottom: 0px;
  padding: 15px 20px;
  position: absolute;
  width: 100%;
}

@media only screen and (max-width:480px) {
  .owl-carousel .owl-nav button.owl-prev {
    margin: 0px;
  }

}

/*======================================
THEMEFOREST REQUIRED CLASSES
======================================*/
/*-------------------------------------
	Text meant only for screen readers
-------------------------------------*/
.sticky {
  clear: both;
}

.gallery-caption {
  clear: both;
}

.bypostauthor {
  clear: both;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

/*-------------------------------------
	Tabs 
-------------------------------------*/
/*Tabs style 1 [ default ] */
.wt-tabs .tab-pane {
  padding: 20px;
  border: 5px solid #e7e7e7;
  background-color: #fff;
}

.wt-tabs .nav-tabs {
  border: 0px;
}

.wt-tabs .nav-tabs>li {
  border: 1px solid #ebebeb;
}

.wt-tabs .nav-tabs>li>a {
  color: #000;
  background-color: #fff;
  font-size: 16px;
  padding: 15px;
  border: 0px;
  display: block;
  font-weight: 600;
}

.wt-tabs .nav-tabs>li>a i {
  margin-right: 5px;
}

.wt-tabs .nav-tabs>li a.active {
  background-color: #e7e7e7;
  color: #000;
}

.wt-tabs .nav-tabs>li a.active:focus {
  background-color: #e7e7e7;
  color: #000;
}

.wt-tabs .nav-tabs>li a.active:hover {
  background-color: #e7e7e7;
  color: #000;
}

.wt-tabs .nav>li>a:focus {
  background-color: transparent;
  border: 0px;
}

.wt-tabs .nav>li>a:hover {
  background-color: transparent;
  border: 0px;
}

.wt-tabs .nav li a:hover {
  border: 1px;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .wt-tabs.tabs-default .tabs-default-nav .nav-tabs {
    justify-content: flex-start;
  }
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li>a {
  color: #000;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li>a span {
  display: block;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li>a span i {
  font-weight: normal;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li>a.active {
  color: #fff;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li.active>a {
  color: #000;
  border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li.active>a:focus {
  color: #000;
  border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default .tabs-default-nav .nav-tabs>li.active>a:hover {
  color: #000;
  border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default .tab-pane {
  color: #fff;
}

@media (max-width: 991px) {
  .wt-tabs.tabs-default .tab-pane {
    padding: 0px;
  }
}

/*Header Start ============================*/
.header-full-width .container {
  max-width: 100%;
}

.header-style-1 {
  position: relative;
}

.header-style-1 .top-bar {
  /*---Top bar---*/
  padding: 0px 0px;
  font-size: 14px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
}

.header-style-1 .top-bar .wt-topbar-left {
  float: left;
}

.header-style-1 .navbar-toggler .icon-bar {
  background: #fff;
}

.header-style-1 .color-fill .navbar-toggler .icon-bar {
  background: #000;
}

@media (max-width: 767px) {
  .header-style-1 .top-bar .wt-topbar-left {
    display: none !important;
  }
}

.header-style-1 .top-bar .wt-topbar-info li {
  display: inline-block;
  margin-right: 0px;
  padding: 12px 15px 12px 0px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.header-style-1 .top-bar .wt-topbar-info li span {
  display: inline-block;
  padding-right: 10px;
}

.header-style-1 .top-bar .wt-topbar-info li i {
  margin-right: 10px;
  font-size: 20px;
}

.header-style-1 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
  display: inline-block;
  margin-right: 0px;
  padding: 10px 15px 10px 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.header-style-1 .top-bar .wt-topbar-right .wt-topbar-info-2 li span {
  display: inline-block;
  padding-right: 10px;
}

.header-style-1 .top-bar .wt-topbar-right .wt-topbar-info-2 li i {
  margin-right: 10px;
  font-size: 20px;
}

@media (max-width: 767px) {
  .header-style-1 .top-bar .wt-topbar-right .wt-topbar-info-2 li:last-child {
    border-right: 0px;
  }
}

@media (max-width: 460px) {
  .header-style-1 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
    border-right: 0px !important;
  }
}

.header-style-1 .top-bar .social-icons {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  list-style: none;
}

@media (max-width: 991px) {
  .top-bar .social-icons {
    display: none;
  }
}

.top-bar .social-icons li {
  padding-left: 10px;
}

.top-bar .social-icons li a {
  color: #fff;
  font-size: 12px;
}

.top-bar .social-icons li a:hover {
  color: #000;
}

.site-header {
  position: relative;
  z-index: 999;
}

.header-style-1 .main-bar {
  width: 100%;
}

.extra-nav {
  /*---Extra Nav---*/
  display: flex;
  float: right;
  height: 70px;
  position: relative;
  z-index: 13;
}

.extra-nav .extra-cell {
  display: flex;
  padding-left: 25px;
  align-items: center;
}

.extra-nav .extra-cell:first-child {
  padding-left: 0px;
}

@media only screen and (max-width:767px) {

  .aon-btn-signup,
  .aon-add-listing {
    font-size: 0;
    text-align: center;
    padding: 10px 10px;
    min-width: 40px;
    margin-left: 10px;
  }

  .aon-btn-signup i,
  .aon-add-listing i {
    font-size: 16px;
    padding: 0px;
    display: table;
    margin: auto;
  }

  .aon-btn-signup {
    display: inline-block;
    background: #ff8a00;
    color: #ff8a00;
    border-radius: 5px;
  }

}


@media (max-width: 540px) {
  .extra-nav .extra-cell {
    padding-left: 10px;
  }
}

.logo-header {
  /*--logo for header---*/
  display: table;
  float: left;
  width: 190px;
  height: 70px;
  vertical-align: middle;
  padding: 0px;
  font-size: 36px;
  color: #000;
  margin: 0px;
  position: relative;
  z-index: 11;
}

@media (max-width: 640px) {
  .logo-header {
    width: 120px;
  }
}

@media (max-width: 360px) {
  .logo-header {
    width: 120px;
  }
}

.logo-header .logo-header-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.logo-header .logo-header-inner img {
  max-width: 100%;
  max-height: 100%;
}

.site-header ul,
.site-header ol {
  margin-bottom: 0px;
}

.main-bar {
  /*--main-bar--*/
  position: relative;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.5s ease;
  background: none;
}

.main-bar .container,
.main-bar .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {

  .main-bar .container,
  .main-bar .container-fluid {
    display: block;
  }
}

.main-bar:after,
.main-bar:after {
  clear: both;
  content: "";
  display: table;
}

.navbar-toggler {
  /*--Navbar Toggler---*/
  border: none;
  margin: 24px 0px 24px 15px;
  padding: 12px 10px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

@media (max-width: 991px) {
  .navbar-toggler {
    display: block;
    margin-top: 14px;
    margin-bottom: 14px;
    float: right;
    outline: none !important;
  }
}

.navbar-toggler .icon-bar {
  background: #000;
  height: 3px;
  border-radius: 0px;
  display: block;
  width: 22px;
}

.navbar-toggler .icon-bar+.icon-bar {
  margin-top: 4px;
}


@media (max-width: 991px) {
  .header-style-1 .navbar-toggler {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .navbar-toggler .icon-bar {
    background: #000;
  }
}

@media (max-width: 420px) {
  .navbar-toggler {
    margin-left: 10px;
  }
}

@media (max-width: 540px) {
  .is-fixed .navbar-toggler {
    margin-bottom: 0px;
  }
}

.is-fixed.mobile-sider-drawer-menu .header-nav .nav {
  height: 100vh !important;
  width: 100%;
}

.is-fixed.mobile-sider-drawer-menu .header-style-1 .header-nav .nav>li {
  padding: 12px 15px !important;
}

.header-nav {
  position: relative;
  padding: 0px;
  z-index: 10;
  /*-- Submenu direction---*/
}

@media (max-width: 991px) {
  .header-nav {
    clear: both;
    margin: 0px -15px;
    border-bottom: 1px solid #E9E9E9;
  }

  .header-nav.navbar-collapse {
    align-items: start;
  }
}

.header-nav .nav {
  display: flex;
  justify-content: flex-end;
  flex-direction: inherit;
}

@media (max-width: 991px) {
  .header-nav .nav {
    float: none;
    margin: 0px;
    background: #fff;
    display: block;
    width: 100%;
    left: 0px;
  }
}

@media (max-width: 991px) {
  .header-nav .nav li {
    float: none;
  }
}

.header-nav .nav li i {
  font-size: 9px;
  margin-left: 3px;
  margin-top: -3px;
  vertical-align: middle;
  opacity: 0.7;
}

@media (max-width: 991px) {
  .header-nav .nav li i {
    float: right;
  }
}

.header-nav .nav>li {
  position: relative;
  margin: -10px 0px;
  padding: 10px 0px;
}

@media (max-width: 991px) {
  .header-nav .nav>li ul {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
}

.header-nav .nav>li>a {
  color: #000;
  font-size: 16px;
  padding: 15px 10px;
  cursor: pointer;
  margin: 0px 0px;
  display: block;
  font-weight: 600;
}

.color-fill .header-nav .nav>li>a {
  color: #000;
}

@media (max-width: 991px) {
  .header-nav .nav>li>a {
    padding: 12px 15px;
    border-top: 1px solid #E9E9E9;
    color: #000;
  }
}

.header-nav .nav>li>a:hover {
  background-color: transparent;
  color: #000;
}

.header-nav .nav>li>a:active,
.header-nav .nav>li>a:focus {
  background-color: transparent;
}

@media (max-width: 991px) {

  .header-nav .nav>li.active>a,
  .header-nav .nav>li.current-menu-item>a {
    color: #ff8a00;
  }
}

@media (max-width: 991px) {

  .header-nav .nav>li>a:hover,
  .header-nav .nav>li>a:active,
  .header-nav .nav>li>a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }
}

.header-nav .nav>li.active>a,
.header-nav .nav>li.current-menu-item>a {
  background-color: transparent;
  color: #ff8a00;
}

.header-nav .nav>li:hover>a {
  color: #ff8a00;
}

.header-nav .nav>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
  transition: all 0.3s ease;
}

.header-nav .nav>li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0px;
  top: 100%;
  list-style: none;
  margin: 20px 0 0;
  opacity: 0;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
}

@media (max-width: 991px) {
  .header-nav .nav>li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
    opacity: 1;
    margin: 0px !important;
    transition: none !important;
  }
}

.header-nav .nav>li .sub-menu li {
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  text-transform: none;
}

.header-nav .nav>li .sub-menu li a {
  color: #000;
  display: block;
  font-size: 13px;
  padding: 14px 20px;
  padding-left: 20px;
  font-weight: 600;
}

.header-nav .nav>li .sub-menu li a:hover {
  color: #ff8a00;
  text-decoration: none;
}

.header-nav .nav>li .sub-menu li:hover>a {
  color: #ff8a00;
}

.header-nav .nav>li .sub-menu li:last-child {
  border-bottom: 0px;
}

.header-nav .nav>li .sub-menu li .fa {
  color: #000;
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 2px;
}

.header-nav .nav>li .sub-menu li>.sub-menu {
  left: 220px;
  margin: 0px 0px 0px 20px;
  transition: all 0.1s ease;
}

@media (max-width: 991px) {
  .header-nav .nav>li .sub-menu li>.sub-menu>li {
    float: none;
    display: block;
    width: auto;
  }
}

@media (max-width: 991px) {
  .header-nav .nav>li .sub-menu li>.sub-menu>li a {
    padding-left: 30px;
  }
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu {
  left: 220px;
  margin: 0px;
  opacity: 1;
  top: -1px;
  visibility: visible;
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu:before {
  background-color: transparent;
  bottom: 0px;
  content: '';
  display: block;
  height: 100%;
  left: -6px;
  position: absolute;
  top: 0px;
  width: 6px;
}

.header-nav .submenu-direction .sub-menu {
  left: auto;
  right: 0px;
}

.header-nav .submenu-direction .sub-menu li>.sub-menu {
  left: auto;
  right: 220px;
  margin: 0px 20px 0px 0px;
}

.header-nav .submenu-direction .sub-menu li:hover>.sub-menu {
  left: auto;
  right: 220px;
  margin: 0px 0px 0px 0px;
}

.header-nav.nav-animation .nav>li>ul.sub-menu li>a {
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 1;
}

.header-nav.nav-animation .nav>li:hover>ul.sub-menu li:hover>a {
  padding-left: 40px;
}

@media (max-width: 991px) {
  .header-nav .has-child {
    position: relative;
  }
}

.header-nav .has-child .submenu-toogle {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #000;
  background-color: transparent;
  z-index: 900;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  display: none;
}

@media (max-width: 991px) {
  .header-nav .has-child .submenu-toogle {
    display: block;
  }
}

.header-nav .has-child li .submenu-toogle {
  opacity: 0.9;
}

.header-nav .has-child.nav-active>a+.submenu-toogle.glyphicon-chevron-right:before {
  content: "\e114";
}

.header-nav .has-child.nav-active>a+.submenu-toogle.glyphicon-plus::before {
  content: "\2212";
}

@media (max-width: 991px) {
  .header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right::before {
    content: "\f107";
  }
}

@media (max-width: 991px) {
  .mobile-full-width-menu .header-nav .nav {
    position: inherit;
  }
}

@media (max-width: 991px) {
  .mobile-sider-drawer-menu .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    transition: all 0.5s linear;
    background-color: #fff;
  }

  .mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
    animation: none !important;
  }

  .mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
    animation: none !important;
  }

  .mobile-sider-drawer-menu.active .header-nav {
    left: 15px;
  }

  .mobile-sider-drawer-menu .icon-bar {
    transition: all 0.5s linear;
    position: relative;
  }

  .mobile-sider-drawer-menu #mobile-side-drawer {
    min-height: 41px;
    position: relative;
  }

  .mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }

  .mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }

  .mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }

  .mobile-sider-drawer-menu .is-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }

  .mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
}

.sticky-no .main-bar {
  position: static !important;
}

.is-fixed .main-bar {
  position: fixed;
  top: 0px;
  left: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.is-fixed.color-fill .main-bar {
  background-color: #fff;
}

.is-fixed.color-fill .main-bar {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.is-fixed.color-fill .header-nav .nav>li {
  margin: 0px;
  padding: 0px;
}

@media (max-width: 991px) {
  .header-nav .nav>li {
    margin: 0px;
    padding: 0px;
  }

  .header-nav .nav>li>a {
    margin: 0px;
    padding: 10px 12px;
  }

  .is-fixed.color-fill .header-nav .nav>li {
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .main-bar {
    padding: 0px;
  }

  .is-fixed .main-bar {
    padding: 0px;
  }

  .is-fixed.color-fill .main-bar {
    padding: 0px;
  }
}

/*Header End ===========================*/

/*Header Style 1*/
.header-style-1 {
  position: absolute;
}

.header-style-1 .header-nav .nav>li>a {
  color: #fff;
}

.header-style-1 .header-nav .nav>li:hover>a {
  color: #ff8a00;
}

.header-style-1 .header-nav .nav>li.active>a,
.header-style-1 .header-nav .nav>li.current-menu-item>a {
  color: #ff8a00;
}

.header-style-1 .color-fill .header-nav .nav>li>a {
  color: #000;
}

.header-style-1 .color-fill .header-nav .nav>li:hover>a {
  color: #ff8a00;
}

.header-style-1 .color-fill .header-nav .nav>li.active>a,
.header-style-1 .color-fill .header-nav .nav>li.current-menu-item>a {
  color: #ff8a00;
}

@media only screen and (max-width:991px) {
  .header-style-1 .header-nav .nav>li>a {
    color: #222;
  }
}


/*Header Style 3*/
.header-style-3 {
  position: absolute;
  width: 100%;
}

.header-style-3 .main-bar {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(0, 0, 0, 0.4);
}

.header-style-3 .is-fixed .main-bar {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.header-style-3 .header-nav .nav>li>a {
  color: #fff;
}

.header-style-3 .header-nav .nav>li:hover>a {
  color: #ff8a00;
}

.header-style-3 .header-nav .nav>li.active>a,
.header-style-3 .header-nav .nav>li.current-menu-item>a {
  color: #ff8a00;
}

.header-style-3 .color-fill .header-nav .nav>li>a {
  color: #000;
}

.header-style-3 .color-fill .header-nav .nav>li:hover>a {
  color: #ff8a00;
}

.header-style-3 .color-fill .header-nav .nav>li.active>a,
.header-style-1 .color-fill .header-nav .nav>li.current-menu-item>a {
  color: #ff8a00;
}

.header-style-3 .navbar-toggler .icon-bar {
  background: #fff;
}

.header-style-3 .color-fill .navbar-toggler .icon-bar {
  background: #000;
}

@media only screen and (max-width:991px) {
  .header-style-3 .header-nav .nav>li>a {
    color: #000;
  }

  .header-style-3 .header-nav .nav>li.active>a,
  .header-style-3 .header-nav .nav>li.current-menu-item>a {
    color: #ff8a00;
  }
}

/*Header Style 2*/
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1270px;
  }
}

/*Title separators*/
.wt-separator-two-part {
  padding-bottom: 50px;
}

@media (max-width: 991px) {
  .wt-separator-two-part {
    padding-bottom: 30px;
  }
}





.wt-separator-two-part.when-bg-dark .section-head .wt-title {
  color: #fff;
}

.wt-separator-two-part.when-bg-dark .section-head.left.wt-small-separator-outer .wt-small-separator {
  color: #000;
}

.wt-separator-two-part.when-bg-dark .section-head.left.wt-small-separator-outer .wt-small-separator div.sep-leaf-left:before {
  background-color: #000;
}

.wt-separator-two-part.when-bg-dark .section-head.left.wt-small-separator-outer .wt-small-separator div.sep-leaf-left:after {
  background-color: #000;
}

.wt-separator-two-part.when-bg-dark .wt-separator-two-part-right .p_discription {
  color: #fff;
}

.wt-separator-two-part-row {
  display: flex;
  align-items: center;
}

.wt-separator-two-part-row .wt-separator-two-part-left .section-head {
  margin-bottom: 0px;
}

.wt-separator-two-part-row .wt-separator-two-part-right {
  margin-bottom: 0px;
}

.wt-separator-two-part-row .wt-separator-two-part-right p {
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .wt-separator-two-part-row .wt-separator-two-part-right {
    text-align: left !important;
    margin-top: 30px;
  }
}

.wt-small-separator {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
  font-weight: 800;
  color: #ff8a00;
  background-color: #f9f9f9;
  padding: 5px 10px;
  border-left: 3px solid #ff8a00;
}

.wt-small-separator.white {
  color: #fff;
}

@media (max-width: 767px) {
  .wt-small-separator {
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 0px;
  }
}

@media (max-width: 767px) {
  .wt-small-separator h2 {
    margin-bottom: 20px;
  }
}

.section-head {
  position: relative;
}

.section-head.when-bg-dark .wt-title {
  color: #fff;
}

.section-head.when-bg-dark p {
  color: #c1c1c1;
}

/*Center-title*/
.section-head.left.wt-small-separator-outer {
  text-align: left;
}

@media (max-width: 1024px) {
  .section-head.left.wt-small-separator-outer h2 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .section-head.left.wt-small-separator-outer h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

.section-head.left.wt-small-separator-outer p {
  margin-bottom: 40px;
}

.section-head.left.wt-small-separator-outer .wt-small-separator div.sep-leaf-left {
  position: relative;
}

.section-head.left.wt-small-separator-outer .wt-small-separator div.sep-leaf-left:before,
.section-head.left.wt-small-separator-outer .wt-small-separator div.sep-leaf-left:after {
  position: absolute;
  content: '';
  background-color: #ff8a00;
  height: 22px;
  bottom: 0px;
  top: 0px;
  transform: skew(-20deg);
}

.section-head.left.wt-small-separator-outer .wt-small-separator div.sep-leaf-left.white:before,
.section-head.left.wt-small-separator-outer .wt-small-separator div.sep-leaf-left.white:after {
  background-color: #fff;
}

.section-head.left.wt-small-separator-outer .wt-small-separator div.sep-leaf-left:before {
  right: -20px;
  width: 10px;
}

.section-head.left.wt-small-separator-outer .wt-small-separator div.sep-leaf-left:after {
  right: -27px;
  width: 4px;
}

.section-head.left.wt-small-separator-outer.white .wt-small-separator div.sep-leaf-left:before,
.section-head.left.wt-small-separator-outer.white .wt-small-separator div.sep-leaf-left:after {
  background-color: #fff;
}

.section-head.left.wt-small-separator-outer.white .wt-small-separator div {
  color: #fff;
}

.section-head.center.wt-small-separator-outer {
  margin-bottom: 50px;
  text-align: center;
}

.section-head.center.wt-small-separator-outer h2 {
  max-width: 600px;
  margin: 0px auto;
}

@media (max-width: 1200px) {
  .section-head.center.wt-small-separator-outer h2 {
    font-size: 50px;
  }
}

@media (max-width: 1024px) {
  .section-head.center.wt-small-separator-outer h2 {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .section-head.center.wt-small-separator-outer h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

.section-head.center .wt-small-separator div.sep-leaf-left {
  position: relative;
}

.section-head.center .wt-small-separator div.sep-leaf-left:before,
.section-head.center .wt-small-separator div.sep-leaf-left:after {
  position: absolute;
  content: '';
  background-color: #ff8a00;
  height: 22px;
  bottom: 0px;
  top: 0px;
  transform: skew(-20deg);
}

.section-head.center .wt-small-separator div.sep-leaf-left:before {
  right: -20px;
  width: 10px;
}

.section-head.center .wt-small-separator div.sep-leaf-left:after {
  right: -27px;
  width: 4px;
}

/*--Play button--*/
.play-now-video .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 65px;
  width: 65px;
  text-align: center;
  line-height: 65px;
  background-color: #fff;
  color: #ff8a00;
  z-index: 1;
  font-size: 20px;
  padding-left: 5px;
  display: block;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: center center;
  border-radius: 50%;
  box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
}

.play-now-video .ripple,
.play-now-video .ripple:before,
.play-now-video .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 65px;
  width: 65px;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: center center;
  border-radius: 50%;
  box-shadow: 0 0 0 0 white;
  animation: ripple-video 3s infinite;
  z-index: 1;
}

.play-now-video .ripple:before {
  animation-delay: .9s;
  content: "";
  position: absolute;
}

.play-now-video .ripple:after {
  animation-delay: .6s;
  content: "";
  position: absolute;
}

@keyframes ripple-video {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.widget {
  margin-bottom: 40px;
}

/*Footer Dark*/
.footer-dark .widget_address {
  padding-right: 30px;
}

.footer-dark .recent-posts-entry .wt-post-info .wt-post-header a {
  color: #acacac;
}

/*Widget Services*/
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.widget_services ul li {
  display: block;
  width: 100%;
  padding: 2px 0px;
  border: 0px;
  overflow: hidden;
}

.widget_services ul li a {
  color: #595959;
  position: relative;
  padding: 0px 0px 6px 0px;
  display: block;
  margin-left: 0px;
  transition: 0.5s all ease;
  font-size: 16px;
  display: inline-block;
}

.widget_services ul li .badge {
  float: right;
  background: none;
  color: #ff8a00;
  font-weight: normal;
  font-size: 14px;
}

.widget_services ul li a:hover {
  color: #ff8a00;
}

button.scroltop {
  background: #161616;
  border: none;
  position: fixed;
  right: 10px;
  bottom: 20px;
  color: #fff;
  margin: 0;
  cursor: pointer;
  display: none;
  height: 30px;
  width: 30px;
  line-height: 20px;
  padding: 8px;
  text-align: center;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  z-index: 999;
}

/*Widget News-Letter*/
.footer-top-newsletter {
  padding-top: 80px;
}

.sf-news-letter {
  background-color: #c1ded7;
  padding: 50px 0px;
  border-radius: 20px;
  padding: 20px 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.sf-news-letter span {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  color: #222;
}


.sf-news-l-form {
  display: flex;
  position: relative;
  min-width: 560px;
  margin-bottom: 0px;
}

.sf-news-l-form .form-control {
  height: 40px;
  width: 100%;
  border-radius: 12px;
  border: none;
}

.sf-news-l-form .sf-sb-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 14px;
  color: #fff;
  border: none;
  font-weight: 500;
  background-color: #ff8a00;
  border-radius: 0px 12px 12px 0px;
  padding: 8px 30px;
  margin-left: -12px;
}

@media only screen and (max-width:991px) {
  .sf-news-letter span {
    display: table;
    margin-bottom: 15px;
  }

  .sf-news-letter form {
    max-width: 700px;
    width: 100%;
  }

  .sf-news-l-form {
    min-width: 100px;
  }
}

/*Footer Light version css*/
.footer-light {
  background-color: #f8f5ff;
  color: #000;
}

.footer-light .logo-footer {
  margin-bottom: 25px;
  max-width: 240px;
}

.footer-light .footer-top {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (max-width:640px) {
  .footer-light .footer-top {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.footer-light .widget-title {
  font-family: "Mulish", sans-serif;
  color: #000;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  position: relative;
}

.footer-light .widget-title:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #000;
  width: 20px;
  height: 2px;
}

.footer-light .call-us {
  padding: 10px 20px 10px 0px;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .footer-light .call-us {
    margin: 15px 0px;
  }
}

.footer-light .call-us i {
  display: block;
  font-size: 42px;
  line-height: 42px;
  margin-right: 10px;
}

.footer-light .call-us a {
  display: block;
  color: #ff8a00;
  font-size: 28px;
  line-height: 28px;
  font-weight: 800;
}

.footer-light .social-icons {
  margin: 30px 0px 0px 0px;
}

.footer-light .social-icons li {
  display: inline-block;
}

.footer-light .social-icons li a {
  height: 30px;
  width: 30px;
  background-color: #000;
  line-height: 30px;
  padding: 0px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  margin-right: 3px;
}

.footer-light .social-icons li a:hover {
  color: #fff;
  background-color: #ff8a00;
}

.footer-light .footer-bottom {
  color: #fff;
  position: relative;
  z-index: 1;
}

.footer-light .footer-bottom .footer-bottom-info {
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .footer-light .footer-bottom .footer-bottom-info {
    display: block;
  }
}

.footer-light .footer-bottom .footer-bottom-info .call-us {
  background-color: #000;
  border-left: 4px solid #ff8a00;
  padding: 10px 20px;
}

@media (max-width: 991px) {
  .footer-light .footer-bottom .footer-bottom-info .call-us {
    margin: 15px 0px;
  }
}

.footer-light .footer-bottom .footer-bottom-info .call-us span {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
}

.footer-light .footer-bottom .footer-bottom-info .call-us a {
  display: block;
  color: #ff8a00;
  font-size: 28px;
  line-height: 28px;
  font-weight: 700;
}

.footer-light .footer-bottom .footer-copy-right .copyrights-text {
  color: #000;
  font-size: 14px;
}

.footer-light .copyrights-nav {
  list-style: none;
  display: block;
  margin-bottom: 0px;
}

.footer-light .copyrights-nav li {
  display: inline-block;
  margin-right: 8px;
}

.footer-light .copyrights-nav li:first-child {
  margin-left: 0;
}

.footer-light .copyrights-nav li:last-child {
  margin-right: 0;
}

.footer-light .copyrights-nav li:last-child:after {
  display: none;
}

.footer-light .copyrights-nav li:after {
  content: "/";
  position: relative;
  right: -8px;
  color: #000;
}

.footer-light .copyrights-nav li a {
  color: #000;
  font-size: 14px;
}

.footer-light .copyrights-nav li a:hover {
  color: #ff8a00;
}

.footer-light .copyrights-nav li a:active {
  color: #ff8a00;
}

.footer-light .copyrights-nav li a:focus {
  color: #ff8a00;
}

.footer-light .copyrights-nav li.active a {
  color: #fff;
}

.footer-light .widget_address li {
  color: #000;
}

.footer-light .widget_address li i {
  color: #000;
  font-weight: normal;
}

.footer-light .widget_services ul li a {
  color: #000;
}

.footer-light .widget_services ul li a:hover {
  color: #ff8a00;
}

.footer-light .recent-posts-entry .wt-post-info .wt-post-header a {
  color: #000;
}

.footer-light .recent-posts-entry .wt-post-info .wt-post-header a:hover {
  color: #ff8a00;
}

.footer-light .recent-posts-entry .wt-post-info .wt-post-meta ul li {
  color: #6a6a6a;
}

.sf-site-link ul {
  list-style: none;
  margin: 0px;
}

.sf-site-link ul li a {
  color: #535353;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width:767px) {
  .sf-site-link {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width:575px) {
  .footer-top .row [class*='col-']:last-child .sf-site-link {
    margin-bottom: 0px;
  }
}

.sf-footer-bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom {
  padding-bottom: 30px;
}

.sf-f-copyright {
  color: #535353;
  font-size: 14px;
  font-weight: 500;
}

.sf-footer-bottom-section .sf-f-social {
  position: relative;
  padding-left: 420px;
}

.sf-footer-bottom-section .sf-f-social:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 50%;
  height: 1px;
  background-color: #022278;
  width: 390px;
}

.sf-footer-bottom-section .sf-f-social .socila-box {
  margin: 0px;
}

.sf-footer-bottom-section .sf-f-social .socila-box li {
  display: inline-block;
}

.sf-footer-bottom-section .sf-f-social .socila-box li a {
  color: #26418c;
  font-size: 22px;
  padding-right: 15px;
}

.sf-footer-bottom-section .sf-f-social .socila-box li:last-child a {
  padding-right: 0px;
}


@media only screen and (max-width:1100px) {
  .sf-f-logo {
    display: table;
    margin: 0px auto 20px;
  }

  .sf-footer-bottom-section {
    display: block;
    text-align: center;
  }

  .sf-footer-bottom-section .sf-f-social::after {
    left: 0%;
    top: 0px;
    height: 1px;
    width: 100%;
  }

  .sf-footer-bottom-section .sf-f-social {
    padding: 20px 0px 0px;
    margin-top: 25px;
  }
}


/*Accordians*/
.accordion-cutom .accordion-item {
  position: relative;
  border: none;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  transition: 0.5s all ease;
}

.accordion-cutom .accordion-item:last-child {
  margin-bottom: 0px;
}

.accordion-cutom .accordion-item .acod-content {
  padding: 10px 20px 20px 50px;
  background-color: #fff;
}

.accordion-cutom .accordion-item .accordion-header a {
  background-color: #fff;
}

.accordion-cutom [aria-expanded="false"] .accordion-button::after {
  content: "\f068";
}

.accordion-cutom .acod-content:after {
  transition: 0.5s all ease;
  content: '';
  position: absolute;
  right: 0px;
  top: 0px;
  width: 4px;
  height: 0%;
  background-color: #ff8a00;
  z-index: 1;
}

.accordion-cutom.collapse.show .acod-content:after {
  height: 100%;
}

.acod-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.acod-title a {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.acod-head {
  position: relative;
  /*Accodian open close indicator css*/
}

.acod-head a {
  display: block;
  padding: 15px 20px 15px 50px;
  background-color: #f6f6f6;
}

.acod-head a.collapsed {
  color: #000;
}

.acod-head .fa {
  margin-right: 5px;
}

.acod-head a,
.acod-head:hover,
.acod-head:active,
.acod-head:focus {
  color: #000;
}

.acod-head .indicator {
  padding: 15px;
  color: #000;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  font-size: 22px;
}

.acod-head .indicator .fa {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.acod-head.acc-actives .indicator .fa:before {
  content: "\f067";
  font-family: 'FontAwesome';
}

.acod-head [aria-expanded="false"] .indicator .fa:before {
  content: "\f105";
  font-family: 'FontAwesome';
}

.acod-head [aria-expanded="true"] .indicator .fa:before {
  font-family: 'FontAwesome';
  content: "\f107";
}

.acod-head .collapsed .indicator .fa:before {
  content: "\f105";
  font-family: 'FontAwesome';
}

.acod-head .collapsed [aria-expanded="false"] .indicator .fa:before {
  content: "\f067";
  font-family: 'FontAwesome';
}

.acod-head .indicator .fa:before {
  font-family: 'FontAwesome';
  content: "\f107";
}

.acod-head [aria-expanded="true"] .indicator .fa:before {
  font-family: 'FontAwesome';
  content: "\f107";
}

.form-control {
  border: 1px solid #e1e1e1;
  height: 50px;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 12px;
  border-radius: 5px;
}


.form-control:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6);
  background-color: #F8F8F8;
}

.bootstrap-select .dropdown-menu li a {
  font-size: 14px;
}


/*Section Overlay*/
.overlay-wraper {
  position: relative;
}

.overlay-wraper .overlay-main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.overlay-wraper>.container {
  position: relative;
  z-index: 1;
}

.overlay-wraper>.container-fluid {
  position: relative;
  z-index: 1;
}

/*newsletter section*/
.nl-type {
  width: 100%;
}

@media (max-width: 768px) {
  .nl-type {
    margin: 0px auto;
  }
}

@media (max-width: 575px) {
  .nl-type {
    width: 100%;
  }
}

.nl-type .nl-form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.nl-type .nl-form .form-control {
  background-color: transparent;
  border: 1px solid #acacac;
  border-right: 0px;
  flex: 1 1 auto;
  width: 1%;
}

.nl-type .nl-form .form-control::placeholder {
  color: #000;
  font-size: 12px;
  font-weight: 400;
}

.nl-type .nl-form .form-control:-ms-input-placeholder {
  color: #000;
  font-size: 12px;
  font-weight: 400;
}

.nl-type .nl-form .s-btn {
  border: none;
  background-color: #ff8a00;
  padding: 0px 20px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

select {
  appearance: none;
  /* Remove default arrow */
  /* background-image: url(...); */
  /* Add custom arrow */
  padding: 10px 30px 10px 10px;
  background-color: #fff;
}

/*radio & checkbox*/
.sf-radio-checkbox.radio-inline,
.sf-radio-checkbox.checkbox-inline {
  padding-left: 0 !important;
}

.sf-radio-checkbox label {
  font-weight: normal !important;
}

.sf-radio-checkbox input[type=checkbox],
.sf-radio-checkbox input[type=radio] {
  display: none;
  opacity: 0;
  margin-left: 1px !important;
}

.sf-radio-checkbox input[type=checkbox]+label,
.sf-radio-checkbox input[type=radio]+label {
  display: block;
  padding-left: 24px !important;
  position: relative;
  cursor: pointer;
  line-height: 1.3;
}

.sf-radio-checkbox input[type=checkbox]+label:hover:before,
.sf-radio-checkbox input[type=radio]+label:hover:before {
  border-color: #022278;
}

.sf-radio-checkbox input[type=checkbox]+label:before,
.sf-radio-checkbox input[type=radio]+label:before {
  background-color: #c4cada;
  border: 1px solid #afb6c9;
  content: "";
  display: inline-block;
  height: 20px;
  line-height: 1;
  margin-left: -24px;
  position: absolute;
  top: 0px;
  transition: all linear 0.1s;
  width: 20px;
  border-radius: 2px;
}

.sf-radio-checkbox input[type=checkbox]+label:after {
  color: #fff;
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 0px;
  -webkit-font-smoothing: antialiased;
  left: 6px;
  position: absolute;
  top: 7px;
  transition: all linear 0.1s;
}

.sf-radio-checkbox input[type=checkbox]:checked+label:before {
  border-width: 10px;
  border-color: #022278;
}

.sf-radio-checkbox input[type=checkbox]:checked+label:after {
  font-size: 12px;
  left: 3px;
  top: 1px;
}

.sf-radio-checkbox input[type=radio]+label:before {
  border-radius: 50%;
  content: "";
}

.sf-radio-checkbox input[type=radio]:checked+label:before {
  border-color: #022278;
  border-width: 10px;
}

.form-inline .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"] {
  position: absolute;
  left: 0;
  bottom: 0;
}




/*============================
	My Account css
=============================*/
.wrapper-admin {
  height: 100vh;
}

#header-admin {
  width: calc(100% - 250px);
  height: 70px;
  background: #f8f9fb;
  border-bottom: none;
  position: fixed;
  box-shadow: 0 13px 35px -12px rgb(61 98 174 / 25%);
  top: 0;
  right: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1000;
}

#header-admin .container {
  width: 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

#header-admin.active {
  width: calc(100%);
}

#sidebar-admin-wraper {
  width: 250px;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  background: #fff;
  border-right: 1px solid #e8edf1;
  color: #fff;
  transition: all 0.3s;
  padding-top: 0px;
}

#sidebar-admin-wraper.active {
  margin-left: -250px;
}

#content {
  width: calc(100% - 250px);
  padding-top: 70px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f8f9fb;
}

#content.active {
  width: 100%;
}

@media (max-width:991px) {
  #header-admin .container {
    width: 100%;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 768px) {
  #sidebar-admin-wraper {
    margin-left: -250px;
    padding-top: 70px;
  }

  #sidebar-admin-wraper.active {
    margin-left: 0;
  }

  #content {
    width: 100%;
  }

  #content.active {
    width: calc(100% - 250px);
  }

  #header-admin {
    width: calc(100%);
  }

  #header-admin.active {
    width: calc(100% - 0px);
  }
}

.header-left {
  float: left;
  display: flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.header-right {
  float: right;
  display: flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.header-left,
.header-right {
  height: 70px;
}

.header-widget-wrap {
  margin: 0px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.nav-btn-wrap {
  display: block;
  padding: 25px;
}

.nav-btn-admin {
  font-size: 21px;
  cursor: pointer;
  display: block;
  color: #000 !important;
  border-radius: 30px;
}

.nav-btn-admin .nav-btn-text {
  font-size: 14px;
}

.nav-btn-admin .fa {
  font-size: 24px;
  border: none;
  padding: 9px 12px;
}


.header-widget-wrap>.header-widget {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  -ms-flex-align: center;
  align-items: center;
}

.header-widget-wrap .dropdown-menu {
  width: 350px;
  min-width: 350px;
  top: 60px;
  left: auto;
  right: -15px;
  padding: 0;
}

.header-widget-wrap .dropdown-menu-header {
  padding: 15px 15px;
  font-weight: 600;
  font-size: 14px;
  color: #555;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
}

.header-widget-wrap .sf-toogle-dropdown {
  border: none;
}

.header-widget-wrap .sf-toogle-dropdown:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}

.header-widget-wrap .dropdown-menu ul {
  list-style: none;
}

.header-widget-wrap .dropdown-menu ul>li {
  position: relative;
  clear: both;
  background-color: #f7f7f7;
  margin: 0 10px 10px 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.header-widget-wrap .dropdown-menu ul>li.sf-read-msg {
  opacity: 0.5;
}

.header-widget-wrap .dropdown-menu ul>li>a {
  display: block;
  padding: 10px 75px 10px 10px;
  color: #777;
  font-size: 12px;
}

.header-widget-wrap .dropdown-menu ul>li a:after {
  content: "";
  display: table;
  clear: both;
}

.header-widget-wrap .dropdown-menu ul>li>a:hover {
  color: #000;
}

.header-widget-wrap .dropdown-menu ul>li>a .sf-author-pics {
  display: block;
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.header-widget-wrap .dropdown-menu ul>li>a .sf-author-pics img {
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.header-widget-wrap .dropdown-menu ul>li>a .sf-author-texting {
  display: block;
  overflow: hidden;
  padding-top: 12px;
}



.header-widget .aon-admin-user+.dropdown-menu {
  width: 150px;
}

.header-widget-wrap .dropdown-menu ul>li>a .sf-booking-label {
  position: absolute;
  top: 21px;
  right: 10px;
  background-color: #666;
  color: #fff;
  display: block;
  padding: 1px 8px;
  border-radius: 10px;
  font-size: 10px;
}

.header-widget-wrap .dropdown-menu ul>li>a .sf-booking-label.bs-pending {
  background-color: #ea7f25;
}

.header-widget-wrap .dropdown-menu ul>li>a .sf-booking-label.bs-complete {
  background-color: #7ab734;
}

.aon-admin-user:hover {
  color: #999;
}

.aon-admin-pic {
  width: 40px;
  height: 40px;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -20px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.aon-admin-pic img {
  width: 100%;
  height: auto;
}

.aon-admin-pic span {
  display: inline-block;
}

.aon-admin-pic span {
  display: inline-block;
}

.aon-admin-messange,
.aon-admin-notification,
.aon-admin-user {
  position: relative;
  color: #999;
  font-size: 24px;
  width: 70px;
  height: 69px;
  line-height: 70px;
  text-align: center;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.aon-admin-messange:hover,
.aon-admin-notification:hover,
.aon-admin-user:hover {
  background-color: #f0f4f7;
}

.aon-admin-messange span,
.aon-admin-notification span {
  position: absolute;
  top: 15px;
  right: 12px;
  width: 16px;
  height: 16px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 16px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  font-weight: bold;
  font-family: Arial;
}

.aon-admin-notification span {
  background-color: #F04F53;
}

.feather-user-pic {
  width: 34px !important;
  height: 34px !important;
  border-radius: 30px;
  overflow: hidden;
}

@media only screen and (max-width:650px) {

  .aon-admin-messange,
  .aon-admin-notification,
  .aon-admin-user {
    font-size: 18px;
    width: 50px;
  }

  .feather-user-pic {
    width: 28px !important;
    height: 28px !important;
  }

}

.my-account-logo {
  padding: 10px;
  width: auto;
  box-shadow: 0 13px 35px -12px rgb(61 98 174 / 25%);
}

.my-account-logo img {
  height: 50px;
}

.cus-account-logo {
  width: 120px;
}

.my-account-logo span {
  color: #000;
  font-size: 20px;
  margin-left: 10px;
}

@media only screen and (max-width:768px) {
  .my-account-logo {
    padding: 10px 25px;
  }
}

#content.active {
  overflow: hidden;
}

#header-admin.active .container,
#content.active .content-admin-main {
  min-width: 480px;
}

@media only screen and (max-width:480px) {
  #header-admin.active .container {
    min-width: 100%;
  }
}

/* Admin Nav */
.has-child .submenu-toogle {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
  padding: 7px;
  font-size: 16px;
  display: none;
}

.has-child li .submenu-toogle {
  opacity: 0.9;
}

.has-child.nav-active>a+.submenu-toogle.fa-angle-down:before {
  content: "\f106";
}

.cms_cards{
  transition: all 0.3s linear;
}

.select2-container .select2-selection--single{
  height:34px !important;
}
.select2-container--default .select2-selection--single{
       border: 1px solid #ccc !important; 
   border-radius: 0px !important; 
}


.cms_cards:hover{
  transform: scale(1.04);
}

.admin-nav {
  max-height: calc(100vh - 128px);
  height: 100%;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.admin-nav ul {
  list-style: none;
  margin: 0;
}

.admin-nav ul li {
  float: none;
}

.admin-nav ul>li>a {
  padding: 20px 15px;
  font-size: 15px;
  color: #555;
  position: relative;
  display: block;
  font-weight: 400;
}

.admin-nav ul>li>a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  width: 5px;
  height: 0%;
  background: #ff8a00;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}


.admin-nav ul li a>svg,
.admin-nav ul li a>i {
  font-size: 18px;
  margin-right: 10px;
  color: #222;
  vertical-align: middle;
}

.admin-nav ul>li.active>a {
  color: #022279;
  background-color: #fff;
}

.admin-nav ul>li.active>a:after {
  content: "";
  position: absolute;
  top: 20%;
  right: 0px;
  width: 5px;
  height: 60%;
  background: #ff8a00;
}

.admin-nav ul>li:hover>a:after {
  content: "";
  position: absolute;
  top: 20%;
  right: 0px;
  width: 5px;
  height: 60%;
  background: #ff8a00;
}

@media only screen and (max-width:767px) {
  .admin-nav {
    max-height: calc(100vh - 175px);
  }
}


/*************************** 
SIMPLE INNER SCROLLBAR 
**************************/

.scrollbar-inner>.scroll-element,
.scrollbar-inner>.scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scrollbar-inner>.scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scrollbar-inner>.scroll-element.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%;
}

.scrollbar-inner>.scroll-element.scroll-y {
  height: 100%;
  right: 2px;
  top: 0;
  width: 5px;
}

.scrollbar-inner>.scroll-element .scroll-element_outer {
  overflow: hidden;
}

.scrollbar-inner>.scroll-element .scroll-element_outer,
.scrollbar-inner>.scroll-element .scroll-element_track,
.scrollbar-inner>.scroll-element .scroll-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.scrollbar-inner>.scroll-element .scroll-element_track,
.scrollbar-inner>.scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: 0.4;
}

.scrollbar-inner>.scroll-element .scroll-element_track {
  background-color: transparent;
}

.scrollbar-inner>.scroll-element .scroll-bar {
  background-color: #c2c2c2;
}

.scrollbar-inner>.scroll-element:hover .scroll-bar {
  background-color: #919191;
}

.scrollbar-inner>.scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191;
}

/* update scrollbar offset if both scrolls are visible */
.scrollbar-inner>.scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px;
}

.scrollbar-inner>.scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px;
}

.scrollbar-inner>.scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px;
}

.scrollbar-inner>.scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px;
}





.admin-nav ul>li>a:hover,
.admin-nav ul>li>a:active,
.admin-nav ul>li>a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.admin-nav ul>li .mega-menu>li:after {
  display: none;
}

.admin-nav ul li .sub-menu>li a {
  padding-left: 65px;
}

.admin-nav ul li .sub-menu>li a>i {
  font-size: 20px;
}

.admin-nav ul>li ul,
.admin-nav ul>li .sub-menu {
  display: none;
  position: static;
  visibility: visible;
  width: auto;
  background-color: rgba(0, 0, 0, 0.1)
}

.admin-nav ul li.has-child {
  position: relative;
}

.admin-nav ul li .sub-menu {
  opacity: 1;
  margin: 0 !important;
}

.has-child .submenu-toogle {
  display: block;
}

.admin-nav ul>li.has-child .open-close-admin-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 999;
  cursor: pointer;
  padding: 7px 8px;
  font-size: 14px;
}

.admin-nav ul>li.has-child.nav-active>.open-close-admin-btn:before {
  content: "\f068";
}

.content-admin-wraper {
  float: left;
  width: 100%;
  position: relative;
  z-index: 1
}

.content-admin-area {
  margin-left: 240px;
  padding: 70px 0 0;
  background-color: #F6F6FA;
  min-height: 100vh;
}

.content-admin-main {
  padding: 30px;
}

.content-admin-main h2 {
  font-size: 36px;
}

@media only screen and (max-width:991px) {
  .content-admin-main {
    padding: 15px;
  }
}


.content-admin-main .panel {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .06);
  -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, .06);
}

textarea.form-control {
  height: auto;
}

.aon-admin-header {
  display: table;
  width: 100%;
}

.aon-admin-header .aon-admin-header-left,
.aon-admin-header .aon-admin-header-right {
  display: table-cell;
}

.aon-admin-header .aon-admin-header-right {
  text-align: right;
}

.aon-admin-header-icon {
  display: inline-block;
  border: 2px solid #022279;
  width: 42px;
  height: 42px;
  line-height: 38px;
  margin-right: 5px;
  font-size: 22px;
  color: #022279;
  text-align: center;
  vertical-align: text-bottom;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

@media only screen and (max-width:991px) {
  .aon-admin-header {
    display: block;
    margin-bottom: 30px;
  }

  .aon-admin-header .aon-admin-header-left,
  .aon-admin-header .aon-admin-header-right {
    display: block;
  }

  .aon-admin-header .aon-admin-header-right {
    text-align: left;
  }
}


.aon-card {
  border: 1px solid #e6ebf1;
  margin-bottom: 30px;
  border-radius: 10px;
}

.aon-card:last-child {
  margin-bottom: 0px;
}

.aon-card-header {
  background-color: #fff;
  padding: 30px 30px 30px;
}

.aon-card-header2 {
  padding: 30px 30px 0px;
  border-bottom: none;
}

.aon-card-header h4 {
  margin: 0px;
}

.aon-card-body {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
}

@media only screen and (max-width:991px) {
  .content-admin-main {
    padding: 15px !important;
  }

  .aon-card-body {
    padding: 15px;
  }
}

@media only screen and (max-width:480px) {
  .aon-card-body>h3 {
    font-size: 20px;
  }
}

.aon-inputicon-box {
  position: relative;
}

.aon-inputicon-box .form-control {
  padding-left: 55px;
}

.aon-inputicon-box .aon-input-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 50px;
  z-index: 5;
  line-height: 50px;
  text-align: center;
}

.aon-inputicon-box .aon-input-icon:after {
  content: "";
  position: absolute;
  top: 30%;
  right: 0px;
  height: 40%;
  width: 1px;
  background: #ddd;
}


.content-admin-main .aon-inputicon-box .aon-input-icon {
  color: #6c7681;
}

.content-admin-main .form-control {
  background-color: #fff;
  border-color: #e8edf1;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 5px;
}

.content-admin-main .form-control:focus {
  background: #fff;
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
  border-color: #022279;
}

.content-admin-main .bootstrap-select .dropdown-toggle {
  background-color: #fff !important;
  border-color: #e8edf1 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 5px;
  padding: 10px 12px;
  height: 50px;
}

.content-admin-main .bootstrap-select .dropdown-toggle:focus {
  background: #fff;
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
}

@media only screen and (max-width:380px) {
  .aon-inputicon-box .form-control {
    padding-left: 40px;
  }

  .aon-inputicon-box .aon-input-icon {
    width: 35px;
  }

  .aon-inputicon-box .aon-input-icon:after {
    right: 2px;
  }
}

.editer-btn {
  text-align: right;
  position: relative;
  bottom: -1px;
}

.editer-tools {
  background-color: #f6f4f7;
  padding: 5px;
  border: 1px solid #ddd;
  margin-bottom: -1px;
}

.editer-textarea textarea {
  border: 1px solid #ddd;
  height: 120px;
  border-radius: 0;
  -webkit-border-radius: 0
}

.editer-bottom {
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: -1px;
}

.image-upload-wrap {
  padding: 40px 30px 50px;
  border: 2px dashed #918f8e;
  text-align: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

.image-upload-wrap strong {
  font-size: 36px;
  line-height: 36px;
  display: block;
}

.image-upload-wrap span {
  font-size: 20px;
  color: #7a96a8;
  display: block;
  margin-bottom: 20px;
}

@media only screen and (max-width:600px) {
  .image-upload-wrap strong {
    font-size: 26px;
    line-height: 30px;
  }
}

.admin-button,
.admin-button-secondry {
  color: #fff;
  padding: 10px 20px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  outline: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  line-height: 1.42857;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
}

.admin-button {
  background-color: #ff8a00;
  color: #fff;
}

.admin-button:active,
.admin-button:hover,
.admin-button:focus,
.active>.admin-button {
  background-color: #ff8a00;
  color: #fff;
}

.admin-button-secondry {
  background-color: #022279;
}

.admin-button-secondry:active,
.admin-button-secondry:hover,
.admin-button-secondry:focus,
.active>.admin-button-secondry {
  background-color: #022279;
  color: #fff;
}

.aon-staff-avtar-footer {
  padding: 30px;
  background: #fff;
  border: 1px solid #e8edf1;
}

.aon-staff-avtar-title {
  margin: 0px 0px 20px;
}

.aon-staff-avtar-footer ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.aon-staff-avtar-footer ul li {
  color: #414142;
  font-weight: 600;
  margin-bottom: 10px;
}

.aon-staff-avtar-footer ul li:last-child {
  margin-bottom: 0px;
}

.aon-staff-avtar-footer ul li span {
  color: #70777d;
  font-weight: normal;
}

@media only screen and (max-width:1200px) {
  .aon-staff-avtar {
    margin-bottom: 30px;
  }
}


@media only screen and (max-width:991px) {
  .admin-left-area {
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #dddd;
    margin-bottom: 15px;
  }

  .admin-area-heading strong {
    display: block;
  }
}

/*Popup tabs*/
.ws-toggle-popup {
  width: 410px;
  top: 60px;
  left: auto;
  right: -15px;
  padding: 0;
  margin-top: 50px;
  position: absolute;
  z-index: 1000;
  float: left;
  opacity: 0;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 10px;

  -webkit-box-shadow: 0 0 15px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 12px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

}

.header-widget {
  overflow: hidden;
}

.header-widget.active {
  overflow: visible;
}

.header-widget.active .ws-toggle-popup {
  display: block;
  margin-top: 0px;
  opacity: 1;
  overflow: hidden;
}

.ws-toggle-popup:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}


.popup-tabs-wrap-section {
  width: 410px;
  right: 0px;
}

.popup-tabs .head-notification {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}

.popup-tabs-head {
  padding: 20px 25px;
  border-bottom: 1px solid #e8edf1;
}

.popup-tabHead-right {
  color: #999;
  font-size: 14px;
}

.popup-tabs-footer {
  padding: 20px 25px;
  text-align: center;
  border-top: 1px solid #e8edf1;
}


.popup-tabs .nav-tabs {
  padding: 0px;
  border: none;
  margin: 0px -7px;
}

.popup-tabs ul>li {
  clear: inherit !important
}

.popup-tabs .nav-tabs .nav-item {
  position: relative;
  background: none;
}

.popup-tabs .nav-tabs .nav-item .nav-link.active {
  border: 0px;
  color: #fff;
  background: #022279;
}

.popup-tabs .nav-tabs>li>a {
  border: 0px;
}

.popup-tabs .nav-tabs>li>a:hover {
  background: #f7fafc;
  color: #022279;
}

.popup-tabs .nav-tabs>li>a:focus {
  background: #f7fafc;
  color: #022279;
}

.popup-tabs .nav-tabs .nav-item {
  margin: 0px 0px 15px 0px;
  padding: 0px 7px
}

.popup-tabs .nav-tabs .nav-item:last-child {
  margin-right: 0px;
}

.popup-tabs .nav-tabs .nav-link {
  color: #293742;
  padding: 10px 5px 10px 5px;
  display: inline-flex;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.popup-tabs .nav-tabs .nav-link::after {
  height: 2px;
  background: #38aef8;
}

.popup-tabs .nav-tabs .nav-link {
  display: block;
  font-size: 14px;
  color: #8494a4;
  display: block;
  font-family: 'Poppins', sans-serif;
}


.ws-poptab-listing {
  max-height: 300px;
}

.ws-poptab-list {
  padding: 15px 20px;
  border-bottom: 1px solid #f4f1f1;
  position: relative;
}

.ws-poptab-list:last-child {
  border-bottom: none;
}

.ws-poptab-media {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 44px;
  margin-right: 10px;
}

.ws-poptab-media img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  height: 100%;
}

.ws-poptab-info {
  padding: 0px 10px 0px 55px;
}

.ws-poptab-info h6 {
  color: #222;
  font-size: 16px;
}

.ws-poptab-info p {
  margin-bottom: 0px;
}

.ws-poptab-info .ws-time-duration {
  color: #999;
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 14px;
}

@media only screen and (max-width:1200px) {
  .ws-toggle-popup {
    width: 300px;
    margin-right: 0px;
  }

  .ws-toggle-popup::before {
    right: 70px;
  }

  .ws-poptab-info {
    padding: 0px 0px 0px 45px;
    font-size: 12px;
  }

  .ws-poptab-info .btn.btn-sm {
    padding: 3px 5px;
    font-size: 10px;
    line-height: 14px;
  }

  .ws-poptab-media {
    width: 40px;
    height: 40px;
  }

  .ws-poptab-media img {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width:480px) {
  .header-widget-wrap>.header-widget .ws-toggle-popup {
    right: -50px;
  }

  .header-widget-wrap>.header-widget:nth-child(2) .ws-toggle-popup {
    left: -80px;
  }

  .header-widget-wrap>.header-widget:nth-child(2) .ws-toggle-popup.ws-toggle-popup::before {
    left: 90px;
    right: auto;
  }

  .header-widget-wrap>.header-widget:nth-child(3) .ws-toggle-popup {
    left: auto;
    right: 0px;
    width: 200px;
  }

  .header-widget-wrap>.header-widget:nth-child(3) .ws-toggle-popup.ws-toggle-popup::before {
    left: 110px;
    right: auto;
  }

  .header-widget-wrap>.header-widget:nth-child(4) .ws-toggle-popup {
    right: -80px;
  }

  .header-widget-wrap>.header-widget:nth-child(4) .ws-toggle-popup.ws-toggle-popup::before {
    right: 136px;
  }
}

@media only screen and (max-width:360px) {
  .header-widget-wrap>.header-widget .ws-toggle-popup {
    right: 0px;
    margin-right: -100px;
  }

  .header-widget-wrap>.header-widget:nth-child(3) .ws-toggle-popup {
    width: 280px;
    margin-right: 0px;
  }
}

.aon-admin-search-box {
  margin-left: 20px;
}

.aon-admin-search {
  position: relative;
}

.aon-admin-search .form-control {
  border-radius: 30px;
  height: 36px;
  background: #f3f5f6;
  min-width: 250px;
}

.aon-admin-search .form-control:focus {
  box-shadow: none;
}

.aon-admin-search .admin-search-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  outline: none;
}

@media only screen and (max-width:1200px) {
  .aon-admin-search .form-control {
    min-width: 180px;
  }
}

@media only screen and (max-width:767px) {
  .aon-admin-search-box {
    display: none;
  }
}


.popup-curra-lang {
  padding: 0px;
  background: #fff;
  width: 180px;
}

.popup-curra-lang-list {
  list-style: none;
  margin: 0px;
}

.popup-curra-lang-list li {
  padding: 8px 20px;
  border-bottom: 1px solid #f0f2f3;
  font-size: 14px;
}

.popup-curra-lang-list li:last-child {
  border-bottom: none;
}

.user-welcome-area {
  padding: 0px;
  background: #fff;
  width: 220px;
  overflow: hidden;
}

.user-welcome-list {
  list-style: none;
  margin: 0px;
}

.user-welcome-list li {
  padding: 8px 20px;
  border-bottom: 1px solid #f0f2f3;
  font-size: 14px;
}



.popup-notifica-msg {
  padding: 0px;
  background: #fff;
}


.ws-poptab-list-wrap {
  margin-bottom: 0px;
  background: #f7fafc;
}

.ws-poptab-all a {
  border: 0px;
  color: #fff;
  background: #022279;
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
}


.admin-logout-area {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px 25px;
  background: #fff;
}

.pro-pic-info-wrap {
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

.pro-pic-info-wrap .pro-pic-box {
  width: 40px;
  height: 40px;
  float: left;
  border-radius: 50px;
  margin-right: 15px;
}

.pro-pic-info-wrap strong {
  display: block;
  color: #022279;
  line-height: 1.4;
}

.pro-pic-info-wrap span {
  display: block;
  color: #777;
  line-height: 1;
  font-size: 14px;
}

.feather-icon {
  display: flex !important;
  align-items: center;
  margin-left: 0px;
  padding-left: 0px;
  font-size: 20px !important;
  color: #022279 !important;
  cursor: pointer;
}

.feather-icon.has-toltip:hover .header-toltip {
  top: auto !important;
  bottom: 100% !important;
}


.action-dropdown .action-button {
  color: #022279;
  outline: none;
  border: none;
  padding: 5px 10px;
  background: none;
  color: #333;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.action-dropdown .action-button:after {
  display: none;
}

.action-dropdown .action-button:focus {
  color: #022279;
}

.action-dropdown .dropdown-menu {
  border: none;
  padding: 5px 0px;
  margin-top: 12px;
  left: auto;
  right: 100%;
  margin-right: -50px;
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1);
}

.action-dropdown .dropdown-menu:after {
  content: "";
  position: absolute;
  top: -7px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
}

.action-dropdown .dropdown-menu>li>a {
  padding: 5px 15px;
  font-size: 12px;
  color: #99a3aa;
}

.action-dropdown .dropdown-menu>li>a i {
  margin-right: 5px;
  font-size: 16px;
  vertical-align: middle;
  color: #293742;
}

.action-dropdown .dropdown-menu>li>a:hover {
  background: none;
  color: #000;
}

.action-dropdown .dropdown-menu>li>a:hover i {
  color: #000;
}

.action-dropdown.dropdown-left {
  display: inline-block;
}

.action-dropdown.dropdown-left .fa {
  vertical-align: middle;
}

.action-dropdown.dropdown-left .dropdown-menu {
  top: 5px !important;
  margin: 0 10px 0 0;
  right: 25px !important;
  left: auto !important;
  transform: none !important;
}

.action-dropdown .dropdown-menu:after {
  top: 10px;
  right: -14px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff;
}


/*table section*/

.ws-table-border-type .table {
  border: none !important;
}

.ws-table-border-type table>tbody>tr>th {
  padding: 5px;
}

.ws-table-border-type table>thead {
  background-color: #fff;
}

.ws-table-border-type table>tbody:before {
  content: "";
  display: block;
  height: 20px;
}

.ws-table-border-type table>thead>tr {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.ws-table-border-type table>tbody>tr {
  border-bottom: 1px solid #ddd;
}

.ws-table-border-type table>thead>tr>th {
  padding: 20px 15px;
  border-bottom: 0px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  vertical-align: middle;
}

.ws-table-border-type table>tbody>tr>td {
  padding: 20px 15px;
  border: 0px;
  font-size: 14px;
  color: #687178;
}

.ws-table-border-type table>tbody>tr>td .we-table-trash-btn {
  text-align: right;
}

.ws-table-border-type table>tbody>tr>td .we-table-trash-btn button {
  background-color: transparent;
  border: none;
}

.site-button-border {
  color: #555;
  background-color: #fff;
  padding: 10px 25px;
  display: inline-block;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  line-height: 1.42857;
  text-decoration: none !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.action-dropdown.dropdown-top .dropdown-menu {
  top: 100%;
  margin: 10px 0px 0 0;
  right: 0;
  min-width: 200px;
}

.action-dropdown.dropdown-top .dropdown-menu::after {
  top: -14px;
  right: 14px;
  border-bottom: 7px solid #fff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  left: auto;
}

.add-more-item {
  margin-right: 35%;
  margin-top: -100px;
  margin-bottom: 30px;
}

.add-more-btn {
  margin-bottom: 20px;
  outline: 0px !important;
}

@media only screen and (max-width:1200px) {
  .add-more-item {
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width:991px) {
  .add-more-item {
    margin-top: 30px;
  }
}


.active .add-more-btn .fa:before {
  content: "\f068";
}

.add-more-content {
  padding: 30px;
  border: 1px solid #ddd;
  display: none;
  border-radius: 5px;
}

.add-more-form.row:after {
  width: auto;
  height: 0;
}

.add-more-form.row>[class*="col-"]:last-child .form-group {
  margin: 0px;
}

.add-your-sign {
  margin-right: 35%;
  margin-top: 0px;
  margin-bottom: 30px;
}

@media only screen and (max-width:1200px) {
  .add-your-sign {
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.add-sign-priveiw {
  text-align: center;
  border: 1px solid #ddd;
  padding: 30px;
  width: 50%;
}

.add-sign-priveiw img {
  width: 180px;
}

.edit-part-name {
  position: relative;
}

.edit-part-name .site-button {
  position: absolute;
  top: 5px;
  right: 5px;
}

.add-payment-detail {
  margin-right: 35%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.add-payment-icon {
  font-size: 30px;
}

@media only screen and (max-width:1200px) {
  .add-payment-detail {
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.crad-tile-main {
  border-bottom: 1px solid #ddd;
}

.ws-custom-heading {
  display: flex;
  justify-content: space-between;
}

/*=== >text ===*/
.text-white {
  color: #fff;
}

.text-primary {
  color: #47b1f4;
}

.text-black {
  color: #000;
}

.text-gray {
  color: #f5f6f6;
}

.text-gray-dark {
  color: #d3d3d3;
}

.text-red {
  color: #ff6868;
}

.text-red-dark {
  color: #FF2020;
}

.text-green {
  color: #00d285;
}

.text-yellow {
  color: #ffc100;
}

.text-orange {
  color: #F60;
}

.text-blue {
  color: #2c80ff;
}

.text-sky {
  color: #21accd;
}

.text-purple {
  color: #802d75;
}


.radio-inline-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.radio-inline-box .sf-radio-checkbox {
  margin-right: 20px;
  margin-bottom: 10px;
}

.radio-inline-box .sf-radio-checkbox label {
  font-size: 14px;
}

.whatsapp-pic img {
  width: 22px;
}

.aon-con-btn {
  margin-bottom: 20px;
}

@media only screen and (max-width:1199px) {
  .aon-con-btn {
    text-align: left;
  }
}

.header-toltip {
  position: absolute;
  top: 80% !important;
  left: 50%;
  white-space: nowrap;
  background: #022279 !important;
  display: table;
  padding: 5px 10px !important;
  font-size: 11px !important;
  color: #fff !important;
  border-radius: 3px !important;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-family: "arial";
  font-weight: 600 !important;
  line-height: 16px !important;
}

.has-toltip:hover {
  overflow: visible !important;
  position: relative;
}

.has-toltip:hover .header-toltip {
  opacity: 1 !important;
  visibility: visible !important;
  top: 120% !important;
}


.header-menu {
  margin-right: 30px;
}

.header-menu .navbar-toggler {
  display: none;
}

.header-menu .nav>li {
  padding: 0px;
}

.header-menu .header-nav .nav>li>a {
  padding: 20px 10px;
  font-size: 14px;
}

.admin-nav-label {
  background: #ff8a00;
  padding: 4px 8px;
  margin-left: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.13);
  color: #fff;
  border-radius: 4px;
  text-align: center;
  font-weight: 100;
  font-size: 10px;
}

.sf-radio-check-2 {
  padding: 15px 0px;
  margin: 0px;
}

.sf-radio-check-2 label {
  margin: 0px;
}

.sf-radio-check-row {
  margin-left: -15px;
  margin-right: -15px;
}

.sf-raChe-6 {
  width: calc(50% - 30px);
  margin: 0px 15px 0px !important;
}

.grayscle-area {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}

@media only screen and (max-width:1200px) {
  .header-menu {
    display: none;
  }
}







.sf-select-box.form-control {
  background-color: #f7fafc;
  border-color: #e8edf1;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 5px;
  height: 50px;
}

.sf-select-box.form-control:focus {
  background: #fff;
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
}

.bootstrap-select.sf-select-box .dropdown-toggle {
  background-color: #f7fafc !important;
  height: 50px;
}

.set-bulk-btn {
  margin: 30px 0px;
}

.add-more-content2 {
  padding: 30px;
  border: 1px solid #ddd;
  display: none;
  border-radius: 10px;
}

.add-more-form.row:after {
  width: auto;
  height: 0;
}

.add-more-form.row>[class*="col-"]:last-child .form-group {
  margin: 0px;
}

/* tabs Section */

.sf-custom-tabs .nav-tabs>li>a.active {
  position: relative;
  opacity: 1;
}

.sf-custom-tabs .nav-tabs>li>a.active:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0%;
  width: 100%;
  background-color: #022279;
  height: 2px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sf-custom-tabs .nav-tabs>li.active>a {
  color: #fff;
  opacity: 1;
}

.sf-custom-tabs .nav-tabs>li.active>a:after {
  left: 0%;
  width: 100%;
  background-color: #fff;
}



/*Data table head*/
.sf-bd-data-tb-head {
  margin-bottom: 30px;
  text-align: right;
}

@media only screen and (max-width:420px) {
  .selected-working-hours ul li {
    width: 100%;
    text-align: center;
  }
}


.dropzone {
  min-height: 150px;
  border: 2px dashed rgb(2, 34, 121);
  background: white;
  padding: 20px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

.dropzone span {
  background-color: #ff8a00;
  padding: 10px 20px;
  border-radius: 30px;
  color: #fff;
  display: table;
  margin: auto;
}

.table-striped>tbody>tr:nth-of-type(2n+1) {
  --bs-table-accent-bg: #f6f8fa;
}


.page-item.active .page-link {
  background: #022279;
  border-color: #022279;
}

.page-link {
  color: #022279;
}

.page-link:hover {
  background-color: #022279;
  border-color: #022279;
  color: #fff;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin-top: 10px !important;
}

div.dataTables_wrapper div.dataTables_length select {
  min-width: 100px;
}

.model-w800 {
  max-width: 800px;
}




.action {
  width: 330px;
  display: none !important;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action .dx-selectbox {
  width: 90px;
}




.card-custom-area {
  display: table;
  width: 100%;
}

.card-custom-left {
  padding-left: 15px;
  padding-right: 15px;
  display: table-cell;
  width: 250px;
  vertical-align: top;
}

.card-custom-right {
  padding-left: 15px;
  padding-right: 15px;
}

.aon-pro-avtar-pic {
  padding: 30px;
  border-radius: 10px;
  background: #f8f9fb;
  border: 1px solid #ddd;
  position: relative;
}

.aon-proAvtar-media {
  border-radius: 10px;
  overflow: hidden;
}

.aon-proAvtar-btn {
  display: table;
  margin: 0px -35px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  border-radius: 5px;
  padding: 10px 17px;
  width: 70px;
}

.aon-proAvtar-btn:hover {
  position: absolute;
}

.aon-inputicon-box .bootstrap-select .dropdown-toggle {
  padding-left: 40px;
}

.aon-divider-line {
  height: 1px;
  background: #ddd;
}


.form-control.t-area-height {
  height: 50px !important;
}

.sf-sm-btn-link {
  background: none;
  padding: 0px;
  border: none;
  color: #022279;
}


.aon-med-pro-area {
  padding-top: 10px;
  background: #fff;
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
}

.aon-inputicon-box .form-select {
  padding-left: 55px;
}

.form-select {
  height: 50px;
  border-color: #e8edf1;
}

.form-select:focus {
  border-color: #06328b;
  outline: 0;
  box-shadow: none;
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
}

.form-group {
  margin-bottom: 25px;
}

label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}


.aon-table-custom>thead>tr>th {
  border-bottom: 1px solid #c4cada !important;
  border-top: 1px solid #c4cada !important;
  vertical-align: middle;
  padding-top: 15px;
  padding-bottom: 15px;
}

.aon-table-custom>tbody>tr>td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.dt-remove-btn {
  vertical-align: top;
  margin-left: 2px;
  padding: 3px 5px !important;
}

.aon-table-custom2>thead>tr>th {
  border-bottom: 1px solid #c4cada !important;
  vertical-align: middle;
  padding: 15px 8px !important;
}

.aon-table-custom2>tbody>tr>td {
  padding: 10px 0px !important;
}

.btn-primary {
  background: #ff8a00;
  border-color: #e17b04;
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
  background-color: #e17b04;
  border-color: #e17b04;
}


.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  outline: 0;
  box-shadow: none;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  box-shadow: none;
}

table .sf-radio-checkbox input[type="checkbox"]+label,
table .sf-radio-checkbox input[type="radio"]+label {
  margin: 0px;
}

.dataTables_wrapper .row:last-child {
  border-top: 1px solid #c4cada !important;
  padding-top: 20px;
}

.day-of-app {
  color: #777;
  font-size: 13px;
}

.day-of-app span {
  color: #ff8a00;
  cursor: pointer
}

.max-w150 {
  width: 200px;
}

.com-one {
  width: 25%
}

.com-two {
  width: 25%
}


.aon-team-wrap {
  padding: 30px;
  background: #fff;
  text-align: center;
  border: 1px solid #f2f3f7;
  border-radius: 10px;
  box-shadow: 0 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  position: relative;
}

.aon-team-wrap .aon-team-pic {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin: 0px auto 30px;
  position: relative;
}

.aon-team-wrap .aon-team-pic img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.aon-team-wrap .aon-team-pic:after {
  content: "";
  width: 170px;
  height: 170px;
  border-radius: 100%;
  margin: -10px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top: 2px solid #fe8900;
  border-bottom: 2px solid #fe8900;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  opacity: 0;
}

.aon-team-wrap .aon-team-pic:after {
  animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}


.aon-team-wrap:hover .aon-team-pic:after {
  opacity: 1;
}

.aon-team-name {
  margin-bottom: 0px;
}

.aon-team-wrap .action-dropdown {
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 25px;
}

.cs-message-panel {
  background-color: #fff;
  position: fixed;
  top: 0px;
  right: -100%;
  max-width: 600px;
  width: 100%;
  height: calc(100% - 0px);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 9999;
}

.active-team .cs-message-panel {
  right: 0%;
}

.active-edit-pro .cs-editpro-area {
  right: 0%;
}

.active-new-chart .cs-newchart-area {
  right: 0%;
}

.active-books-appointment .cs-books-appointment-area {
  right: 0%;
}

.active-edit-appointments .cs-edit-appointments-area {
  right: 0%;
}

body[class*="active-"] .cs-message-panel {
  box-shadow: 0 0px 150px 0px rgba(0, 0, 0, 0.3);
}



.cs-panel-close {
  position: absolute;
  top: 25px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  padding: 0;
}

.cs-panl-head {
  padding: 30px;
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
}

.cs-panl-body {
  padding: 30px;
  height: calc(100vh - 193px);
  overflow: auto;
  background-color: #f6f8fa;
}

.cs-panl-body2 {
  padding: 30px;
  height: calc(100vh - 93px);
  overflow: auto;
  background-color: #f6f8fa;
}

.cs-panl-footer {
  padding: 30px;
  width: 100%;
  border-top: 1px solid #dbdbdb;
}

.aon-pro-team-pic {
  width: 300px;
  height: 300px;
  margin: 0px auto 30px;
  display: table;
  border: 1px solid #edf0f2;
  border-radius: 30px;
  z-index: 1;
  position: relative;
  background: #fff;
  padding: 0px;
}

.aon-pro-team-pic img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.aon-pro-team-pic .admin-button {
  position: absolute !important;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 30px;
  bottom: 15px;
  left: 50%;
  margin: 0px -17px;
  border-radius: 100%;
  font-size: 13px;
  padding: 0px;
}

.aon-pro-team-pic .admin-button .fa {
  padding: 0px;
}

.aon-newChart-area {
  position: relative;
}

.aon-newChart-left {
  background-color: #f6f8fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 280px;
  height: calc(100vh - 130px);
  position: absolute;
  top: 0px;
  left: 0px;
}

.aon-newChart-right {
  padding-left: 310px;
}

@media only screen and (max-width:1199px) {
  .aon-newChart-right {
    padding-left: 0px;
  }

  .aon-newChart-left {
    width: 100%;
    position: relative;
    height: auto;
    margin-bottom: 30px;
  }

}

.aon-custom-nav {
  position: relative;
}

.aon-custom-nav:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background: #ddd;
}

.aon-custom-nav>.nav-item>.nav-link {
  color: #777;
  border-radius: 0;
  cursor: pointer;
}

.aon-custom-nav>.nav-item>.nav-link.active {
  color: #222;
  background: none;
  position: relative;
}

.aon-custom-nav>.nav-item>.nav-link.active:after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background: #06328b;
  opacity: 1;
  position: absolute;
  content: '';
  z-index: 1;
}

@media only screen and (max-width:767px) {
  .aon-custom-nav>.nav-item>.nav-link>h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width:640px) {
  .aon-newChart-list .aon-custom-nav {
    display: flex;
  }

  .aon-custom-nav {
    display: block;
  }

  .aon-custom-nav>.nav-item>.nav-link {
    padding-left: 0px;
  }
}

/*--------------------------------------------
  team membar section
---------------------------------------------*/

.team-list-nav {
  margin-bottom: 0px !important;
}

.team-list-nav .nav-item {
  width: 33.333%;
}

.team-list-nav .nav-item>.nav-link {
  padding: 15px 15px;
}

.sf-team-member-area {
  position: relative;
}

.sf-team-member-area-list {
  width: 350px;
  padding: 20px;
  border: 1px solid #dbdbdb;
  background: #f7fafc;
  border-radius: 5px;
}

.sf-team-member-search-list {
  height: calc(100vh - 192px);
}

@media only screen and (max-width:1200px) {
  .sf-team-member-search-list {
    max-height: 400px;
  }
}


.sf-team-member-list-wrap {
  display: table;
  width: 100%;
  margin-top: 10px;
}

.sf-team-member-list-wrap:hover .team-member-list-info {
  background-color: #06328b;
}

.team-member-list-info {
  position: relative;
  display: block;
  padding: 15px 80px 15px 20px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.sf-team-member-list-wrap .team-member-list-info .team-member-list-pic {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  float: left;
  margin-right: 15px;
  overflow: hidden;
  border-radius: 100%;
  border-radius: 100%;
  background: #eaeef1;
}

.sf-team-member-list-wrap .team-member-list-info .team-member-list-name {
  font-size: 14px;
  color: #000;
}

.sf-team-member-list-wrap .team-member-list-info .team-member-list-availability {
  font-size: 12px;
}

.sf-team-member-list-wrap .team-member-list-info .team-member-list-availability {
  color: #777;
}

.sf-team-member-list-wrap:hover .team-member-list-info .team-member-list-name,
.sf-team-member-list-wrap:hover .team-member-list-info .team-member-list-availability {
  color: #fff;
}



.sf-team-member-search {
  position: relative;
}

.sf-team-member-search .form-control {
  padding-right: 40px;
  border-radius: 5px !important;
  box-shadow: none;
  border: 0px;
  border: 1px solid #dbdbdb;
  background: #fff;
}

.sf-team-member-search .btn {
  outline: none !important;
  position: absolute;
  right: 0;
  top: 0;
  color: #CCCCCC;
  font-size: 20px;
  z-index: 10;
  background: none;
}

.sf-date-time-section {
  width: calc(100% - 370px);
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width:1200px) {
  .sf-team-member-area-list {
    width: 300px;
  }

  .sf-date-time-section {
    width: calc(100% - 330px);
  }
}

@media only screen and (max-width:1024px) {
  .sf-date-time-section {
    width: 100%;
    position: static;
  }

  .sf-team-member-area-list {
    width: 100%;
    margin-bottom: 30px;
  }
}


.create-newChart-list {
  position: relative;
  list-style: none;
  margin: 0px 0px 0px 30px;
}

.create-newChart-list:before {
  content: "";
  position: absolute;
  top: 0px;
  left: -30px;
  height: 100%;
  width: 3px;
  background: #ddd;
  border-radius: 5px;
}

.create-newChart-list li {
  position: relative;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 10px;
  margin-bottom: 30px;
}

.create-newChart-list li:before {
  content: "";
  position: absolute;
  top: 25px;
  left: -38px;
  height: 16px;
  width: 16px;
  background: #06328b;
  border-radius: 20px;
}

.create-newChart-head {
  padding-bottom: 15px;
}

@media only screen and (max-width:640px) {
  .create-newChart-head {
    display: block !important;
  }

  .create-newChart-head .create-newChart-left {
    margin-bottom: 10px;
  }
}

.create-newChart-head+.create-newChart-body {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.create-newChart-date {
  color: #ff8a00;
}

.create-newChart-emty {
  padding: 30px;
  text-align: center;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 10px;
  color: #999;
}

.create-user-infos {
  display: none;
  padding: 30px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 10px;
  color: #999;
  background-color: #fff;
}

.create-user-notess {
  display: none;
  padding: 30px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 10px;
  color: #999;
  background-color: #fff;
}


.aon-newChart-edit {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  background-color: #fff;
}

@media only screen and (max-width:640px) {
  .aon-newChart-edit [class*='col-'].text-right {
    text-align: left;
    margin-top: 30px;
  }
}

@media only screen and (max-width:480px) {
  .aon-newChart-edit .row [class*='col-'] {
    display: block !important;
  }

  .pecen-pic {
    margin-bottom: 10px;
  }
}

.pecen-pic {
  width: 110px;
  height: 110px;
  margin-right: 25px;
  overflow: hidden;
  border-radius: 10px;
}

.pecen-email i,
.pecen-phone i {
  color: #000;
  margin-right: 5px;
}

.pecen-sex {
  display: inline-block;
  margin-right: 10px;
}

.pecen-sex span {
  color: #000;
}

.aon-admin-calender {
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
  background-color: #f6f8fa;
}

.aon-newChart-title {
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding: 15px 0px;
}

.sf-form-textarea {
  height: auto;
}

.active-time-slide .cs-timeSlide-area {
  right: 0px;
}

.cs-timing-list {
  list-style: none;
  margin: 0px 0px 30px;
}

.cs-timing-list li {
  display: inline-block;
  margin-right: 20px;
}

@media only screen and (max-width:580px) {
  .cs-timing-list li {
    width: 70px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.input_Timing-box {
  border: 1px solid #ddd;
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;
  position: relative;
}

.input_fields_Timing .form-group {
  margin: 0px;
}

.input-timing-close {
  position: absolute;
  top: 25px;
  right: 20px;
  background: none;
  border: none;
  font-size: 18px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background: #F8374A;
  color: #fff;
  padding: 0;
  border-radius: 3px;
}

.input-timing-close:hover {
  color: #fff;
}

.aon-insurance-text {
  max-width: 800px;
  margin: auto;
}



.aon-feedback-list {
  position: relative;
  list-style: none;
  margin: 0px 0px 0px 150px;
}

.aon-feedback-list:before {
  content: "";
  position: absolute;
  top: 0px;
  left: -30px;
  height: 100%;
  width: 3px;
  background: #ddd;
  border-radius: 5px;
}

.aon-feedback-date {
  color: #fff;
  position: absolute;
  display: block;
  left: -150px;
  top: 0px;
  background: #ff8a00;
  padding: 15px;
  border-radius: 5px;
}


.aon-feedback-list>li {
  position: relative;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 10px;
  margin-bottom: 30px;
}

.aon-feedback-list>li:before {
  content: "";
  position: absolute;
  top: 25px;
  left: -38px;
  height: 16px;
  width: 16px;
  background: #fff;
  border-radius: 20px;
  border: 5px solid #063285;
}

.aon-feedback-head {
  padding-bottom: 15px;
}

.aon-feedback-head+.create-newChart-body {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.aon-feedback-date {
  color: #fff;
  position: absolute;
  display: block;
  left: -150px;
  top: 0px;
  background: #ff8a00;
  padding: 15px;
  border-radius: 5px;
}

.aon-feedback-title {
  margin-bottom: 15px;
}

.aon-feedback-date:after {
  content: "";
  position: absolute;
  top: 27px;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 12px;
  border-color: transparent transparent transparent #ff8a00;
}

.create-feedback-body {
  border-top: 1px solid #ddd;
  padding-top: 15px;
  margin-top: 13px;
}


.aon-feedback-date span {
  display: block;
  font-family: arial;
  line-height: 1;
}

.aon-feedback-date .aon-day {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}

.aon-feedback-date .aon-month {
  font-size: 14px;
  margin-bottom: 5px;
}

.aon-feedback-date .aon-year {
  font-size: 14px;
}

.aon-publish-label {
  display: inline-block;
  margin-right: 20px;
  color: #ff8a00;
  font-size: 14px;
  border-radius: 5px;
  line-height: 1.2;
}



/*23 December */
.card-reg-form-lr-wrap {
  display: flex;
  justify-content: space-between;
}

.card-reg-form-left {
  max-width: 540px;
  width: 100%;
  margin-right: 1%;
}

.card-reg-form-left {
  max-width: 540px;
  width: 100%;
}

.card-cost-section-block ul {
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.card-cost-section-block ul li {
  width: 20%;
  display: flex;
}

.card-cost-section-block ul li .card-cost-inner {
  width: 100%;
  margin: 10px;
  background-color: #fff;
  padding: 50px 5px;
  border: 1px solid #06328b;
  border-radius: 20px;
  text-align: center;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #ff8a00;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.calen-list-nav {
  margin-bottom: 40px;
  text-align: center;
}

.calen-list-nav .nav-item {
  width: 33.333%;
}

.calen-list-nav .nav-item>.nav-link {
  padding: 15px 15px;
}

.ins-type {
  padding: 50px;
  background-color: #fff;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #ddd;
}

.ins-type span {
  display: inline-block;
  max-width: 100px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.ins-type span:after {
  width: 80px;
  height: 80px;
  content: '';
  background-color: #022279;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  opacity: 0.1;
  transform: translate(-50%, -50%);
}

.ins-type p {
  margin-bottom: 0px;
}

.sf-insurance-partners {
  margin-bottom: 30px;
}

.partners-logo-wrap .row {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.partners-logo {
  background-color: #fff;
  text-align: center;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.partners-logo a {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 50px;
}

.sf-partner-head {
  margin-bottom: 30px;
}

.partners-logo-wrap {
  max-width: 991px;
  margin: 0px auto;
}

.partners-info-wrap {
  max-width: 991px;
  margin: 0px auto;
  border: 1px solid #ddd;
  border-top: 0px;
  background-color: #fff;
  text-align: center;
}

.partners-info-wrap .row [class*='col-'] {
  border-right: 1px solid #ddd;
}

.partners-info-wrap .row [class*='col-']:last-child {
  border-right: 0px;
}

.partners-info-wrap .row [class*='col-'] .partners-info {
  padding: 50px;
}

.partners-info h2 {
  color: #ff8a00;
}

@media only screen and (max-width:1600px) {
  .ins-type {
    padding: 30px;
  }
}


/*Appointment*/

.sf-appoi-row {
  margin-left: 0px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;
}

.aon-date-box {
  position: absolute;
  top: 0px;
  left: 0px;
  background: #ff8a00;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
}

.aon-date-box strong,
.aon-date-box span {
  display: block;
  text-align: center;
  line-height: 1;
}

.aon-date-box strong {
  font-size: 30px;
  margin-bottom: 5px;
}

.aon-date-box span {
  font-size: 12px;
}

@media only screen and (max-width:991px) {
  .sf-appoi-row>.d-flex {
    display: block !important;
  }

  .sf-appoi-first {
    margin-bottom: 10px;
  }

  .sf-appoi-second {
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 15px;
  }

  .sf-appoi-third {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.sf-appoi-first {
  position: relative;
  padding-left: 65px;
}

.sf-appoi-first h4 {
  margin: 0px;
}

.sf-appoi-first img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  float: left;
  margin-right: 15px;
}

.sf-appoi-first p {
  margin-bottom: 0px;
}

@media only screen and (max-width:480px) {
  .sf-appoi-first {
    padding-left: 0px;
    display: block !important;
    text-align: center;
  }

  .aon-date-box {
    position: inherit;
  }

  .sf-appoi-first img {
    float: none;
    margin-bottom: 10px;
  }

  .sf-appoi-info {
    margin-bottom: 10px;
  }

  .sf-appoi-second {
    margin-bottom: 10px;
    display: block;
    margin-right: 0px;
    text-align: center;
  }
}

.sf-appoint-doc-wrap {
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 55px 30px 30px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.sf-appoint-doc-wrap:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #022279;
  height: 140px;
  width: 100%;
  z-index: -1;
}

.sf-appoint-doc-info {
  text-align: center;
}

.sf-appoint-doc-date {
  padding-top: 25px;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  text-align: center;
}

.sf-appoint-doc-date p {
  margin-bottom: 5px;
}

.sf-appoint-doc-info img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.sf-appint-control {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  list-style: none;
}

.sf-appint-control li a span {
  width: 50px;
  height: 50px;
  font-size: 22px;
  line-height: 50px;
  border: 1px solid #022279;
  border-radius: 50%;
  color: #022279;
  display: inline-block;
  margin-bottom: 10px;
}

.sf-appointment-full-detail {
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f6f8fa;
}

.sf-doc-apt {
  margin-bottom: 50px;
}

@media only screen and (max-width:480px) {
  .sf-doc-apt {
    margin-bottom: 10px;
  }
}

.sf-doc-apt .accordion-button:focus {
  box-shadow: none;
}

.sf-doc-apt .accordion-button:not(.collapsed) {
  color: #022279;
  background-color: transparent;
}

.sf-appointment-status {
  border: 1px solid #022279;
  padding: 20px;
  border-radius: 10px;
}

.sf-appointment-status ul {
  list-style: none;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
}

.sf-appointment-status ul li span {
  font-size: 18px;
  color: #022279;
}

.sf-appointment-full-detail .accordion-item {
  background: none;
}

.sf-appointment-full-detail .accordion-button {
  background: none;
  padding: 20px 0px;
}

.sf-appointment-full-detail .accordion-body {
  padding: 20px 0px;
}

.active-feedback-slide .cs-feedback-area {
  right: 0px;
}

@media only screen and (max-width:480px) {
  .sf-appointment-status ul {
    display: block;
  }

  .sf-appointment-status ul li {
    margin-bottom: 20px;
  }

  .sf-appointment-status ul li:last-child {
    margin-bottom: 0px;
  }

  .sf-appointment-status ul li p {
    margin-bottom: 10px;
  }
}

/*Upload Form*/
.sf-uplaod-files-wrap {
  margin: 0px auto 50px;
  border: 1px solid #ddd;
  background-color: #f6f8fa;
  border-radius: 10px;
  padding: 30px;
}

.sf-uplaod-files-wrap .sf-upload-control {
  border-top: 1px solid #ddd;
  padding-top: 30px;
  margin-bottom: 0px;
}

.sf-uplaod-files-wrap .sf-upload-control a {
  text-align: center;
  display: block;
  background: #fff;
  border: 1px solid #ddd;
  padding: 30px;
  border-radius: 10px;
}

.sf-uplaod-files-wrap .sf-upload-control a:focus {
  border: 1px solid #022279;
}

.sf-uplaod-files-wrap .sf-upload-control a span {
  font-size: 22px;
  line-height: 20px;
  color: #022279;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

.sf-uplaod-files-wrap .sf-upload-control a p {
  margin-bottom: 0px;
}


/*Patient Booking*/
.sf-appoint-doc-wrap2 {
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 30px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.sf-appoint-doc-info2 {
  text-align: center;
}

.sf-appoint-doc-date2 {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  text-align: center;
}

.sf-appoint-doc-date2 {
  padding-top: 20px;
  margin-top: 30px;
  border-top: 1px solid #ddd;
  text-align: center;
}

.sf-appoint-doc-info2 img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}


.aon-search2-form {
  padding: 25px 0px;
}

.aon-search2-form.no-p-lr {
  padding: 25px 0px;
}

.aon-search2-bar-wrap {
  background: #06328b;
}

.aon-search2-left {
  width: 80%;
  display: table;
}

.aon-search2-col {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 5px;
}

.aon-search-btn .site-button {
  width: 100%;
}

.aon-search2-right {
  width: 20%;
}

.aon-search2-btn {
  padding: 10px 25px;
}

.aon-search2-right.a-s-auto {
  width: auto;
}

.aon-search2-table {
  display: table;
  width: 100%;
}

.aon-search-advance-btn {
  background: none;
  border: none;
  color: #fff;
  float: right;
}

.aon-search-advance-btn i {
  font-size: 28px;
  margin-right: 10px;
}

.aon-search-advance-btn strong {
  display: block;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
}

.aon-search-advance-btn span {
  display: block;
  font-size: 16px;
  line-height: 1;
}

.aon-search-advance-btn span:last-child {
  letter-spacing: 1px;
  letter-spacing: 4.5px;
}

@media only screen and (max-width:991px) {
  .aon-search2-left {
    width: 100%;
  }

  .aon-search2-table {
    width: 100%;
    display: block;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .aon-search2-col.aon-search-col,
  .aon-search2-col.aon-category-col {
    display: block;
    width: 50%;
    margin-bottom: 15px;
  }

  .aon-search2-col.aon-search-btn {
    width: 100%;
  }

  .aon-search2-right.a-s-auto {
    margin: auto;
  }
}

@media only screen and (max-width:767px) {

  .aon-search2-left,
  .aon-search2-right {
    width: 100%;
  }

  .aon-search2-left {
    margin-bottom: 15px;
  }

  .aon-search2-right.a-s-auto {
    margin: auto;
  }
}

@media only screen and (max-width:767px) {

  .aon-search2-col.aon-search-col,
  .aon-search2-col.aon-category-col {
    width: 100%;
  }
}



.aon-search-heading {
  border-bottom: 1px solid #ddd;
}

.aon-search-row {
  padding-top: 30px;
  padding-bottom: 30px;
}

.aon-search-list-grid {
  list-style: none;
  margin: 0px;
}

.aon-search-list-grid .btn {
  font-size: 20px;
  margin-left: 10px;
  border: 1px solid #ddd;
}

@media only screen and (max-width:767px) {
  .aon-search-list-grid.d-flex.pull-right {
    float: left;
    margin-top: 10px;
  }

  .aon-search-list-grid li:first-child .btn {
    margin-left: 0px;
  }
}

.aon-search-info-wrap {
  padding: 40px 0px 0px;
}


.aon-footer-bottom-area {
  text-align: center;
}


/*Grid View*/
.sf-grid-view {
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, .1);
  padding: 30px;
  margin-bottom: 30px;
}

.sf-grid-head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.sf-medi-rate span {
  display: inline-block;
  padding-right: 2px;
  color: #ff8a00;
  font-size: 16px;
}

.sf-medi-rate span.sf-rating-lable {
  color: #00a900;
  font-size: 14px;
}

.sf-medi-like i {
  font-size: 22px;
  margin-right: 5px;
}

.bage-green {
  border-radius: 6px;
  background-color: #1c8315;
  color: #fff;
  padding: 2px 6px;
}

.sf-grid-view-mid .sf-grid-media {
  position: relative;
  margin: 0px auto 20px;
  width: 140px;
}

@media only screen and (max-width:420px) {
  .sf-grid-view-mid .sf-grid-info h3 {
    font-size: 20px;
  }
}

.sf-grid-view-mid .sf-grid-media img {
  width: 100%;
  border-radius: 10px;
}

.sf-grid-view-mid .sf-grid-media i {
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #59b153;
  display: block;
  box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.2);
}

.sf-grid-info {
  text-align: center;
  font-size: 16px;
}

.sf-doc-exp {
  font-size: 14px;
}

.sf-doct-info span {
  color: #2f2f2f;
}

.sf-doc-profile-view {
  font-size: 14px;
  color: #06328b;
}

.sf-search-result-option2 {
  padding: 0px;
}


/*Grid View 2*/
.sf-shadow-box {
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.aon-Grid2-box {
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 60px;
}

.aon-Grid2-head {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.aon-Grid2-exper {
  line-height: 1;
}

.aon-Grid2-pic {
  width: 50px;
  height: 50px;
  border-radius: 30px;
  float: left;
  margin-right: 15px;
  position: relative;
}

.aon-Grid2-pic img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.aon-Grid2-pic .fa {
  position: absolute;
  left: 0px;
  bottom: 0px;
  background: #1c8315;
  color: #fff;
  font-size: 10px;
  padding: 4px;
  border-radius: 10px;
}


.aon-Grid2-info {
  overflow: hidden;
}

.aon-Grid2body {
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.aon-Grid2-details {
  font-size: 14px;
}

.aon-Grid2-exper {
  margin-bottom: 10px;
}

.sf-medi-like2 {
  padding-bottom: 10px;
}

.aon-Grid2-specialist {
  padding-left: 30px;
  padding-bottom: 10px;
  position: relative;
}

.aon-Grid2-specialist span {
  color: #8d8d8d;
}

.aon-Grid2-specialist i {
  position: absolute;
  left: 0px;
  top: 2px;
  color: #000;
  font-size: 20px;
}

.aon-Grid2-red-less {
  color: #ff8a00;
  cursor: pointer;
}

.bage-green2 {
  border-radius: 6px;
  background-color: #1c8315;
  color: #fff;
  padding: 2px 6px 4px;
  display: inline-flex;
  line-height: 1;
  align-items: center;
  font-size: 14px;
}

.aon-Grid2-footer {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: -60px;
  text-align: center;
}

.aon-Grid2-fee {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.aon-Grid2-fee span {
  color: #000;
}

.aon-Grid2-book-now {
  color: #ff8a00;
}


/*listing View*/
.sf-listing-view {
  display: table;
  width: 100%;
  border: 1px solid #ddd;
  background-color: #fff;
  margin-bottom: 30px;
}

.sf-listing-media-wrap {
  display: table-cell;
  padding: 30px;
  vertical-align: top;
  width: 0px;
}

.sf-listing-media {
  position: relative;
  width: 140px;
  text-align: center;
}

.sf-listing-media img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.sf-listing-media i {
  width: 23px;
  height: 23px;
  line-height: 23px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: #59b153;
  display: block;
  box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.2);
}

.sf-listing-info {
  display: table-cell;
  padding: 30px 25px 30px 10px;
  vertical-align: top;
}

.sf-listing-info-right {
  display: table-cell;
  padding: 30px;
  vertical-align: top;
  border-left: 1px solid #ddd;
  width: 305px;
}

.sf-siderbar-map {
  border: 1px solid #ddd;
}

.search-filter-box {
  padding: 30px;
  border-bottom: 1px solid #ddd;
}

.sf-radio-input {
  border: 1px solid #e8edf1;
  padding: 12px 15px;
  border-radius: .25rem;
}

@media only screen and (max-width:1199px) {
  .sf-listing-info-right {
    width: 260px;
    padding: 20px;
  }

  .sf-listing-info {
    padding: 20px 15px 20px 0px;
  }

  .sf-listing-media-wrap {
    padding: 20px;
  }

  .sf-listing-media {
    width: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .sf-listing-info-right {
    display: flex;
    width: 100%;
    border-left: none;
    border-top: 1px solid #ddd;
  }
}


.sf-listing-con-details {
  padding-bottom: 10px;
  font-size: 14px;
  color: #8d8d8d;
}

.sf-listing-con-details .sf-medi-like .bage-green {
  margin-right: 10px;
}

.sf-listing-con-details .sf-medi-like i {
  color: #000;
}


.sf-listing-con-timeing {
  padding-left: 30px;
  padding-bottom: 10px;
  position: relative;
}

.sf-listing-con-timeing i {
  position: absolute;
  left: 0px;
  top: 2px;
  color: #000;
  font-size: 20px;
}

.sf-listing-con-timeing span {
  display: inline-block;
}

.sf-listing-con-timeing span.open {
  color: #1c8315;
}

.sf-listing-con-timeing span.close {
  color: #ff4444;
}

.sf-listing-con-address {
  padding-left: 30px;
  padding-bottom: 10px;
  position: relative;
}

.sf-listing-con-address i {
  position: absolute;
  left: 0px;
  top: 2px;
  color: #000;
  font-size: 20px;
}

.sf-listing-con-address p {
  color: #8d8d8d;
  font-size: 14px;
  margin-bottom: 0px;
}

.sf-listing-con-address p span {
  color: #000;
}

.sf-listing-right-footer {
  margin-left: 30px;
  display: inline-table;
}

.sf-listing-right-footer p {
  font-style: italic;
  font-size: 12px;
  text-align: center;
  color: #08a300;
  margin-bottom: 0px;
}

.sf-listing-right-footer p span {
  color: #000;
}

@media only screen and (max-width:991px) {
  .sf-listing-view {
    display: block;
  }

  .sf-listing-media {
    width: 100px;
  }

  .sf-listing-info {
    padding: 30px 25px 30px 0px;
  }
}

@media only screen and (max-width:767px) {
  .sf-listing-info-right {
    display: block;
    padding: 30px;
    border-left: none;
    width: 100%;
    border-top: 1px solid #ddd;
  }
}

@media only screen and (max-width:420px) {
  .sf-listing-view .sf-listing-info h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width:400px) {
  .sf-listing-media-wrap {
    width: 100%;
    display: block;
    padding-bottom: 0px;
  }

  .sf-listing-info {
    width: 100%;
    display: block;
    padding: 20px;
  }

  .sf-listing-info-right {
    padding: 20px;
  }

  .sf-listing-right-footer {
    margin-left: 0px;
  }
}


/*Site Pagination*/
.s-p-center {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.site-pagination {
  clear: both;
}

.site-pagination .pagination>li>a,
.site-pagination .pagination>li>span {
  margin: 0px 3px;
  border: none;
  border-radius: 30px !important;
  text-align: center;
}

.site-pagination .pagination>li>a .fa {
  font-size: 12px;
}

.site-pagination .pagination>li>a {
  color: #022278;
}

.site-pagination .page-item.active .page-link {
  background-color: #ff8a00;
  border-color: #ff8a00;
}

.site-pagination .pagination>li>a,
.site-pagination .pagination>li>span {
  margin: 0px 5px;
  border: none;
  border-radius: 30px !important;
  padding: 5px 2px;
  min-width: 36px;
}

.site-pagination .pagination>li>a:hover,
.site-pagination .pagination>li>span:hover {
  color: #fff;
  background-color: #ff8a00;
  border-color: #ff8a00;
}




.site-footer.footer-light {
  background-color: #d2e7e2;
  border-top: 1px solid #647772;
}

.aon-footer-bottom-area {
  border-top: 1px solid #aabcb7;
  color: #6b6b6b;
  padding-top: 30px;
}

.aon-socila-icon {
  list-style: none;
  margin: 20px 0px 0px;
}

.aon-socila-icon li {
  margin-right: 15px;
}

.aon-socila-icon li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #ff8a00 !important;
  background: #fff;
  display: block;
  border-radius: 30px;
}


.aon-f-title {
  padding-bottom: 10px;
  margin-bottom: 30px;
  font-size: 22px;
  position: relative;
}

.aon-f-title:after {
  position: absolute;
  left: 0px;
  bottom: 0px;
  content: "";
  width: 60px;
  height: 2px;
  background: #ff8a00;
  display: table;
}

.aon-doctorAbout-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
  padding-bottom: 10px;
}

.aon-doctorAbout-left {
  position: relative;
  text-align: center;
}

.aon-doctorAbout-pic {
  width: 180px;
  height: 180px;
  padding: 8px;
  border-radius: 100%;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  position: relative;
}

.aon-doctorAbout-pic img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.aon-doctorAbout-pic .fa {
  position: absolute;
  bottom: -10px;
  left: 50%;
  background: #4ecd69;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0px -15px;
  text-align: center;
  display: table;
  border-radius: 30px;
  z-index: 1;
}

.aon-doctorAbout-pic::after {
  content: "";
  width: 184px;
  height: 184px;
  border-radius: 100%;
  margin: -2.5px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top: 5px solid #fe8900;
  border-bottom: 5px solid #fe8900;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  opacity: 1;
  transform: rotate(115deg);
}

.aon-doctorAbout-pic:after {
  animation: rotate 1.5s ease linear;
}

.aon-doctorAbout-left:hover .aon-doctorAbout-pic:after {
  opacity: 1;
  animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}




.aon-doctorAbout-right {
  display: flex;
  flex-basis: auto;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-left: 40px;
  position: relative;
}

.aon-doctorAbout-name {
  margin-bottom: 10px;
}

.aon-doctorAbout-type {
  margin-bottom: 10px;
}

.aon-doctorAbout-rating {
  margin-bottom: 10px;
}

.aon-doctorAbout-contat {
  margin: 0px 0px 10px;
  list-style: none;
}

.aon-doctorAbout-contat li {
  display: inline-block;
  margin-right: 10px;
}

.aon-doctorAbout-contat li i {
  margin-right: 5px;
  vertical-align: middle;
}

.aon-doctorAbout-rating {
  display: inline-block;
  padding-right: 2px;
  color: #ff8a00;
  font-size: 16px;
}

.aon-doctorAbout-rating .aon-rating-lable {
  color: #00a900;
  font-size: 14px;
}

.aon-doctorAbout-exper {
  margin-bottom: 10px;
}

.aon-doctorAbout-text {
  margin-bottom: 20px;
}

.aon-doctorAddto-share {
  list-style: none;
  margin-top: 10px;
}

.aon-doctorAddto-share li {
  display: inline-block;
  margin-right: 15px;
}

.aon-doctorAddto-share li a {
  display: block;
  border: 1px solid #ddd;
  padding: 2px 12px;
  border-radius: 5px;
}

.aon-doctorAbout-label {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #e3f4fa;
  color: #3e8198;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}

@media only screen and (max-width:1199px) {
  .aon-doctorAbout-label {
    position: inherit;
  }
}

@media only screen and (max-width:767px) {
  .aon-doctorAbout-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .aon-doctorAbout-right {
    padding-left: 0px;
  }

  .aon-doctorAbout-left {
    margin-bottom: 30px;
  }
}

.aon-doctoreducation-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

.aon-doctoreducation-wrap.bdr-none {
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.aon-award-list {
  list-style: none;
  margin: 0px;
}

.aon-award-list li {
  margin-bottom: 30px;
}

.aon-award-list li:last-child {
  margin-bottom: 0px;
}

.aon-award-list .aon-award-pic {
  margin-right: 20px;
  float: left;
}

@media only screen and (max-width:420px) {
  .aon-award-list .aon-award-pic {
    margin-bottom: 20px;
    float: none;
  }
}

.aon-docLocation-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

.aon-docPexpi-wrap {
  margin-bottom: 50px;
}



.aon-side-bar {
  background: #f4fcfb;
  border: 1px solid #dce9e7;
  border-radius: 10px;
}

.aon-siderbar-nav {
  margin: 30px;
  list-style: none;
  position: relative;
}

@media only screen and (max-width:420px) {
  .aon-siderbar-nav {
    margin: 20px;
  }
}

.aon-siderbar-nav:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 8px;
  height: calc(100% - 22px);
  width: 1px;
  background: #5d908b;
  margin: 11px 0px;
}

.aon-siderbar-nav li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 30px;
}

.aon-siderbar-nav li:after {
  content: "";
  position: absolute;
  top: 11px;
  left: -3px;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background: #1b605b;
}

.aon-siderbar-nav li:last-child {
  margin-bottom: 0px;
}

.aon-siderbar-nav li.active a {
  color: #ff8a00;
}

.aon-siderbar-nav li.active:after {
  background: #ff8a00;
}

.aon-consultancy-price {
  font-size: 20px;
}

.aon-find-doctors-wrap {
  border-top: 1px solid #ddd;
}

@media only screen and (max-width:420px) {
  .aon-find-doctors-wrap {
    padding: 20px;
  }
}

.aon-doctor-tabs {
  margin-bottom: 30px !important;
}

.aon-doctorTotal-rating {
  border: 2px solid #1b605b;
  padding: 20px;
  border-radius: 10px;
  white-space: nowrap;
}



.aon-doctorReview-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

.aon-docRating-title {
  font-size: 15px;
  text-align: center;
}

.aon-docRating-num {
  font-size: 48px;
  line-height: 1;
}

.aon-doctorReview-left {
  position: relative;
  text-align: center;
  min-width: 140px;
}

.aon-doctorReview-right {
  display: flex;
  flex-basis: auto;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-left: 40px;
  position: relative;
}

@media only screen and (max-width:460px) {
  .aon-doctorReview-wrap {
    display: block !important;
  }

  .aon-doctorReview-left {
    margin-bottom: 20px;
  }

  .aon-doctorReview-right {
    padding-left: 0px;
  }
}

.aon-doctorInsuranc-tabline {
  margin-bottom: 30px;
}

.aon-docReview-list {
  list-style: none;
  margin: 0px;
}

.aon-docReview-list li {
  margin-bottom: 20px;
}

.aon-docReview-list p {
  margin-bottom: 10px;
}

.aon-docReview-list .aon-docReview-more {
  color: #ff8a00;
}

.aon-docReview-list .aon-docReview-meta {
  color: #222;
}

.aon-doctorInsuranc-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

.aon-insuranc-logo {
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}


.aon-doctorSpecializ-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

.aon-insuranc-list {
  display: -ms-flexbox;
  display: flex;
}

.aon-insuranc-list .feather-check {
  font-size: 18px;
  color: #4ecd69;
  padding-top: 5px;
  padding-right: 5px;
}

.aon-doctorLang-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

.aon-doctorLang-title {
  margin-bottom: 30px;
}

.aon-docLang-pic {
  margin-right: 10px;
}

/*Doctor Detail*/

@media only screen and (max-width:991px) {
  .aon-profile1-page-wrap {
    padding-bottom: 10px;
  }
}

.card-shadow-box {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width:420px) {
  .card-shadow-box {
    padding: 20px;
  }
}

.sf-dco-profile-card {
  background-color: #1b6059;
  padding: 30px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.sf-dco-profile-card:before {
  z-index: -1;
  content: '';
  position: absolute;
  left: 50%;
  top: 60px;
  width: 200%;
  height: 500px;
  background-color: rgba(255, 255, 255, 0.1);
  transform: rotate(-10deg) translateY(-50%) translateX(-50%);
  opacity: 0.4;
}

.sf-doc-pro-card-media {
  width: 245px;
  height: 245px;
  margin: 0px auto 30px;
  border: 2px solid rgba(204, 204, 204, 0.2);
  border-radius: 50%;
  padding: 27px;
  position: relative;
}

.sf-doc-pro-card-media:after {
  content: '';
  position: absolute;
  left: -4px;
  top: -4px;
  width: 248px;
  height: 248px;
  transform: rotate(10deg);
  border-radius: 50%;
  padding: 27px;
  border-left: 6px solid #ff8a00;
  border-right: 6px solid #ff8a00;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.sf-doc-pro-card-media:hover:after {
  animation: rotate 1.5s infinite linear;
}

.sf-doc-pr-media-inner {
  position: relative;
  text-align: center;
}

.sf-doc-pr-media-inner img {
  width: 187px;
  height: 187px;
  border-radius: 50%;
}

.sf-doc-pr-media-inner i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  left: 50%;
  bottom: -15px;
  background-color: #59b153;
  display: block;
  transform: translateX(-50%);
}

.sf-doc-pro-card-name {
  text-align: center;
  color: #fff;
  margin-bottom: 50px;
}

.sf-doc-pro-card-name h3 {
  color: #fff;
}

.sf-doc-pro-card-info ul li {
  margin-bottom: 10px;
  position: relative;
  color: #fff;
  padding-left: 50px;
  margin-bottom: 20px;
}

.sf-doc-pro-card-info ul li:last-child {
  margin-bottom: 0px;
}

.sf-doc-pro-card-info ul li h4 {
  color: #fff;
  margin-bottom: 0px;
}

.sf-doc-pro-card-info ul li p {
  margin-bottom: 0px;
}

.sf-doc-pro-card-info ul li img {
  color: #fff;
  position: absolute;
  left: 0px;
  top: 3px;
  width: 32px;
}


/*timetable*/

.sf-doc-timeing-card .nav-link span {
  font-size: 14px;
  display: block;
  color: #9f9f9f;
}

@media only screen and (max-width:1199px) {
  .sf-doc-timeing-card {
    padding: 20px;
  }

  .sf-doc-Photo-wrap {
    padding: 20px;
  }

  .sf-doc-find-wrap {
    padding: 20px;
  }

  .sf-doc-video-wrap {
    padding: 20px;
  }
}

.sf-doc-timeing-card-nav {
  margin-bottom: 0px !important;
}

@media only screen and (max-width:640px) {
  .aon-custom-nav.sf-doc-timeing-card-nav {
    display: flex;
  }
}

@media only screen and (max-width:420px) {
  .sf-doc-timeing-card {
    padding: 20px;
  }
}

.sf-doc-timeing-card-nav .nav-item {
  width: 33.333%;
  display: flex;
}

.sf-doc-timeing-card-nav .nav-item>.nav-link {
  padding: 15px 10px;
}

.sf-doc-timeing-card-nav>.nav-item>.nav-link.active::after {
  background-color: #2fc0a1;
}

@media only screen and (max-width:420px) {
  .aon-custom-nav.sf-doc-timeing-card-nav {
    display: block;
  }

  .sf-doc-timeing-card-nav .nav-item {
    width: 100%;
    display: block;
  }

  .sf-doc-timeing-card-nav .nav-item .nav-link {
    width: 100%;
    display: block;
    background-color: #fff;
    margin-top: 10px;
  }
}

.sf-doc-timing-slots .sf-doc-timing-slots-detail {
  background-color: #e2f7f2;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;
  color: #2fc0a1;
}

.sf-doc-timing-slots .sf-doc-timing-slots-detail.active {
  background-color: #fbe1e2;
  color: #b5405b;
}

.sf-doc-timing-slots .sf-doc-timing-slots-detail span {
  display: block;
  font-weight: 600;
  font-size: 16px;
}

.sf-doc-timing-slots .sf-doc-timing-slots-detail p {
  margin-bottom: 0px;
  font-size: 15px;
}

.sf-doc-timing-slots-btn {
  border-top: 1px solid #ddd;
  padding-top: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

.site-button.red {
  background-color: #ef1b1a;
}


.aon-doctorAddto-share {
  list-style: none;
  margin-top: 10px;
}

.aon-doctorAddto-share li {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 5px;
}

.aon-doctorAddto-share li a {
  display: block;
  border: 1px solid #ddd;
  padding: 2px 12px;
  border-radius: 5px;
}

/*exp-tabs*/
.sf-doc-exp-list-detail {
  list-style: none;
  margin-bottom: 0px;
}

.sf-doc-exp-list-detail li {
  padding-bottom: 15px;
  border-bottom: 1px solid #dedfe1;
  margin-bottom: 15px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width:767px) {
  .sf-doc-exp-list-detail li {
    display: block;
  }
}

.sf-doc-exp-list-detail li:last-child {
  border-bottom: 0px;
  margin: 0px;
  padding: 0px;
}

.sf-doc-exp-list-detail li .list-title {
  color: #2a2a2a;
  margin-right: 10px;
  font-weight: 600;
}

.sf-doc-full-detail.bdr-none {
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.sf-doc-full-detail-head {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width:575px) {
  .sf-doc-full-detail-head {
    display: block;
    margin-bottom: 20px;
  }
}

.sf-doc-full-detail-head .sf-doc-info-wrap {
  margin-bottom: 20px;
}

.sf-doc-full-detail-head .sf-doc-info-wrap .sf-doct-info {
  font-size: 16px;
  color: #2a2a2a;
  margin-bottom: 5px;
}

.sf-doc-full-detail-head .sf-doc-info-wrap .sf-medi-rate {
  margin-bottom: 5px;
}

.sf-doc-full-detail-head .sf-doc-info-wrap .sf-medi-rate .sf-rating-lable {
  color: #7b7b7b;
}

.sf-doc-full-detail-head .sf-doc-info-wrap .sf-doc-exp {
  font-size: 16px;
}

.sf-doc-full-detail-head .sf-doc-info-wrap .sf-doc-exp span {
  color: #2a2a2a;
}


.sf-doc-position span {
  background-color: #e3f4fa;
  color: #3e8198;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}


.sf-doc-price-list-head {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  color: #434343;
  padding-bottom: 10px;
  margin-top: 20px;
}

.sf-doc-price-list {
  margin-bottom: 0px;
  list-style: none;
  border: 1px solid #ebebeb;
}

.sf-doc-price-list li {
  padding: 15px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  color: #434343;
  font-size: 16px;
  font-weight: 600;
}

.sf-doc-price-list li:nth-child(even) {
  background-color: #f5faf9;
}

.sf-doc-price-list li:last-child {
  border-bottom: 0px;
}

/*Patient Exp*/

.sf-patient-exp h3 {
  margin-bottom: 10px;
}

.sf-patient-exp-list ul {
  list-style: none;
  margin-bottom: 0px;
}

.sf-patient-exp-list ul li {
  margin-bottom: 30px;
}

.sf-patient-exp-list ul li:last-child {
  margin-bottom: 0px;
}

.sf-patient-exp-body {
  padding: 0px;
  margin-left: 100px;
  border: 0px;
  position: relative;
}

.sf-patient-exp-body .sf-medi-rate .sf-rating-lable {
  color: #7b7b7b;
}

.sf-patient-exp-body img {
  position: absolute;
  left: -100px;
  top: 0px;
  width: 82px;
  height: 82px;
  border-radius: 50%;
}

.sf-patient-exp-head {
  display: flex;
  justify-content: space-between;
}

.sf-patient-exp-head .sf-pat-left {
  display: flex;
  justify-content: space-between;
}

.sf-patient-exp-head .sf-pat-left .sf-patient-exp-name {
  padding-right: 20px;
}

.sf-patient-exp-name {
  font-size: 18px;
  color: #2a2a2a;
}

@media only screen and (max-width:575px) {
  .sf-patient-exp-head .sf-pat-left {
    display: block;
  }

  .sf-patient-exp-head {
    display: block;
  }
}

@media only screen and (max-width:420px) {
  .sf-patient-exp-body {
    margin-left: 0px;
  }

  .sf-patient-exp-body img {
    position: inherit;
    left: auto;
  }
}

.sf-doc-contacts {
  list-style: none;
  margin-bottom: 0px;
}

.sf-doc-contacts li {
  margin-bottom: 5px;
}

.sf-doc-contacts li:last-child {
  margin-bottom: 0px;
}

.sf-doc-contacts li span {
  font-size: 18px;
  color: #2a2a2a;
  display: block;
}

.sf-doc-contacts li p {
  margin-bottom: 0px;
}

@media only screen and (max-width:991px) {
  .sf-doc-location-map {
    margin-bottom: 30px;
  }
}


.aon-article-list-inner {
  padding-left: 128px;
  border: 0px;
  position: relative;
}

.aon-article-list-inner .aon-article-pic img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 108px;
  border-radius: 10px;
}

.aon-article-list {
  list-style: none;
  margin: 0px;
}

.aon-article-list li {
  margin-bottom: 20px;
}

.aon-article-list li:last-child {
  margin-bottom: 0px;
}

.aon-article-list-inner p {
  margin-bottom: 0px;
}

.aon-article-list .aon-articles-time li {
  font-size: 14px;
  color: #000;
  display: inline-block;
  padding-right: 30px;
}


@media only screen and (max-width:640px) {
  .aon-article-list-inner {
    padding-left: 128px;
    border: 0px;
    position: relative;
  }
}

@media only screen and (max-width:420px) {
  .aon-article-list-inner {
    padding-left: 0px;
  }

  .aon-article-pic {
    margin-bottom: 10px;
  }

  .aon-article-list-inner .aon-article-pic img {
    position: inherit;
  }
}

.aon-pro-benner-area {
  background: #1b605b;
  margin-bottom: -80px;
}

.aon-pro-banner-content {
  padding: 100px 0px;
}

.aon-doc-pro-location {
  color: #fff;
  margin-bottom: 20px;
}

.aon-doc-pro-conInfo {
  color: #fff;
  display: table;
  margin: auto;
}

.aon-doc-pro-conInfo span {
  display: table-cell;
  padding: 0px 20px;
  line-height: 1.2;
}

.aon-doc-pro-conInfo span+span {
  position: relative;
}

.aon-doc-pro-conInfo span+span a {
  color: #fff;
}

.aon-doc-pro-conInfo span+span:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 1px;
  background: #fff;
  opacity: 0.2;
}

@media only screen and (max-width:420px) {
  .aon-doc-pro-conInfo {
    display: block;
  }

  .aon-doc-pro-conInfo span {
    display: block;
    margin: 20px 0px;
  }
}

.aon-doc-pro-card-name {
  text-align: center;
  color: #fff;
}

.aon-doc-pro-card-name h3 {
  color: #fff;
}

.aon-siderbar-nav.m-a0 {
  margin: 0px;
}

.aon-photo-gallery {
  background-image: url("../images/gallery.jpg");
  height: 250px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
}

.aon-photo-num {
  width: 100%;
  font-size: 40px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.aon-photo-num:hover {
  color: #fff;
}

.aon-video-gallery {
  background-image: url("../images/video.jpg");
  height: 250px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
}

.aon-video-num {
  width: 100%;
  font-size: 40px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.aon-video-num:hover {
  color: #fff;
}









/*Home page One CSS*/
.sf-ques-ans-list ul {
  list-style: none;
  margin-bottom: 0px;
}

.sf-ques-ans-list ul>li {
  margin-bottom: 30px;
}

.sf-ques-ans-list ul>li:last-child {
  margin-bottom: 0px;
}

.sf-ques-ans-body {
  padding: 0px;
  padding-left: 80px;
  border: 0px;
  position: relative;
}

.sf-ques-ans-body .sf-pat-left {
  position: absolute;
  left: 0px;
  top: 0px;
}

.sf-ques-ans-body .sf-pat-left img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.sf-ques-ans-body .sf-pat-left .sf-ques-ans-view {
  display: block;
  font-size: 14px;
  color: #000;
}

.sf-ques-ans-head {
  display: flex;
  justify-content: space-between;
}

.sf-ques-ans-head .sf-ques-ans-name {
  padding-right: 20px;
  font-size: 18px;
  color: #2a2a2a;
}

.sf-ques-ans-date {
  text-transform: uppercase;
  color: #000;
  font-size: 13px;
}

.sf-ques-ans-list ul li .children {
  border-top: 1px solid #ddd;
  list-style: none;
  margin-left: 80px;
  margin-top: 10px;
  padding-top: 10px;
}

.sf-ques-ans-list ul li .children .sf-ques-ans-bottom {
  display: flex;
  justify-content: space-between;
}

.sf-ques-ans-list ul li .children .sf-ques-ans-bottom .sf-view-ans {
  font-size: 16px;
  color: #06328b;
  min-width: 110px;
  text-align: right;
}


.aon-banner-wrap {
  background-color: #2c6c5e;
  padding-top: 100px;
}

.aon-banner-row {
  position: relative;
}

.aon-banner-right {
  color: #fff;
}

.aon-banner-pic {
  position: relative;
  z-index: 1;
}

.aon-banner-bg {
  position: absolute;
  top: 20%;
  left: 0px;
  background-color: #ff8a00;
  width: calc(50% - 30px);
  height: 60%;
  font-family: arial;
  font-weight: bold;
}

.aon-banner-bg span {
  color: #fff;
  font-size: 180px;
  opacity: 0.2;
  display: table;
  line-height: 1;
}

.aon-banner-bg .star-one {
  position: absolute;
  top: -95px;
  right: -65px;
  color: #ff8a00;
  font-size: 150px;
  opacity: 1;
}

.aon-banner-bg .star-two {
  position: absolute;
  top: -50px;
  left: -40px;
  color: #fff;
  font-size: 350px;
  opacity: 0.2;
}

.aon-banner-bg .star-three {
  position: absolute;
  top: 50px;
  left: 30%;
  color: #fff;
  font-size: 150px;
  opacity: 0.2;
}

.aon-banner-bg .star-four {
  position: absolute;
  bottom: -80px;
  left: 10%;
  color: #fff;
  font-size: 450px;
  opacity: 0.2;
}

.animte-pluse {
  animation: MoveUpDown 3s linear infinite;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media only screen and (max-width:991px) {
  .aon-banner-row .container {
    max-width: 100%;
  }
}

@keyframes MoveUpDown {

  0%,
  100% {
    bottom: 0;
  }

  50% {
    bottom: -20px;
  }
}

.animte-pluse2 {
  animation: MoveUpDown2 6s linear infinite;
  position: absolute;
  left: 0;
  bottom: 0;
}

@keyframes MoveUpDown2 {

  0%,
  100% {
    top: 0;
  }

  50% {
    top: -20px;
  }
}

.aon-banner-heading {
  font-size: 65px;
  color: #fff;
  margin-bottom: 10px;
}

.aon-banner-heading span {
  color: #ff8a00;
}

.aon-banner-text {
  font-size: 18px;
  margin-bottom: 40px;
}

.aon-banner-bot-icon {
  width: 40px;
  margin-right: 15px;
}

.aon-banner-bot-text>strong {
  display: block;
  line-height: 1;
}

.aon-banner-bot-text>span {
  font-size: 14px;
  opacity: 0.8;
}

.aon-search1-bar-wrap {
  position: relative;
  z-index: 1;
}

.aon-search1-bar-wrap .container {
  position: relative;
}

.aon-search1-area {
  position: relative;
}

.aon-search1-row {
  position: absolute;
  width: 100%;
  top: -60px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.aon-search1-form {
  padding: 30px;
  width: 100%;
  display: table;
}

.aon-search1-left,
.aon-search1-right {
  display: table-cell;
  vertical-align: bottom;
}

.aon-search1-right {
  width: 1%;
}

.aon-search1-table {
  display: table;
  width: 100%;
}

.aon-search1-table .aon-search1-col {
  display: table-cell;
  padding: 0px 15px;
}

@media only screen and (max-width:1200px) {
  .aon-search1-form {
    display: block;
  }

  .aon-search1-left,
  .aon-search1-right {
    display: block;
  }

  .aon-search1-right {
    width: auto;
    padding-top: 25px;
  }

  .aon-search1-right .aon-search1-btn {
    width: 100%;
  }

  .aon-banner-heading {
    font-size: 50px;
  }

  .aon-banner-text {
    font-size: 16px;
  }
}

@media only screen and (max-width:1199px) {
  .aon-banner-bg .star-one {
    top: -112px;
    right: -34px;
  }
}

@media only screen and (max-width:991px) {
  .aon-search1-bar-wrap {
    padding: 40px 0px 30px;
  }

  .aon-search1-table {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .aon-search1-table .aon-search1-col {
    display: block;
    padding: 0px 15px;
    width: 50%;
    margin-bottom: 25px;
  }

  .aon-banner-colLeft {
    position: relative;
  }

  .aon-banner-pic {
    text-align: center;
  }

  .aon-banner-bg {
    position: absolute;
    top: auto;
    left: 0px;
    background-color: #ff8a00;
    width: calc(80% - 0px);
    height: calc(100% - 150px);
    font-family: arial;
    font-weight: bold;
    bottom: 0;
  }

  .aon-banner-right {
    padding: 30px 0px 6px 0px;
  }

  .aon-search1-row {
    position: static;
  }

  .aon-banner-heading {
    font-size: 30px;
  }
}

@media only screen and (max-width:575px) {
  .aon-search1-table .aon-search1-col {
    width: 100%;
  }
}


.aon-inputicon-bg .aon-input-icon {
  width: 44px;
  height: 44px;
  line-height: 44px;
  color: #fff;
  top: 5px;
  border-right: none;
  border-radius: 30px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#478165+0,8ab378+100 */
  background: #478165;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #478165 0%, #8ab378 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #478165 0%, #8ab378 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #478165 0%, #8ab378 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#478165', endColorstr='#8ab378', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.aon-inputicon-bg .aon-input-icon:after {
  display: none;
}

.aon-inputicon-bg {
  padding-left: 55px;
}

.aon-inputicon-bg label {
  line-height: 1;
  margin: 0px 0px 0px 3px;
  color: #000;
  font-size: 18px;
}

.aon-inputicon-bg .form-select {
  padding: 0px 0px;
  border: none;
  border-bottom: 1px solid #ddd;
  height: 32px;
  border-radius: 0px;
}

.aon-search1-tabs {
  margin: -20px 0px 0px 40px;
  list-style: none;
}

.aon-search1-tabs li {
  margin-right: 20px;
}

.aon-search1-tabs li a {
  background: #eaf0f0;
  color: #2c6c5e;
  padding: 8px 25px;
  font-weight: bold;
  border-radius: 30px;
  display: block;
}

.aon-search1-tabs li a:hover {
  background: #2c6c5e;
  color: #fff;
}

.aon-search1-tabs li a.active {
  background: #2c6c5e;
  color: #fff;
}

@media only screen and (max-width:1200px) {
  .aon-search1-row {
    position: inherit;
    top: auto;
  }

  .aon-search1-bar-wrap {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .aon-search1-tabs {
    margin-top: 0px;
    position: relative;
    top: -20px;
  }
}

@media only screen and (max-width:991px) {
  .aon-search1-tabs {
    margin: 0px 0px 0px 40px;
  }

  .aon-search1-bar-wrap {
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width:480px) {
  .aon-search1-tabs {
    display: block !important;
  }

  .aon-search1-tabs li {
    margin-bottom: 3px;
  }
}


.aon-search1-btn {
  padding: 10px 50px;
}

.aon-search-advance-btn {
  background: none;
  border: none;
  color: #fff;
  float: right;
}

.aon-search-advance-btn i {
  font-size: 28px;
  margin-right: 10px;
}

.aon-search-advance-btn strong {
  display: block;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
}

.aon-search-advance-btn span {
  display: block;
  font-size: 16px;
  line-height: 1;
}

.aon-search-advance-btn span:last-child {
  letter-spacing: 1px;
  letter-spacing: 4.5px;
}



.sf-ques-ans-list ul {
  list-style: none;
  margin-bottom: 0px;
}

.sf-ques-ans-list ul>li {
  margin-bottom: 30px;
}

.sf-ques-ans-list ul>li:last-child {
  margin-bottom: 0px;
}

.sf-ques-ans-body {
  padding: 0px;
  padding-left: 80px;
  border: 0px;
  position: relative;
}

.sf-ques-ans-body .sf-pat-left {
  position: absolute;
  left: 0px;
  top: 0px;
}

.sf-ques-ans-body .sf-pat-left img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.sf-ques-ans-body .sf-pat-left .sf-ques-ans-view {
  display: block;
  font-size: 14px;
  color: #000;
}

.sf-ques-ans-head {
  display: flex;
  justify-content: space-between;
}

.sf-ques-ans-head .sf-ques-ans-name {
  padding-right: 20px;
  font-size: 18px;
  color: #2a2a2a;
}

.sf-ques-ans-date {
  text-transform: uppercase;
  color: #000;
  font-size: 13px;
}

.sf-ques-ans-list ul li .children {
  border-top: 1px solid #ddd;
  list-style: none;
  margin-left: 80px;
  margin-top: 10px;
  padding-top: 10px;
}

.sf-ques-ans-list ul li .children .sf-ques-ans-bottom {
  display: flex;
  justify-content: space-between;
}

.sf-ques-ans-list ul li .children .sf-ques-ans-bottom .sf-view-ans {
  font-size: 16px;
  color: #06328b;
  min-width: 110px;
  text-align: right;
}

@media only screen and (max-width:575px) {
  .sf-ques-ans-head {
    display: block;
  }

  .sf-ques-ans-head .sf-ques-ans-name {
    display: block;
  }

  .sf-ques-ans-list ul li .children .sf-ques-ans-bottom {
    display: block;
  }

  .sf-ques-ans-list ul li .children {
    margin-left: 0px;
  }
}

@media only screen and (max-width:420px) {
  .sf-ques-ans-body .sf-pat-left {
    position: inherit;
    left: auto;
    top: auto;
  }

  .sf-ques-ans-body {
    padding-left: 0px;
  }
}

.section-head.center {
  text-align: center;
  margin: 0px auto 60px;
  max-width: 650px;
}

@media only screen and (max-width:991px) {
  .section-head.center .aon-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:420px) {
  .section-head.center .aon-title {
    font-size: 26px;
  }
}

/*Team*/
.bg-light-gray {
  background-color: #f6fbf8;
}

.aon-df-rating span {
  display: inline-block;
  padding-right: 2px;
  color: #ff8a00;
  font-size: 16px;
}

.aon-df-rating span.aon-df-lable {
  color: #000;
}

.aon-med-team-area {
  padding: 90px 0px;
  align-items: flex-start;
}

.aon-med-team-area.aon-med-team-area1 {
  padding-top: 160px;
}

@media only screen and (max-width:1200px) {
  .aon-med-team-area.aon-med-team-area1 {
    padding-top: 90px;
  }
}

@media only screen and (max-width:991px) {
  .aon-med-team-area.aon-med-team-area1 {
    padding: 30px 0px;
  }

  .aon-med-team-area {
    padding: 30px 0px;
  }
}

.aon-med-team {
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.aon-med-team .aon-med-team-pic {
  width: 180px;
  margin-right: 20px;
  position: relative;
}

.aon-med-team .aon-med-team-discription {
  margin-bottom: 5px;
}

.aon-med-team .aon-med-team-position {
  display: block;
  color: #ff8a00;
  font-size: 16px;
  margin-bottom: 5px;
}

.aon-med-team .aon-med-team-location {
  margin-bottom: 5px;
  display: block;
}

.aon-med-team .aon-med-team-location i {
  margin-right: 5px;
  color: #000;
}

.aon-med-team:hover .aon-med-team-pic {
  border-radius: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.aon-med-team:hover .aon-med-team-pic img {
  border-radius: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.aon-med-team .aon-med-team-pic:after {
  opacity: 0;
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  transform: rotate(10deg);
  border-radius: 50%;
  padding: 27px;
  border-left: 3px solid #ff8a00;
  border-right: 3px solid #ff8a00;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.aon-med-team:hover .aon-med-team-pic:after {
  opacity: 1;
}

.aon-med-team:hover .aon-med-team-pic:after {
  animation: rotate 1.5s infinite linear;
}

.aon-med-team:hover {
  box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

@media only screen and (max-width:1200px) {
  .aon-med-team .aon-med-team-pic:after {
    left: -2px;
    top: -2px;
  }
}

@media only screen and (max-width:991px) {
  .aon-med-team {
    display: block;
    padding: 20px;
  }

  .aon-med-team .aon-med-team-pic {
    width: 131px;
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width:767px) {
  .aon-med-team {
    display: flex;
    padding: 20px;
  }

  .aon-med-team .aon-med-team-pic {
    width: 131px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width:575px) {
  .aon-med-team .aon-med-team-pic {
    width: 160px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width:480px) {
  .aon-med-team {
    display: block;
    padding: 20px;
    text-align: center;
  }

  .aon-med-team .aon-med-team-pic {
    width: 160px;
    margin: 0px auto 30px;
  }

  .aon-med-team .aon-med-team-pic:after {
    left: -2px;
    top: -2px;
    width: 165px;
    height: 165px;
  }
}



.aon-addmore-btn-section {
  text-align: center;
}

.aon-addmore-btn-section .aon-addplus {
  border: 1px solid #ff8a00;
  border-radius: 6px;
  background-color: #fff;
  width: 46px;
  height: 40px;
  display: inline-block;
  position: relative;
}

.aon-addmore-btn-section .aon-addplus i {
  color: #ff8a00;
  font-size: 20px;
  line-height: 40px;
  display: inline-block;
}

.aon-addmore-btn-section .aon-addplus:before,
.aon-addmore-btn-section .aon-addplus:after {
  content: '';
  position: absolute;
  width: 80px;
  height: 1px;
  background-color: #ff8a00;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.aon-addmore-btn-section .aon-addplus:before {
  right: 100%;
  margin-right: 10px;
}

.aon-addmore-btn-section .aon-addplus:after {
  left: 100%;
  margin-left: 10px;
}

.aon-addmore-btn-section .aon-addplus:hover i {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.aon-addmore-btn-section .aon-addplus:hover:before {
  width: 50px;
}

.aon-addmore-btn-section .aon-addplus:hover:after {
  width: 50px;
}




/*Service categories*/
.aon-med-srv-cat-area {
  padding: 90px 0px;
}

@media only screen and (max-width:991px) {
  .aon-med-srv-cat-area {
    padding: 30px 0px;
  }
}

.aon-med-srv-cat-section {
  margin-bottom: 30px;
}

.aon-med-srv-cat-section ul {
  margin-bottom: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

.aon-med-srv-cat-section ul li {
  width: 20%;
  border-left: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.aon-med-sevices-cat {
  padding: 50px 30px;
  text-align: center;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.aon-med-sevices-cat p {
  margin-bottom: 0px;
}

.aon-med-sevices-cat .media {
  height: 120px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.aon-med-sevices-cat .media img {
  height: 64px;
}

.aon-med-sevices-cat .media:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.aon-med-serices-cat-info a {
  color: #2f6759;
}

.aon-med-sevices-cat:hover {
  background-color: #ff8a00;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.1);
}

.aon-med-sevices-cat:hover .aon-med-serices-cat-info a,
.aon-med-sevices-cat:hover .aon-med-serices-cat-info p {
  color: #fff;
}

.aon-med-sevices-cat:hover .media:after {
  opacity: 1;
}





@media only screen and (max-width:1200px) {
  .aon-med-srv-cat-section ul li {
    width: 25%;
  }

  .aon-med-srv-cat-section ul li:last-child {
    border-right: 1px solid #ebebeb;
  }
}

@media only screen and (max-width:991px) {
  .aon-med-srv-cat-section ul li {
    width: 33.333%;
  }
}

@media only screen and (max-width:767px) {
  .aon-med-srv-cat-section ul li {
    width: 50%;
  }
}

@media only screen and (max-width:575px) {
  .aon-med-sevices-cat {
    padding: 20px 10px;
  }

  .aon-med-sevices-cat .media {
    height: 105px;
  }
}





/*Working*/
.section-head.center.white {
  color: #fff;
}

.section-head.center.white .aon-title {
  color: #fff;
}

.aon-med-working-area {
  border-top: 1px solid #ebebeb;
  padding: 90px 0px;
}

@media only screen and (max-width:991px) {
  .aon-med-working-area {
    padding: 30px 0px 0px 0px;
  }
}

.aon-med-working {
  padding: 0px 60px;
  text-align: center;
}

.aon-med-working .media span {
  width: 170px;
  height: 160px;
  background-color: #fff;
  margin: 0px auto 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #ff8a00;
  border-radius: 6px;
  overflow: hidden;
}

@media only screen and (max-width:575px) {
  .aon-med-working .media span img {
    width: 50px;
  }
}

@media only screen and (max-width:420px) {
  .aon-med-working .media span {
    width: 100px;
    height: 90px;
  }

  .aon-med-working .media span img {
    width: 50px;
  }
}

.aon-med-working-info h4 {
  color: #2f6759;
  margin-bottom: 10px;
}

@media only screen and (max-width:1280px) {
  .aon-med-working {
    padding: 0px 30px;
  }
}

@media only screen and (max-width:991px) {
  .aon-med-working {
    margin-bottom: 30px;
  }
}



.aon-med-appoint-area {
  padding: 90px 0px;
  background-image: url(../images/background/bg2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width:991px) {
  .aon-med-appoint-area {
    padding: 30px 0px;
  }
}

.aon-med-appoint-area .section-head {
  margin-bottom: 0px;
}

.aon-med-appoint-area .section-head.center.white .aon-title {
  margin-bottom: 30px;
}

.aon-med-appoint-area .section-head.center.white p {
  margin-bottom: 30px;
}


.aon-med-appoint-area .container {
  position: relative;
}

.aon-med-appoint-area .media img {
  position: absolute;
  left: calc(100% - 20%);
  bottom: -140px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media only screen and (max-width:1800px) {
  .aon-med-appoint-area .media img {
    left: calc(100% - 30%);
    max-width: 450px;
  }
}

@media only screen and (max-width:1600px) {
  .aon-med-appoint-area .media img {
    left: calc(100% - 30%);
    max-width: 400px;
  }
}

@media only screen and (max-width:1280px) {
  .aon-med-appoint-area .media img {
    display: none;
  }
}

.aon-med-help-area {
  padding: 90px 0px;
}

@media only screen and (max-width:991px) {
  .aon-med-help-area {
    padding: 30px 0px;
  }
}

.aon-med-help-section .row {
  align-items: center;
}

.aon-med-help-media {
  margin-right: 30px;
}

.aon-med-help-media .media {
  padding: 40px;
  position: relative;
  z-index: 1;
}

.aon-med-help-media .media.curve-left:after {
  content: '';
  left: 0px;
  bottom: 0px;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 400px 0 0 400px;
  border-color: transparent transparent transparent #ff8a00;
}

.aon-med-help-media .media.curve-right:after {
  content: '';
  right: 0px;
  bottom: 0px;
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 400px 400px;
  border-color: transparent transparent #ff8a00 transparent;
}

@media only screen and (max-width:991px) {
  .aon-med-help-media .media.curve-right:after {
    right: -20px;
    bottom: -20px;
    border-width: 0 0 360px 360px;
  }

  .aon-med-help-media .media.curve-left:after {
    left: -20px;
    bottom: -20px;
    border-style: solid;
    border-width: 360px 0 0 360px;
  }
}

@media only screen and (max-width:767px) {
  .aon-med-help-media .media.curve-right:after {
    display: none;
  }

  .aon-med-help-media .media.curve-left:after {
    display: none;
  }
}

.aon-med-help-media .media img {
  border-radius: 10px;
  width: 100%;
}

.aone-find-doc {
  position: relative;
}

.aone-find-doc input {
  padding-right: 70px;
}

.aone-find-doc button {
  position: absolute;
  right: 6px;
  top: 6px;
  background-color: #ff8a00;
  border: 0px;
  color: #fff;
  bottom: 6px;
  width: 45px;
  border-radius: 6px;
}

.aon-med-help-media-info .aon-title {
  color: #115949;
  margin-bottom: 30px;
}

.aon-med-help-media-info .aon-title span {
  color: #ff8a00;
}

.aon-med-help-media-info p {
  margin-bottom: 30px;
}


@media only screen and (max-width:1280px) {
  .aon-med-help-media {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width:991px) {
  .aon-med-help-media {
    margin-right: 0px;
  }

  .aon-med-help-media .media {
    padding: 0px;
  }

  .aon-med-help-media-info .aon-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .aon-med-help-media-info {
    margin-bottom: 30px;
  }

  .aon-med-help-media {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width:420px) {
  .aon-med-help-media-info .aon-title {
    font-size: 26px;
  }
}

/*statics*/
.aon-med-statics-area {
  position: relative;
  z-index: 1;
}

.aon-med-statics-area:before {
  position: absolute;
  background-color: #f6fbf8;
  content: '';
  left: 0px;
  top: 0px;
  height: 50%;
  width: 100%;
  z-index: -1;
}

.aon-med-static-section {
  padding: 50px 30px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .1);
  border-top: 2px solid #ff8a00;
  border-radius: 10px;
  text-align: center;
}

@media only screen and (max-width:1280px) {
  .aon-med-static-section {
    margin-bottom: 30px;
  }
}

.aon-med-static-section .media {
  height: 70px;
}

.aon-med-static-section .counter-area {
  font-size: 40px;
  color: #ff8a00;
}

.aon-med-static-section .aon-med-static-name {
  font-size: 20px;
  color: #115949;
}


.aon-med-faq-area {
  padding: 90px 0px;
}

@media only screen and (max-width:991px) {
  .aon-med-faq-area {
    padding: 30px 0px;
  }

  .aon-med-statics-area:before {
    display: none;
  }

  .aon-med-statics-area {
    margin-top: 40px;
  }
}

.aon-med-faq-section {
  max-width: 780px;
  margin: 0px auto;
}

.aon-med-faq .accordion-item {
  border: 1px solid #115949;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.aon-med-faq .accordion-item .accordion-button {
  font-size: 20px;
  color: #115949;
  padding: 8px 16px 8px 16px;
}

@media only screen and (max-width:767px) {
  .aon-med-faq .accordion-item .accordion-button {
    font-size: 16px;
  }
}

.aon-med-faq .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button:after {
  background-image: none;
  content: "\f067";
  font-family: 'FontAwesome';
  line-height: 20px;
}

.accordion-button:not(.collapsed):after {
  background-image: none;
  content: "\f068";
  transform: none;
}

.list-check-style {
  list-style: none;
  margin: 30px 0px;
}

.list-check-style li {
  padding-left: 30px;
  position: relative;
  padding-bottom: 5px;
}

.list-check-style li i {
  position: absolute;
  left: 0px;
  top: 4px;
  color: #115949;
  font-size: 22px;
}

/*Latest Blog*/
.aon-med-blog-area {
  padding: 90px 0px;
  border-top: 1px solid #ebebeb;
}

@media only screen and (max-width:991px) {
  .aon-med-blog-area {
    padding: 30px 0px;
  }
}

.aon-med-blog-style-1 .post-bx {
  margin-bottom: 30px;
}

.aon-med-blog-style-1 .post-bx .post-thum {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.aon-med-blog-style-1 .post-bx .post-thum img {
  width: 100%;
}

.aon-med-blog-style-1 .post-info {
  border: 1px solid #ebebeb;
  border-radius: 0px 0px 10px 10px;
  position: relative;
}

.aon-med-blog-style-1 .post-info .post-categories a:last-child {
  margin-right: 0px;
}

.aon-med-blog-style-1 .post-meta {
  padding: 10px 20px;
  border-bottom: 1px solid #ebebeb;
}

.aon-med-blog-style-1 .post-meta ul {
  margin-bottom: 0px;
}

.aon-med-blog-style-1 .post-meta ul li {
  color: #595959;
  position: relative;
  padding-right: 10px;
  display: inline-block;
}

.aon-med-blog-style-1 .post-meta ul li i {
  color: #2f6759;
  font-size: 20px;
}

.aon-med-blog-style-1 .post-meta ul li a {
  color: #595959;
}

.aon-med-blog-style-1 .post-title {
  font-size: 20px;
  margin-bottom: 15px;
}

.aon-med-blog-style-1 .post-title a {
  color: #115949;
}

.aon-med-blog-style-1 .post-text {
  padding: 20px;
}

.aon-med-blog-style-1 .post-text .site-btn-link {
  color: #ff8a00;
  font-weight: 600;
  display: table;
}

.aon-med-blog-style-1 .post-text .site-btn-link:after {
  content: "";
  width: 0%;
  height: 2px;
  background: #ff8a00;
  display: table;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.aon-med-blog-style-1:hover .post-text .site-btn-link:after {
  width: 100%;
}




.aon-med-blog-style-1 .post-date {
  position: absolute;
  right: 30px;
  background-color: #2f6759;
  padding: 15px 0px;
  width: 86px;
  text-align: center;
  top: -90px;
}

@media only screen and (max-width:1199px) {
  .aon-med-blog-style-1 .post-date {
    right: 20px;
    top: -135px;
  }
}

.aon-med-blog-style-1 .post-date span {
  display: block;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 20px;
}

.aon-med-blog-style-1 .post-date span.date-dd {
  font-size: 48px;
  font-weight: 600;
  color: #ff8a00;
  line-height: 0.7;
  display: inline-table;
}


/*Testimonial*/
.aon-med-testimonial-area {
  padding: 90px 0px;
}

.aon-testimonial-contaent-wrap {
  position: relative;
}

@media only screen and (max-width:991px) {
  .aon-med-testimonial-area {
    padding: 30px 0px;
  }

  .aon-med-testimonial-area .aon-title {
    font-size: 30px;
    margin-bottom: 30px !important;
  }
}

@media only screen and (max-width:767px) {
  .aon-med-testimonial-area .aon-title {
    font-size: 26px;
  }
}

/* testimonial-left circle */
.aon-testimonial-left {
  background-color: #fff;
  width: 284px;
  height: 440px;
  top: 0px;
  left: 0px;
  position: absolute;
  background-image: url(../images/testimonial-img/left-lines.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.aon-testimonial-left span {
  border-radius: 50%;
  background: #ff8a00;
  display: inline-block;
}

.aon-testimonial-left span img {
  border-radius: 50%;
}

.aon-testimonial-left span.circle-pic-l-3 {
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  z-index: 1;
}

.aon-testimonial-left span.circle-pic-l-3 img {
  padding: 2px;
  width: 54px;
  height: 54px;
}

.aon-testimonial-left span.circle-pic-l-3:after {
  content: '';
  width: 30px;
  height: 30px;
  background-color: #ff8a00;
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-radius: 50%;
  z-index: -1
}

.aon-testimonial-left span.circle-pic-l-1 {
  position: absolute;
  top: 70px;
  left: 70px;
  z-index: 1
}

.aon-testimonial-left span.circle-pic-l-1 img {
  padding: 3px;
  width: 80px;
  height: 80px;
}

.aon-testimonial-left span.circle-pic-l-1:after {
  content: '';
  width: 34px;
  height: 34px;
  background-color: #ff8a00;
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-radius: 50%;
  z-index: -1
}

.aon-testimonial-left span.circle-pic-l-2 {
  position: absolute;
  bottom: 100px;
  left: 80px;
  z-index: 1
}

.aon-testimonial-left span.circle-pic-l-2 img {
  padding: 3px;
  width: 68px;
  height: 68px;
}

.aon-testimonial-left span.circle-pic-l-2:after {
  content: '';
  width: 32px;
  height: 32px;
  background-color: #ff8a00;
  position: absolute;
  left: 0px;
  bottom: 0px;
  border-radius: 50%;
  z-index: -1
}

.aon-testimonial-left span.circle-pic-l-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 140px;
  width: 34px;
  height: 34px;
  background-color: #ff8a00;
}

/* testimonial-left circle End */


/* testimonial-right circle start */
.aon-testimonial-right {
  background-color: #fff;
  width: 284px;
  height: 440px;
  top: 0px;
  right: 0px;
  position: absolute;
  background-image: url(../images/testimonial-img/right-lines.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.aon-testimonial-right span {
  border-radius: 50%;
  background: #ff8a00;
  display: inline-block;
}

.aon-testimonial-right span img {
  border-radius: 50%;
}

.aon-testimonial-right span.circle-pic-r-3 {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  z-index: 1
}

.aon-testimonial-right span.circle-pic-r-3 img {
  padding: 2px;
  width: 54px;
  height: 54px;
}

.aon-testimonial-right span.circle-pic-r-3:after {
  content: '';
  width: 30px;
  height: 30px;
  background-color: #ff8a00;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  z-index: -1
}

@media only screen and (max-width:1280px) {

  .aon-testimonial-left,
  .aon-testimonial-right {
    display: none;
  }
}


.aon-testimonial-right span.circle-pic-r-1 {
  position: absolute;
  top: 70px;
  right: 70px;
  z-index: 1
}

.aon-testimonial-right span.circle-pic-r-1 img {
  padding: 3px;
  width: 80px;
  height: 80px;
}

.aon-testimonial-right span.circle-pic-r-1:after {
  content: '';
  width: 34px;
  height: 34px;
  background-color: #ff8a00;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  z-index: -1
}

.aon-testimonial-right span.circle-pic-r-2 {
  position: absolute;
  bottom: 100px;
  right: 80px;
  z-index: 1
}

.aon-testimonial-right span.circle-pic-r-2 img {
  padding: 3px;
  width: 68px;
  height: 68px;
}

.aon-testimonial-right span.circle-pic-r-2:after {
  content: '';
  width: 32px;
  height: 32px;
  background-color: #ff8a00;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  z-index: -1
}

.aon-testimonial-right span.circle-pic-r-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 140px;
  width: 34px;
  height: 34px;
  background-color: #ff8a00;
}

/* testimonial-right circle End */

.aon-med-testimonial-mid {
  max-width: 780px;
  margin: 0px auto;
  padding-top: 60px;
}

@media only screen and (max-width:1280px) {
  .aon-med-testimonial-mid {
    padding-top: 0px;
  }
}

.aon-med-testimonial-1 {
  padding: 50px 80px 20px 80px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .1);
  margin: 30px;
  border-radius: 200px;
}

.aon-med-testimonial-1 .aon-testimonial-name {
  color: #115949;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.aon-med-testimonial-1 .aon-testimonial-position {
  color: #ff8a00;
  font-size: 18px;
}

.aon-med-testimonial-1 .aon-testimonial-quote {
  color: #ff8a00;
  font-size: 80px;
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
}


@media only screen and (max-width:767px) {
  .aon-med-testimonial-1 {
    padding: 50px 40px 20px;
    border-radius: 50px;
  }
}

@media only screen and (max-width:420px) {
  .aon-med-testimonial-1 {
    margin: 30px 10px 10px 10px;
    padding: 40px 20px 15px;
  }
}



/*Home page Two CSS*/
.aon-banner2-wrap {
  background-color: #2c6c5e;
  background-image: url("../images/banner2/bg.jpg");
  padding-top: 100px;
}

.aon-banner2-row {
  position: relative;
}

.aon-banner2-pic {
  position: relative;
  z-index: 1;
}

.aon-banner2-left {
  color: #fff;
  border-left: 10px solid #ff8a00;
  padding: 40px 0px 50px 40px;
  position: relative;
}

.aon-banner2-left:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50%;
  height: 10px;
  background-color: #ff8a00;
}

.aon-banner2-left:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 50%;
  height: 10px;
  background-color: #ff8a00;
}

.aon-banner2-heading {
  font-size: 62px;
  color: #fff;
  margin-bottom: 30px;
  line-height: 70px;
}

.aon-banner2-heading span {
  color: #ff8a00;
}

@media only screen and (max-width:991px) {
  .aon-banner2-left {
    margin: 90px 0px 50px;
  }

  .aon-banner2-pic {
    text-align: center;
    max-width: 400px;
    margin: 0px auto;
  }

  .aon-banner2-heading {
    font-size: 30px;
  }

  .aon-banner2-row .row .d-flex {
    display: block !important;
  }

  .aon-banner2-row {
    position: relative;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width:767px) {
  .aon-banner2-heading {
    font-size: 26px;
    line-height: 32px;
  }
}

@media only screen and (max-width:575px) {
  .aon-banner2-left {
    padding: 20px 0px 20px 10px;
  }
}

.aon-banner-search {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.aon-search3-form {
  padding: 30px;
  width: 100%;
  display: table;
}

.aon-search3-left,
.aon-search3-right {
  display: table-cell;
  vertical-align: bottom;
}

.aon-search3-right {
  width: 1%;
}

.aon-search3-table {
  display: table;
  width: 100%;
}

.aon-search3-table .aon-search1-col {
  display: table-cell;
  padding: 0px 15px;
}

.aon-inputicon2-bg .aon-input-icon {
  width: 44px;
  height: 44px;
  line-height: 44px;
  color: #fff;
  top: 7px;
  border-right: none;
  border-radius: 30px;
  border: 1px solid #fff;
}

.aon-inputicon2-bg .aon-input-icon:after {
  display: none;
}

.aon-inputicon2-bg {
  padding-left: 55px;
}

.aon-inputicon2-bg label {
  line-height: 1;
  margin: 0px 0px 0px 3px;
  color: #fff;
  font-size: 18px;
}

.aon-inputicon2-bg .form-select {
  padding: 0px 0px;
  border: none;
  border-bottom: 1px solid #ddd;
  height: 32px;
  background: none;
  border-radius: 0px;
  color: #fff;
}

.aon-inputicon2-bg .form-select option {
  color: #222;
}

.aon-search2-tabs {
  margin: 0px;
  list-style: none;
  display: table;
  width: 100%;
}

.aon-search2-tabs::after {
  background: #fff;
}

.aon-search2-tabs li {
  display: table-cell;
  border-right: 2px solid #fff;
  text-align: center;
  width: 33.333%;
}

.aon-search2-tabs li:last-child {
  border-right: none;
}

.aon-search2-tabs li .nav-link {
  color: #fff !important;
  padding: 15px 25px;
  width: 100%;
  font-weight: bold;
  border-radius: 30px;
}

.aon-search2-tabs li .nav-link.active {
  color: #ff8a00 !important;
}

.aon-search2-tabs li .nav-link.active::after {
  background-color: #fff !important;
}

.aon-search2-form-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 25px 20px;
}

@media only screen and (max-width:991px) {
  .aon-search2-form-area {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width:575px) {
  .aon-search1-table .aon-search1-col {
    width: 100%;
  }
}




.aon-med-how-work {
  position: relative;
  z-index: 1;
}

.aon-how-work-left {
  background-color: #ff8a00;
}

.aon-how-work-right {
  background-color: #f6fbf8;
  margin: -50px 0px;
  position: relative;
}

@media only screen and (max-width:991px) {
  .aon-how-work-right {
    margin: 0px;
  }

  .aon-med-how-work {
    max-width: 700px;
    margin: 0px auto;
  }
}

@media only screen and (max-width:767px) {
  .aon-med-how-work {
    max-width: 520px;
  }
}

.aon-how-work-right:before {
  content: "";
  position: absolute;
  top: 0px;
  left: -50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent transparent #dfdfdf transparent;
}

.aon-how-work-right:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: -50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent #dfdfdf transparent transparent;
}

.aon-howLeft-col {
  max-width: 635px;
  width: 100%;
  padding: 100px 30px 100px 0px;
  padding-left: 15px;
  color: #fff;
  position: relative;
}

.aon-howLeft-title {
  color: #fff;
  font-size: 60px;
}

.aon-howLeft-icon {
  position: absolute;
  top: -60px;
  left: -115px;
  font-size: 300px;
  line-height: 1;
  font-family: arial;
  font-weight: 600;
  opacity: 0.2;
}

@media only screen and (max-width:1320px) {
  .aon-howLeft-icon {
    top: -30px;
    left: 0px;
    font-size: 200px;
  }
}

.aon-howRight-col {
  max-width: 635px;
  width: 100%;
  padding: 100px 15px 100px 80px;
}

.aon-hwork-list-icon {
  min-width: 52px;
  margin-right: 30px;
}

.aon-hwork-list-text strong {
  display: block;
  line-height: 1;
}

@media only screen and (max-width:1440px) {
  .aon-howLeft-title {
    font-size: 45px;
  }
}

@media only screen and (max-width:991px) {
  .aon-howLeft-col {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .aon-howRight-col {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .aon-howRight-col .aon-title {
    font-size: 30px;
  }

  .aon-how-work-right::before,
  .aon-how-work-right::after {
    display: none;
  }

  .aon-howLeft-title {
    font-size: 30px;
  }

}

@media only screen and (max-width:420px) {
  .aon-howLeft-title {
    font-size: 26px;
  }

  .aon-howRight-col .aon-title {
    font-size: 26px;
  }

  .aon-hwork-list {
    display: block !important;
  }

  .aon-hwork-list-icon {
    margin-right: 0px;
    margin-bottom: 20px;
    max-width: 35px;
  }
}

/*Working*/
.section-head.left {
  margin-bottom: 0px;
}

.section-head.left .aon-title {
  margin-bottom: 30px;
}

.section-head.left p {
  margin-bottom: 30px;
}

@media only screen and (max-width:991px) {
  .section-head.left .aon-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:420px) {
  .section-head.left .aon-title {
    font-size: 26px;
  }
}

.aon-sub-title {
  color: #ff8a00;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 5px;
  font-weight: 600;
}

.section-head.white {
  color: #fff;
}

.section-head.white .aon-title {
  color: #fff;
}

.aon-med-appoint-area2 {
  padding: 10px 0px 0px 0px;
  background-image: url(../images/background/bg3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.aon-med-appoint-area2 .row {
  display: flex;
  align-items: center;
}

.aon-med-appoint-area2 .section-head {
  margin-bottom: 0px;
}

.aon-med-appoint-area2 .section-head.white .aon-title {
  margin-bottom: 30px;
}

.aon-med-appoint-area2 .section-head.white p {
  margin-bottom: 30px;
}

.aon-med-appoint-area2 .media img {
  width: auto;
}

.aon-med-appoint-area2-content {
  text-align: center;
}

@media only screen and (max-width:991px) {
  .aon-med-appoint-area2 {
    padding-top: 30px;
  }

  .aon-med-appoint-area2 .section-head.white .aon-title {
    font-size: 30px;
  }

  .aon-med-appoint-area2 .row {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
    text-align: center;
  }
}

@media only screen and (max-width:991px) {
  .aon-med-appoint-area2 .section-head.white .aon-title {
    font-size: 26px;
  }
}

.aon-med-future-area {
  padding: 90px 0px;
}

@media only screen and (max-width:991px) {
  .aon-med-future-area {
    padding: 30px 0px;
  }
}

.aon-med-future-section .row {
  align-items: center;
}


.list-check-style2 {
  list-style: none;
  margin: 30px 0px;
}

.list-check-style2 li .list-title {
  color: #0f5846;
}

.list-check-style2 li {
  padding-left: 70px;
  position: relative;
  padding-bottom: 5px;
}

.list-check-style2 li i {
  position: absolute;
  left: 0px;
  top: 4px;
  color: #fff;
  font-size: 22px;
  background-color: #ff8a00;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}

@media only screen and (max-width:420px) {
  .list-check-style2 li {
    padding-left: 40px;
  }

  .list-check-style2 li i {
    font-size: 14px;
    width: 26px;
    height: 26px;
    line-height: 28px;
  }

  .list-check-style2 li .list-title {
    font-size: 20px;
  }
}

.aone-med-future-r-section {
  max-width: 566px;
  margin-left: auto;
}

.aone-med-future-r-section ul {
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.aone-med-future-r-section ul li {
  width: 50%;
}

.aone-med-future-r-section ul li:first-child {
  margin-top: 40px;
}

@media only screen and (max-width:575px) {
  .aone-med-future-r-section ul li:first-child {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .aone-med-future-r-section ul li {
    width: 100%;
  }

  .aone-med-future-l-inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .aone-med-future-l-inner>div {
    width: 100%;
  }

}

.aone-med-future-l-inner .aone-med-future-content {
  padding: 50px 30px;
  color: #fff;
  background-color: #ff8a00;
}

.aone-med-future-l-inner .aone-med-future-content h3 {
  color: #fff;
  margin-bottom: 15px;
}

.aone-med-future-l-inner .aone-med-future-content span {
  margin-bottom: 20px;
  display: block;
}

.aone-med-future-l-inner .media img {
  width: 100%;
}

.aone-med-future-r-inner .aone-med-future-content {
  padding: 50px 30px;
  color: #fff;
  background-color: #377366;
}

.aone-med-future-r-inner .aone-med-future-content h3 {
  color: #fff;
  margin-bottom: 15px;
}

.aone-med-future-r-inner .aone-med-future-content span {
  margin-bottom: 20px;
  display: block;
}

.aone-med-future-r-inner .media img {
  width: 100%;
}


@media only screen and (max-width:991px) {
  .aone-med-future-r-section {
    max-width: 100%;
    margin-left: 0;
  }
}

.aon-static2-area {
  padding: 90px 0px;
}

@media only screen and (max-width:991px) {
  .aon-static2-area {
    padding: 30px 0px;
  }
}

.aon-static2-section-wrap .row {
  display: flex;
  align-items: center;
}

.aon-static2-section {
  padding: 50px 30px;
  background-color: #fbf5ee;
  text-align: center;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.aon-static2-section .media {
  height: 120px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

@media only screen and (max-width:991px) {
  .aon-static2-section .media {
    height: auto;
  }
}

.aon-static2-section .media img {
  height: 63px;
}

.aon-static2-section .media::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}


.aon-static2-section .counter-area {
  font-size: 40px;
  color: #ff8a00;
}

@media only screen and (max-width:991px) {
  .aon-static2-section .counter-area {
    font-size: 30px;
  }
}

.aon-static2-section .aon-med-static-name {
  font-size: 20px;
  color: #115949;
}

.aon-static2-section:hover {
  background-color: #ff8a00;
}

.aon-static2-section:hover .aon-med-static-name {
  color: #fff;
}

.aon-static2-section:hover .counter-area {
  color: #fff;
}

.aon-static2-section:hover .media:after {
  opacity: 1;
}


.aon-static2-right {
  margin-left: 50px;
}

.aon-static2-section-content .row [class*='col-']:nth-child(even) {
  margin-top: 30px;
}

@media only screen and (max-width:991px) {
  .aon-static2-right {
    margin-left: 0px;
    padding-top: 30px;
  }
}

@media only screen and (max-width:575px) {
  .aon-static2-section-content .row [class*='col-']:nth-child(even) {
    margin-top: 0px;
  }

  .aon-static2-section-content .row [class*='col-'] {
    margin-bottom: 30px;
  }

  .aon-static2-section-content .row [class*='col-']:last-child {
    margin-bottom: 0px;
  }
}



/*Testimonial two*/
.aon-med-testimonial2-area {
  padding: 90px 0px;
  border-top: 1px solid #ddd;
}

@media only screen and (max-width:991px) {
  .aon-med-testimonial2-area {
    padding: 30px 0px;
  }
}

.aon-testimonial2-contaent-wrap {
  position: relative;
  padding: 90px 0px;
}

.aon-med-testimonial2-left {
  max-width: 700px;
}

@media only screen and (max-width:1199px) {
  .aon-med-testimonial2-left {
    max-width: 540px;
  }
}

.aon-med-testimonial-2 {
  padding: 20px 20px 20px 80px;
  position: relative;
  z-index: 1;
}

.aon-med-testimonial-2 .aon-testimonial-name {
  color: #115949;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
}

.aon-med-testimonial-2 .aon-testimonial-position {
  color: #ff8a00;
  font-size: 18px;
  display: inline-block;
}

.aon-med-testimonial-2 .aon-testimonial-quote {
  color: #f5f5f5;
  font-size: 100px;
  position: absolute;
  left: 0px;
  top: 20px;
  line-height: 0.7;
  z-index: -1;
}

.aon-med-testimonial-2 .aon-df-rating {
  margin-bottom: 10px;
}

.aon-med-testimonial-2 .aon-df-rating span {
  display: inline-block;
  padding-right: 2px;
  color: #ff8a00;
  font-size: 20px;
}

.aon-med-testimonial.aon-owl-arrow.left .owl-nav {
  padding-left: 50px;
}

@media only screen and (max-width:480px) {
  .aon-med-testimonial-2 {
    padding: 0px;
  }

  .aon-med-testimonial.aon-owl-arrow.left .owl-nav {
    padding-left: 0px;
  }
}

.aon-owl-arrow.left .owl-nav {
  text-align: left;
}

.aon-med-testimonial2-right {
  position: absolute;
  right: 90px;
  top: 90px;
}

.aon-large-circle-wrap {
  position: relative;
}

.aon-large-circle-wrap .media {
  width: 297px;
  height: 297px;
  position: relative;
}

.aon-large-circle-wrap .media:before,
.aon-large-circle-wrap .media:after {
  content: '';
  position: absolute;
  border: 1px solid #ebebeb;
  border-radius: 50%;
}

.aon-large-circle-wrap .media:before {
  width: 126%;
  height: 126%;
  left: -13%;
  top: -13%;
}

.aon-large-circle-wrap .media:after {
  width: 160%;
  height: 160%;
  left: -30%;
  top: -30%;
}

.aon-large-circle-wrap .media img {
  border-radius: 50%;
  width: auto;
}

.aon-large-circle-wrap span {
  border-radius: 50%;
  background: #ff8a00;
  display: inline-block;
}

.aon-large-circle-wrap span img {
  border-radius: 50%;
}

.aon-large-circle-wrap span.ring1,
.aon-large-circle-wrap span.ring2,
.aon-large-circle-wrap span.ring3,
.aon-large-circle-wrap span.ring4,
.aon-large-circle-wrap span.ring5,
.aon-large-circle-wrap span.ring6,
.aon-large-circle-wrap span.ring7,
.aon-large-circle-wrap span.ring8 {
  position: absolute;
}

.aon-large-circle-wrap span.ring1 img,
.aon-large-circle-wrap span.ring2 img,
.aon-large-circle-wrap span.ring3 img,
.aon-large-circle-wrap span.ring4 img,
.aon-large-circle-wrap span.ring5 img {
  padding: 3px;
  width: 80px;
  height: 80px;
}

.aon-large-circle-wrap span.ring1 {
  bottom: 30px;
  left: -100px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.aon-large-circle-wrap span.ring1 img {
  width: 52px;
  height: 52px;
  background-color: #fff;
}

.aon-large-circle-wrap span.ring2 {
  top: 10px;
  left: -30px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.aon-large-circle-wrap span.ring2 img {
  width: 54px;
  height: 54px;
  background-color: #ff8a00;
}

.aon-large-circle-wrap span.ring3 {
  right: -100px;
  bottom: 0px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.aon-large-circle-wrap span.ring3 img {
  width: 76px;
  height: 76px;
  background-color: #fff;
}

.aon-large-circle-wrap span.ring4 {
  bottom: -70px;
  left: 50%;
  margin-left: -33px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.aon-large-circle-wrap span.ring4 img {
  width: 66px;
  height: 66px;
  background-color: #ff8a00;
}

.aon-large-circle-wrap span.ring5 {
  top: -90px;
  right: -30px;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
}

.aon-large-circle-wrap span.ring5 img {
  width: 86px;
  height: 86px;
  background-color: #ff8a00;
}

.aon-large-circle-wrap span.ring6 {
  width: 32px;
  height: 32px;
  background-color: #ff8a00;
  right: 10px;
  bottom: -70px;
}

.aon-large-circle-wrap span.ring7 {
  width: 22px;
  height: 22px;
  background-color: #ff8a00;
  top: 50%;
  left: -50px;
}

.aon-large-circle-wrap span.ring8 {
  width: 16px;
  height: 16px;
  background-color: #ff8a00;
  right: -44px;
  top: 40%;
}



@media only screen and (max-width:1200px) {
  .aon-large-circle-wrap .media {
    width: 200px;
    height: 200px;
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .aon-large-circle-wrap span.ring1 {
    bottom: 30px;
    left: -70px;
  }

  .aon-large-circle-wrap span.ring1 img {
    width: 42px;
    height: 42px;
  }

  .aon-large-circle-wrap span.ring2 {
    top: 10px;
    left: -30px;
  }

  .aon-large-circle-wrap span.ring2 img {
    width: 44px;
    height: 44px;
  }

  .aon-large-circle-wrap span.ring3 {
    right: -80px;
    bottom: 0px;
  }

  .aon-large-circle-wrap span.ring3 img {
    width: 66px;
    height: 66px;
  }

  .aon-large-circle-wrap span.ring4 {
    bottom: -85px;
    left: 50%;
    margin-left: -33px;
  }

  .aon-large-circle-wrap span.ring4 img {
    width: 56px;
    height: 56px;
  }

  .aon-large-circle-wrap span.ring5 {
    top: -90px;
    right: -30px;
  }

  .aon-large-circle-wrap span.ring5 img {
    width: 76px;
    height: 76px;
  }

  .aon-large-circle-wrap span.ring6 {
    width: 22px;
    height: 22px;
    right: 10px;
    bottom: -70px;
  }

  .aon-large-circle-wrap span.ring7 {
    width: 12px;
    height: 12px;
    top: 50%;
    left: -50px;
  }

  .aon-large-circle-wrap span.ring8 {
    width: 6px;
    height: 6px;
    right: -44px;
    top: 40%;
  }
}


@media only screen and (max-width:991px) {
  .aon-large-circle-wrap .media {
    margin-top: 50px;
    width: 120px;
    height: 120px;
    position: relative;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .aon-large-circle-wrap span.ring1 {
    bottom: 5px;
    left: -48px;
  }

  .aon-large-circle-wrap span.ring1 img {
    width: 32px;
    height: 32px;
  }

  .aon-large-circle-wrap span.ring2 {
    top: 10px;
    left: -30px;
  }

  .aon-large-circle-wrap span.ring2 img {
    width: 34px;
    height: 34px;
  }

  .aon-large-circle-wrap span.ring3 {
    right: -60px;
    bottom: 0px;
  }

  .aon-large-circle-wrap span.ring3 img {
    width: 56px;
    height: 56px;
  }

  .aon-large-circle-wrap span.ring4 {
    bottom: -65px;
    left: 50%;
    margin-left: -33px;
  }

  .aon-large-circle-wrap span.ring4 img {
    width: 46px;
    height: 46px;
  }

  .aon-large-circle-wrap span.ring5 {
    top: -60px;
    right: -30px;
  }

  .aon-large-circle-wrap span.ring5 img {
    width: 66px;
    height: 66px;
  }

  .aon-large-circle-wrap span.ring6 {
    width: 16px;
    height: 16px;
    right: 10px;
    bottom: -70px;
  }

  .aon-large-circle-wrap span.ring7 {
    width: 8px;
    height: 8px;
    top: 50%;
    left: -50px;
  }

  .aon-large-circle-wrap span.ring8 {
    width: 4px;
    height: 4px;
    right: -44px;
    top: 40%;
  }
}


@media only screen and (max-width:991px) {
  .aon-med-testimonial2-left {
    max-width: 100%;
  }

  .aon-large-circle-wrap {
    display: none;
  }

  .aon-large-circle-wrap .media {
    display: none;
  }

  .aon-testimonial2-contaent-wrap {
    position: relative;
    padding: 0px 0px;
    margin-bottom: 10px;
  }
}





/*provider mid Section*/
.aon-med-provider-area2 {
  position: relative;
}

.aon-med-provider-mid-wrap {
  padding: 40px;
  background-image: url(../images/background/bg4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.aon-med-provider-mid {
  padding: 40px;
  border: 2px solid #ff8a00;
}

.aon-med-provider-area2 .row {
  display: flex;
  align-items: center;
}

.aon-med-provider-area2 .section-head {
  margin-bottom: 0px;
}

.aon-med-provider-area2 .section-head.white .aon-title {
  max-width: 580px;
  margin: 0px auto 30px;
}

.aon-med-provider-area2 .section-head.white p {
  margin-bottom: 30px;
}

.aon-med-provider-area2 .media img {
  width: auto;
}

.aon-med-provider-area2-content {
  text-align: center;
}

.aon-med-provider-mid .list-check-style {
  color: #fff;
}

.aon-med-provider-mid .list-check-style li {
  padding-left: 0px;
}

.aon-med-provider-mid .list-check-style li i {
  color: #fff;
  position: inherit;
  padding-right: 5px;
}

@media only screen and (max-width:991px) {
  .aon-med-provider-mid-wrap {
    padding: 11px;
  }

  .aon-med-provider-area2-content .aon-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:420px) {
  .aon-med-provider-area2-content .aon-title {
    font-size: 26px;
  }

  .aon-med-provider-mid {
    padding: 20px;
  }
}





.aon-med-price1-area {
  padding: 90px 0px;
}

.aon-price1-box {
  padding: 40px;
  border: 1px solid #e9efee;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
}

.aon-price1-icon {
  display: table;
  margin: 0px auto 30px;
}

.aon-price1-num {
  font-size: 60px;
  color: #ff8a00;
  line-height: 1;
  font-weight: bold;
  text-align: center;
}

@media only screen and (max-width:480px) {
  .aon-price1-num {
    font-size: 30px;
  }

  .aon-price1-box {
    padding: 20px 20px 40px 20px;
  }
}

.aon-price1-num sub {
  font-size: 30px;
  line-height: 1;
  bottom: 0;
}

.aon-price1-label {
  position: absolute;
  top: 30px;
  left: 0px;
  background: #ff8a00;
  padding: 5px 25px;
  color: #fff;
  border-radius: 0px 20px 20px 0px;
}

.aon-price1-time {
  text-align: center;
  font-size: 16px;
  color: #222;
  font-weight: bold;
}

.aon-price1-list {
  padding: 30px 0px 0px;
  margin: 20px 0px 30px;
  border-top: 1px solid #ddd;
}

.aon-price1-list li {
  position: relative;
  padding: 5px 20px 5px 0px;
  font-size: 18px;
}

.aon-price1-list li i {
  position: absolute;
  top: 8px;
  right: 0px;
  font-size: 24px;
  color: #222;
}

.aon-price1-box.active-plan {
  background-color: #fbf5ee;
}

.active-plan .aon-price1-label {
  background: #fff;
  color: #ff8a00;
}

.active-plan .aon-price1-num {
  color: #0c5e5c;
}

.active-plan .aon-price1-list li {
  color: #0c5e5c;
}

.active-plan .aon-price1-list li i {
  color: #0c5e5c;
}

.active-plan .aon-price1-time {
  color: #0c5e5c;
}

@media only screen and (max-width:991px) {
  .aon-price1-box {
    margin-bottom: 50px;
  }

  .aon-med-price1-area {
    padding: 30px 0px;
  }
}



.aon-doc-findbar {
  display: flex;
  position: relative;
  margin-bottom: 0px;
  max-width: 750px;
  margin: 0px auto 50px;
}

.aon-doc-findbar .form-control {
  height: 58px;
  width: 100%;
  border-radius: 12px;
  border: none;
  border: 1px solid #ff8a00;
}

.aon-docFind-btn {
  height: 58px;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 14px;
  color: #fff;
  border: none;
  font-weight: 500;
  background-color: #ff8a00;
  border-radius: 0px 12px 12px 0px;
  padding: 8px 30px;
}

.aon-price1-btn {
  display: table;
  margin: 0px auto -60px;
}




.aon-med-team-area2 {
  border-top: 1px solid #ddd;
}

.aon-med-team.bg-light-gray {
  background-color: #f6fbf8;
}



.aon-search-heading2 {
  border-top: 1px solid #ddd;
}

.aon-search-info-wrap2 {
  padding: 30px 0px;
}

.aon-search-info-wrap2 .container {
  max-width: 100%;
}

.aon-searchInfo-text .fa {
  display: inline-block;
  width: 20px;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 10px;
  background: #00a900;
  color: #fff;
}

@media only screen and (max-width:991px) {
  .aon-search-heading2 .container {
    max-width: 100%;
  }
}


.aon-banner3-area {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.aon-search3-form {
  padding: 30px;
  width: 100%;
  display: table;
}

.aon-search3-left,
.aon-search3-right {
  display: table-cell;
  vertical-align: middle;
}

.aon-search3-right {
  width: 1%;
}

.aon-search3-table {
  display: table;
  width: 100%;
}

.aon-search3-table .aon-search3-col {
  display: table-cell;
  padding: 0px 15px;
  vertical-align: middle;
}



.aon-search-result-left {
  width: 50%;
  padding: 30px;
  float: left;
  background: #fff;
}

.sf-search-result-head {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.sf-search-result-option {
  padding: 20px 0px;
}

.aon-search-half-map {
  width: 50%;
  height: calc(100vh - 90px);
  bottom: 0px;
  right: 0px;
  background: #222;
  float: right;
  position: fixed;

}

@media only screen and (max-width:1540px) {
  .aon-search-result-left {
    width: 58%;
  }

  .aon-search-half-map {
    width: 42%;
  }
}

@media only screen and (max-width:1365px) {
  .aon-search-result-left {
    width: 70%;
  }

  .aon-search-half-map {
    width: 30%;
  }
}

@media only screen and (max-width:991px) {
  .aon-two-part-page {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .aon-search-result-left {
    width: 100%;
  }

  .aon-search-result-left .sf-listing-view-wrap {
    max-width: 100%;
    margin: 0px auto;
  }

  .aon-search-result-left .aon-search3-area {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #ddd;
    padding: 30px 15px;
    border-radius: 5px;
  }

  .aon-search-result-left .aon-search3-area .aon-search3-left,
  .aon-search-result-left .aon-search3-area .aon-search3-right {
    width: 100%;
    display: block;
  }

  .aon-search-result-left .aon-search3-area .aon-search3-left .aon-search3-table {
    display: flex;
    margin-bottom: 10px;
  }

  .aon-search-result-left .aon-search3-area .aon-search3-left .aon-search3-col.aon-category-col {
    width: 50%;
    display: block;
    margin-bottom: 10px;
  }

  .aon-search-result-left .aon-search3-area .aon-search3-left .aon-search3-col.aon-search-col {
    width: 50%;
    display: block;
    margin-bottom: 10px;
  }

  .aon-search-result-left .aon-search3-area .aon-search3-right {
    margin-left: 15px;
    margin-right: 15px;
    width: auto;
  }

  .aon-search-half-map {
    width: 100%;
    position: inherit;
    height: 300px;
  }
}

@media only screen and (max-width:767px) {
  .aon-search-result-left .aon-search3-area .aon-search3-left .aon-search3-table {
    display: block;
    margin-bottom: 15px;
  }

  .aon-search-result-left .aon-search3-area .aon-search3-left .aon-search3-col.aon-category-col {
    width: 100%;
  }

  .aon-search-result-left .aon-search3-area .aon-search3-left .aon-search3-col.aon-search-col {
    width: 100%;
    margin-bottom: 15px;
  }

  .aon-search-result-left .sf-listing-view-wrap {
    max-width: 520px;
  }

  .aon-search-result-left .aon-search3-area {
    max-width: 100%;
  }
}

.aon-search-half-map iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
}

@media only screen and (max-width:991px) {
  .aon-search-half-map iframe {
    height: 300px;
    position: static;
  }
}




/*Contact Page One*/

.aon-page-benner-overlay {
  position: absolute;
  opacity: 0.8;
  background-color: #043c30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aon-page-benner-area {
  background-image: url(../images/banner/job-banner.jpg);
  position: relative;
}

.sf-banner-heading-wrap {
  display: table;
  position: relative;
  height: 400px;
  width: 100%;
  max-width: 1170px;
  margin: auto;
  text-align: center;
}

.sf-banner-heading-area {
  display: table-cell;
  padding: 50px 15px;
  vertical-align: middle;
  color: #fff;
}

.sf-banner-heading-large {
  font-size: 55px;
  margin-bottom: 15px;
  font-weight: 300;
}

@media only screen and (max-width:767px) {
  .sf-banner-heading-large {
    font-size: 36px;
  }

  .sf-banner-heading-wrap {
    height: 300px;
  }
}

.sf-banner-heading-large strong {
  font-weight: 600;
}

.breadcrumbs {
  padding: 25px;
}

.sf-banner-breadcrumbs-nav ul {
  margin: 0;
}

.sf-banner-breadcrumbs-nav ul li {
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  margin-right: 20px;
  color: #fff;
}

.sf-banner-breadcrumbs-nav ul li a {
  color: #fff;
}

.sf-banner-breadcrumbs-nav ul li:after {
  content: "\f105";
  position: absolute;
  right: -13px;
  top: 1px;
  font-size: 18px;
  color: #fff;
  font-family: "FontAwesome";
  margin-left: 7px;
}

.sf-banner-breadcrumbs-nav ul li:last-child:after {
  display: none;
}

.sf-contact-form2 {
  max-width: 940px;
  margin: 0px auto -100px;
  position: relative;
  z-index: 1;
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
}

.sf-contact-form2 .form-control {
  border-width: 0px 0px 1px 0px;
  border-color: #ddd;
  border-style: solid;
}

.sf-contact-form2 textarea.form-control {
  height: 100px;
}

@media only screen and (max-width:991px) {
  .sf-contact-form2-wrap .sf-con-form-title h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .sf-contact-form2-wrap .sf-con-form-title h2 {
    font-size: 26px;
  }
}

/*Sf Map*/
.sf-map-wrap {
  height: 430px;
}

.sf-map-wrap iframe {
  width: 100%;
  height: 100%;
}


.aon-contact2-area {
  position: relative;
}

.aon-contact2-area .row {
  display: flex;
  align-items: center;
}

@media only screen and (max-width:991px) {
  .aon-contact2-area {
    padding: 30px 0px;
  }
}

.aon-contact-1-pic {
  position: relative;
  z-index: 1;
  top: 135px;
}

.aon-contact-1-bg {
  position: absolute;
  top: 80px;
  left: 0px;
  bottom: 0px;
  background-color: #ff8a00;
  width: calc(50% - 280px);
  font-family: arial;
  font-weight: bold;
}

.aon-contact-1-bg span {
  color: #fff;
  font-size: 180px;
  opacity: 0.2;
  display: table;
  line-height: 1;
}

.aon-contact-1-bg .star-two {
  position: absolute;
  top: -50px;
  left: -40px;
  color: #fff;
  font-size: 350px;
  opacity: 0.2;
}

.aon-contact-1-bg .star-three {
  position: absolute;
  top: 50px;
  left: 30%;
  color: #fff;
  font-size: 150px;
  opacity: 0.2;
}

.aon-contact-1-bg .star-four {
  position: absolute;
  bottom: -80px;
  left: 10%;
  color: #fff;
  font-size: 450px;
  opacity: 0.2;
}

.aon-contact-1-info-wrap {
  padding: 80px;
  background-color: #fff;
  border: 6px solid #f2f7f6;
}

.sf-contact-1-info-box {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  display: flex;
}

.aon-contact-1-info-wrap .sf-contact-1-info-box:last-child {
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.sf-contact-1-info-box .sf-contact-1-info .sf-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.sf-contact-1-info-box .sf-contact-1-info p {
  margin-bottom: 0px;
}

.sf-contact-1-icon {
  position: relative;
  z-index: 1;
  width: 100px;
}

.sf-contact-1-icon span {
  display: block;
  line-height: 60px;
}

@media only screen and (max-width:991px) {
  .aon-contact-media {
    position: relative;
    margin-bottom: 80px;
  }

  .aon-contact-1-pic {
    top: 0px;
  }

  .aon-contact-1-bg {
    width: calc(50% - 0px);
  }

  .aon-contact2-area {
    padding-bottom: 0px;
  }

  .aon-contact-1-info-wrap {
    padding: 30px;
  }
}

@media only screen and (max-width:767px) {
  .sf-contact-1-info-box .sf-contact-1-info .sf-title {
    font-size: 18px;
  }
}

@media only screen and (max-width:575px) {
  .sf-contact-1-icon img {
    height: 50px;
  }

  .sf-contact-1-icon {
    width: 70px;
    margin-bottom: 10px;
  }

  .sf-contact-form2 {
    padding: 30px;
  }

  .sf-contact-1-info-box {
    display: block;
  }
}


.aon-med-team2-area {
  padding: 90px 0px;
}

.aon-med-team2 {
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 30px;
  text-align: center;
}

.aon-med-team2 .aon-med-team2-pic {
  margin-bottom: 20px;
}

.aon-med-team2 .aon-med-team2-pic img {
  width: 100%;
}

.aon-med-team2 .aon-med-team2-discription {
  margin-bottom: 5px;
}

.aon-med-team2 .aon-med-team2-position {
  display: block;
  color: #ff8a00;
  font-size: 16px;
  margin-bottom: 5px;
}

.aon-med-team2 .aon-med-team2-location {
  margin-bottom: 5px;
  display: block;
}

.aon-med-team2 .aon-med-team2-location i {
  margin-right: 5px;
  color: #000;
}

.aon-med-team2 .aon-df-rating {
  margin-bottom: 5px;
}

@media only screen and (max-width:991px) {
  .aon-med-team2-area {
    padding: 30px 0px;
  }
}


/*Sf Map*/
.sf-map2-wrap {
  height: 430px;
  position: relative;
}

.sf-map2-wrap iframe {
  width: 100%;
  height: 430px;
}

.aon-contact-2-pic {
  position: relative;
  z-index: 1;
  padding: 40px 40px 0px 40px;
}

.aon-contact-2-pic:after {
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff8a00;
  opacity: 0.2;
}

.aon-contact-2-info-content {
  margin-left: 30px;
}

.aon-contact-2-info-wrap {
  padding: 40px;
  background-color: #fff;
  border: 6px solid #f2f7f6;
}

.sf-contact-2-info-box {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  display: flex;
}

.aon-contact-2-info-wrap .sf-contact-2-info-box:last-child {
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.sf-contact-2-info-box .sf-contact-2-info .sf-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.sf-contact-2-info-box .sf-contact-2-info p {
  margin-bottom: 0px;
}

.sf-contact-2-icon {
  position: relative;
  z-index: 1;
  width: 50px;
  margin-right: 30px;
}

.sf-contact-2-icon span {
  display: block;
  line-height: 60px;
}

@media only screen and (max-width:767px) {
  .sf-contact-2-info-box .sf-contact-2-info .sf-title {
    font-size: 18px;
  }
}

@media only screen and (max-width:575px) {
  .sf-contact-2-info-box {
    display: block;
  }

  .sf-contact-2-icon {
    margin-bottom: 10px;
  }
}

.sf-contact-form3 {
  margin: 0px auto;
  position: relative;
  z-index: 1;
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
}

.sf-contact-form3 .form-control {
  border-width: 0px 0px 1px 0px;
  border-color: #ddd;
  border-style: solid;
}

.sf-contact-form3 textarea.form-control {
  height: 100px;
}

@media only screen and (max-width:991px) {
  .sf-contact-form3-map {
    padding: 30px 0px;
  }

  .sf-contact-form3-area {
    padding: 30px 0px;
  }

  .sf-contact-form3-wrap .sf-con-form-title h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .sf-contact-form3-wrap .sf-con-form-title h2 {
    font-size: 26px;
  }
}

.aon-doc-category-wrap {
  padding: 80px 0px;
}

.col-md-five {
  width: 20%;
}

@media only screen and (max-width:1200px) {
  .col-md-five {
    width: 25%;
  }
}

@media only screen and (max-width:991px) {
  .aon-contact-2-pic {
    text-align: center;
  }

  .aon-contact-2-pic:after {
    max-width: 650px;
  }

  .col-md-five {
    width: 33.333%;
  }

  .aon-contact-2-info-content {
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .aon-doc-category-wrap {
    padding: 30px 0px;
  }

  .aon-doc-category-wrap .aon-title {
    font-size: 30px;
    margin-bottom: 30px !important;
  }
}

@media only screen and (max-width:767px) {
  .col-md-five {
    width: 50%;
  }

  .sf-contact-form3 {
    padding: 30px;
  }

  .aon-doc-category-wrap .aon-title {
    font-size: 26px;
  }

}


.aon-doc-cate-box {
  background-color: #ebf0f0;
  border-radius: 10px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.05);
}

.aon-doc-cate-pic {
  border-radius: 10px;
}

.aon-doc-cate-pic img {
  border-radius: 10px;
  width: 100%;
}

.aon-doc-cate-title {
  padding: 15px 10px;
  text-align: center;
}




/*======================
	Shop Style One
========================*/

.aon-product-wrap {
  background-color: #f6fbf8;
  padding: 80px 0px;
}

.ser-shop-style1-box {
  text-align: center;
}

.ser-shop-style1-box .ser-shop-media {
  position: relative;
}

.ser-shop-style1-box .ser-shop-media img {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
}

@media only screen and (max-width:991px) {
  .aon-product-wrap {
    padding: 30px 0px;
  }

  .aon-product-wrap .aon-title {
    font-size: 30px;
    margin-bottom: 30px !important;
  }
}

@media only screen and (max-width:767px) {
  .aon-product-wrap .aon-title {
    font-size: 26px;
  }
}

.ser-shop-style1-box .ser-shop-media .bedge {
  position: absolute;
  left: 20px;
  top: 95px;
  color: #fff;
  padding: 5px 20px;
  display: inline-block;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  line-height: 1.42857;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: #06328b;
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  text-transform: uppercase;
  letter-spacing: 2px;
  transform-origin: left top;
}

.ser-shop-style1-box .ser-shop-media .bedge.disabled {
  display: none;
}

.ser-shop-style1-box .ser-shop-media .shop-item-controls {
  list-style: none;
  overflow: hidden;
  margin-bottom: 0px;
  display: table;
  border: 1px solid #000;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  right: 30px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.ser-shop-style1-box:hover .ser-shop-media .shop-item-controls {
  opacity: 1;
  top: 30px;

}

.ser-shop-style1-box .ser-shop-media .shop-item-controls li {
  display: block;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #000;
}

.ser-shop-style1-box .ser-shop-media .shop-item-controls li:last-child {
  border-width: 0px;
}

.ser-shop-style1-box .ser-shop-media .shop-item-controls li a {
  display: block;
  color: #ff8a00;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
}

.ser-shop-style1-box .ser-shop-media .shop-item-controls li a:hover {
  color: #fff;
  background-color: #ff8a00;
}

.ser-shop-style1-box .ser-shop-info {
  padding: 20px 0px;
}

.ser-shop-style1-box .ser-shop-info .ser-shop-title {
  margin-top: 0px;
}

.ser-shop-style1-box .ser-shop-info .ser-pro-item-price {
  font-size: 18px;
  font-weight: normal;
}

.ser-shop-style1-box .ser-shop-info .ser-pro-item-price strike {
  padding-right: 10px;
  color: #777777;
  opacity: 0.8;
}



/*lAB TEST*/
.aon-need-help-heading-area {
  padding: 60px 0px 0px 0px
}

.aon-need-help-heading {
  padding: 30px;
  border-radius: 10px;
  background-color: #2c6c5e;
}

.aon-need-help-heading .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aon-need-help-heading-l {
  color: #fff;
  position: relative;
  padding-left: 110px;
  min-height: 90px;
}

.aon-need-help-heading-l img {
  width: 90px;
  height: 90px;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 50%;
}

.aon-need-help-heading-l h3 {
  color: #fff;
  padding-top: 10px;
}

.aon-need-help-heading-l p {
  font-size: 18px;
}

.aon-need-help-heading-r {
  text-align: right;
}

@media only screen and (max-width:991px) {
  .aon-need-help-heading-r {
    text-align: left;
    margin-top: 20px;
  }
}

@media only screen and (max-width:420px) {
  .aon-need-help-heading-l {
    padding-left: 0px;
  }

  .aon-need-help-heading-l img {
    position: inherit;
  }

  .aon-need-help-heading-r {
    margin-top: 0px;
  }
}

.aon-need-help-heading-r span {
  font-size: 18px;
  color: #fff;
  border: 1px solid #60887f;
  padding: 8px 19px;
  border-radius: 10px;
  display: inline-block;
}

.aon-need-help-heading-r span a {
  color: #fff;
}

/*Booked Test*/
.aon-booked-test-area {
  padding: 90px 0px;
}

.aon-booked-test-block {
  padding: 20px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  transition: all 0.5s linear;
}

.aon-booked-test-block:hover {
  border: 1px solid #ff8a00;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, .1);
}

.aon-booked-test-block .aon-test-name {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.aon-booked-test-block .aon-test-price {
  font-size: 30px;
  font-weight: 600;
  color: #ff8a00;
}

.aon-booked-test-block .aon-test-duration {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

@media only screen and (max-width:991px) {
  .aon-booked-test-area {
    padding: 30px 0px;
  }

  .aon-booked-test-area .aon-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .aon-booked-test-area .aon-title {
    font-size: 26px;
  }
}

.aon-owl-arrow.arrow-center .owl-next {
  background: #fff !important;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  border: 1px solid #ddd !important;
  margin-right: -20px !important;
}

.aon-owl-arrow.arrow-center .owl-prev {
  background: #fff !important;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  border: 1px solid #ddd !important;
  margin-left: -20px !important;
}

.aon-owl-arrow.arrow-center .owl-prev .ar-left {
  width: 0px;
  height: 0px;
  background-color: #ff8a00;
  display: block;
  position: relative;
  left: 3px;
}

.aon-owl-arrow.arrow-center .owl-prev .ar-left:after {
  content: '';
  background-color: #000;
  width: 10px;
  height: 1px;
  top: -4px;
  left: -4px;
  position: absolute;
  transform: rotate(-40deg);
}

.aon-owl-arrow.arrow-center .owl-prev .ar-left::before {
  content: '';
  background-color: #000;
  width: 10px;
  height: 1px;
  bottom: -4px;
  left: -4px;
  position: absolute;
  transform: rotate(40deg);
}

.aon-owl-arrow.arrow-center .owl-next .ar-right {
  width: 0px;
  height: 0px;
  background-color: #ff8a00;
  display: block;
  position: relative;
  right: -16px;
}

.aon-owl-arrow.arrow-center .owl-next .ar-right:after {
  content: '';
  background-color: #000;
  width: 10px;
  height: 1px;
  top: -4px;
  right: -2px;
  position: absolute;
  transform: rotate(40deg);
}

.aon-owl-arrow.arrow-center .owl-next .ar-right::before {
  content: '';
  background-color: #000;
  width: 10px;
  height: 1px;
  bottom: -4px;
  right: -2px;
  position: absolute;
  transform: rotate(-40deg);
}

.aon-owl-arrow.arrow-center .owl-nav {
  margin-top: 0px;

}

@media only screen and (max-width:575px) {

  .aon-owl-arrow.arrow-center .owl-prev,
  .aon-owl-arrow.arrow-center .owl-next {
    position: inherit;
  }

  .aon-owl-arrow.arrow-center .owl-nav {
    margin-top: 30px;
  }

  .aon-owl-arrow.arrow-center .owl-prev .ar-left {
    left: -3px;
  }
}

.site-button.round {
  width: 45px;
  height: 45px;
  line-height: 45px;
  padding: 0px;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
}

.site-button.round i {
  padding-right: 0px;
}

.aon-health-concern-area {
  padding: 90px 0px;
}

.aon-health-concer-block {
  padding: 20px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  transition: all 0.5s linear;
  background-color: #fff;
  margin-bottom: 25px;
}

.aon-health-concer-block:hover {
  border: 1px solid #ff8a00;
}

.aon-health-concer-block .aon-health-concer-media span {
  text-align: center;
  display: block;
  height: 90px;
}

.aon-health-concer-block .aon-health-concer-media span img {
  width: auto;
  display: inline-block;
}

.aon-health-concer-block .aon-health-concer-type {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
}

.aon-health-concer-block .site-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width:991px) {
  .aon-health-concern-area {
    padding: 30px 0px;
  }

  .aon-health-concern-area .aon-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .aon-health-concern-area .aon-title {
    font-size: 26px;
  }
}

/*Book Us*/
.aon-book-us-area {
  padding: 90px 0px;
}

.aon-book-us-section .row {
  display: flex;
  justify-content: center;
}

.aon-book-us-section .aon-book-us-block {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ebebeb;
}

.aon-book-us-section .aon-book-us-block .media {
  height: 90px;
}

@media only screen and (max-width:991px) {
  .aon-book-us-area {
    padding: 30px 0px;
  }

  .aon-book-us-area .aon-title {
    font-size: 30px;
  }

  .aon-book-us-section .aon-book-us-block {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width:767px) {
  .aon-book-us-area .aon-title {
    font-size: 26px;
  }
}

@media only screen and (max-width:575px) {
  .aon-book-us-block h3 {
    font-size: 20px;
  }
}

.bg-dark-green {
  background-color: #2c6c5e;
}

/*Health Checkup*/
.aon-health-checkup-area {
  padding: 90px 0px;
}

@media only screen and (max-width:991px) {
  .aon-health-checkup-area {
    padding: 30px 0px;
  }

  .aon-health-checkup-area .aon-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .aon-health-checkup-area .aon-title {
    font-size: 26px;
  }
}

.aon-health-checkup-block {
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
}

.aon-health-checkup-media span {
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 10px;
  display: block;
}

.aon-health-checkup-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 20px;
  position: relative;
}

.aon-health-checkup-price span {
  color: #000;
  font-weight: 600;
  display: inline-block;
}

.aon-health-checkup-price span.discount {
  color: #9d9d9d;
}

.aon-health-checkup-price span.offer {
  color: #59c035;
}

.aon-health-checkup-price:after {
  content: '';
  position: absolute;
  width: calc(100% + 60px);
  height: 1px;
  background-color: #ededed;
  left: -30px;
  bottom: 0px;
}

.aon-health-checkup-timing {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.aon-health-checkup-timing .book-now {
  color: #ff8a00;
}


/*We do great*/
.aon-we-great-area {
  padding: 90px 0px;
}

@media only screen and (max-width:991px) {
  .aon-we-great-area {
    padding: 30px 0px;
  }

  .aon-we-great-area .aon-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .aon-we-great-area .aon-title {
    font-size: 26px;
  }
}

.aon-we-great-block-outer {
  margin-right: 100px;
}

.aon-we-great-block-outer .aon-we-great-block {
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ededed;
  margin-bottom: 20px;
  transition: all 0.5s linear;
  display: table;
  width: 100%;
}

.aon-we-great-block-outer .aon-we-great-block:last-child {
  margin-bottom: 0px;
}

.aon-we-great-block-outer .aon-we-great-block:hover {
  border: 1px solid #ff8a00;
  background-color: #f7fcfb;
}

.aon-we-great-block-outer .aon-we-great-block .media {
  width: 100px;
  display: table-cell;
}

@media only screen and (max-width:1199px) {
  .aon-we-great-block-outer {
    margin-right: 0px;
  }
}

@media only screen and (max-width:575px) {
  .aon-we-great-block h3 {
    font-size: 20px;
  }

  .aon-we-great-block-outer .aon-we-great-block {
    display: block;
    padding: 30px;
  }

  .aon-we-great-block-outer .aon-we-great-block .media {
    display: block;
    margin-bottom: 20px;
  }
}

.aon-we-great-right {
  position: relative;
  z-index: 1;
  margin-top: -125px;
}

.aon-we-great-right-bg {
  background-color: #2c6c5e;
  position: absolute;
  border-radius: 10px;
  width: 100%;
  top: 110px;
  z-index: -1;
  bottom: 0px;
}

.aon-we-great-right .star-two {
  position: absolute;
  top: -50px;
  right: 20px;
  left: auto;
  color: #fff;
  font-size: 350px;
  opacity: 0.2;
}

.aon-we-great-right .star-three {
  position: absolute;
  top: 50px;
  right: 50px;
  left: auto;
  color: #fff;
  font-size: 150px;
  opacity: 0.2;
}

.aon-page-jobs-wrap {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width:991px) {
  .aon-we-great-right {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

/*Side Bar Widgets*/
.aon-owl-arrow .owl-next {
  background: none;
  border: none;
  margin: 0px 20px;
}

.aon-owl-arrow .owl-prev {
  background: none;
  border: none;
  margin: 0px 20px;
}

.aon-owl-arrow .owl-prev .ar-left {
  width: 0px;
  height: 0px;
  background-color: #ff8a00;
  display: block;
  position: relative;
}

.aon-owl-arrow .owl-prev .ar-left:after {
  content: '';
  background-color: #000;
  width: 16px;
  height: 2px;
  top: -6px;
  left: -3px;
  position: absolute;
  transform: rotate(-40deg);
}

.aon-owl-arrow .owl-prev .ar-left::before {
  content: '';
  background-color: #000;
  width: 16px;
  height: 2px;
  bottom: -6px;
  left: -3px;
  position: absolute;
  transform: rotate(40deg);
}

@media only screen and (max-width:480px) {
  .aon-owl-arrow .owl-prev .ar-left::before {
    left: -14px;
  }

  .aon-owl-arrow .owl-prev .ar-left:after {
    left: -13px;
  }
}

.aon-owl-arrow .owl-next .ar-right {
  width: 0px;
  height: 0px;
  background-color: #ff8a00;
  display: block;
  position: relative;
}

.aon-owl-arrow .owl-next .ar-right:after {
  content: '';
  background-color: #000;
  width: 16px;
  height: 2px;
  top: -6px;
  right: -3px;
  position: absolute;
  transform: rotate(40deg);
}

.aon-owl-arrow .owl-next .ar-right::before {
  content: '';
  background-color: #000;
  width: 16px;
  height: 2px;
  bottom: -6px;
  right: -3px;
  position: absolute;
  transform: rotate(-40deg);
}

.aon-owl-arrow .owl-nav {
  margin-top: 10px;
}


/*Side Bar Widgets*/
.side-bar .widget {
  margin-bottom: 20px;
}

.rounded-sidebar-widget {
  border-radius: 5px;
  background-color: #f6fbf8;
  padding: 30px;
  border: 1px solid #ddd;
}

/*widget search custom*/
.widget_search_bx button {
  border-left-color: #FFF;
  padding: 9px 20px;
  color: #fff !important;
  background-color: #ff8a00;
  border-radius: 0px 5px 5px 0px;
  border: 0px;
  height: 44px;
}

.widget_search_bx .input-group .form-control {
  border-right: 1px solid #ff8a00;
  height: 44px;
  border-radius: 5px 0px 0px 5px;
  background-color: #fff;
}

/*Recent post widgets*/
.aon-recentpost-carousel .post-info {
  padding-bottom: 35px;
  border-bottom: 1px solid #ddd;
}

.aon-recentpost-carousel .aon-blog-style-1 {
  margin-bottom: 10px;
}

.recent-posts-entry .widget-post {
  margin-bottom: 20px;
}

.recent-posts-entry .widget-post:last-child {
  border: none;
  margin-bottom: 0px;
}

.recent-posts-entry .wt-post-meta li {
  padding: 0;
  display: block;
  font-size: 14px;
}

.recent-posts-entry .wt-post-meta li:last-child {
  margin-bottom: 0px;
}

.recent-posts-entry .wt-post-meta li:after {
  content: "|";
  display: inline-block;
  font-weight: normal;
  margin-left: 5px;
  font-size: 14px;
  margin-right: 5px;
  color: #000;
}

.recent-posts-entry .wt-post-meta li:last-child::after {
  display: none;
}

.recent-posts-entry .wt-post-date {
  background-color: #0e0e0e;
  width: 50px;
  height: 60px;
  float: left;
}

.recent-posts-entry .wt-post-media {
  width: 68px;
  float: left;
}

.recent-posts-entry .wt-post-media img {
  border-radius: 10px;
}

.recent-posts-entry .wt-post-info {
  margin-left: 88px;
}

.recent-posts-entry .wt-post-info .wt-post-meta ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.recent-posts-entry .wt-post-info .wt-post-meta ul li {
  font-size: 14px;
  color: #6d6d6d;
}

.recent-posts-entry .wt-post-info .post-title {
  margin-top: 0px;
}

.recent-posts-entry .wt-post-info .post-title a {
  color: #022279;
}

/*widget tags-list*/
.widget_tag_cloud a {
  padding: 3px 14px;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px 15px 0px;
  color: #ff8a00;
  background: #fff;
  position: relative;
  border: 1px solid #e8e8e8;
  position: relative;
  border-radius: 3px;
  z-index: 1;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.widget_tag_cloud a:before {
  position: absolute;
  content: '';
  right: 100%;
  top: 0px;
  background-color: #2c6c5e;
  width: 0%;
  height: 100%;
  z-index: -1;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.widget_tag_cloud a:hover:before {
  width: 100%;
  right: 0%;
}

.widget_tag_cloud a:hover {
  color: #ff8a00;
}

/*Widget Social Links*/
.widget_social_inks ul {
  list-style: none;
  display: flex;
  margin-bottom: 0px;
}

.widget_social_inks ul li {
  display: block;
}

.widget_social_inks ul li a {
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 50%;
  display: block;
  margin-right: 8px;
  text-align: center;
}




/*Latest Blog*/
.aon-latest-blog-area2 {
  background-color: #fff;
  padding: 90px 0px;
}

.aon-blog-style-1 {
  margin-bottom: 30px;
}

.aon-blog-style-1 .post-bx {
  border-radius: 5px;
  margin-bottom: 0px;
}

.aon-blog-style-1 .post-bx .post-thum {
  border-radius: 5px;
  overflow: hidden;
}

.aon-blog-style-1 .post-bx .post-thum img {
  width: 100%;
}

.aon-blog-style-1 .post-info {
  background: none;
  padding: 30px 0px;
  text-align: center;
}

@media only screen and (max-width:767px) {
  .aon-blog-style-1 .post-info {
    padding-bottom: 0px;
  }

  .aon-recentpost-carousel .aon-blog-style-1 .post-info {
    padding-bottom: 30px;
  }
}

.aon-blog-style-1 .post-info .post-categories {
  display: none;
  position: relative;
  margin-top: -46px;
  margin-bottom: 20px;
}

.aon-blog-style-1 .post-info .post-categories a {
  display: inline-block;
  padding: 5px 20px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 10px;
  background-color: #ff8a00;
  margin-right: 8px;
  font-size: 14px;
}

.aon-blog-style-1 .post-info .post-categories a:last-child {
  margin-right: 0px;
}

.aon-blog-style-1 .post-meta {
  border: 0px;
  padding: 0px;
  margin: 0px;
}

.aon-blog-style-1 .post-meta ul {
  margin: 0px 0px 10px 0px;
  float: none;
}

.aon-blog-style-1 .post-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.aon-blog-style-1 .post-date {
  width: inherit;
  background: none;
  margin: 0px;
}

.aon-blog-style-1 .post-date {
  width: inherit;
  background: none;
  margin: 0px;
}

.aon-blog-style-1 .post-date span {
  background: none;
  padding: 0px;
  font-size: inherit;
  display: inherit;
  vertical-align: inherit;
}

.aon-blog-style-1 .post-meta ul li {
  color: #595959;
  position: relative;
  padding-right: 10px;
  display: inline-block;
}

.aon-blog-style-1 .post-meta ul li:last-child {
  padding-right: 0px;
}

.aon-blog-style-1 .post-meta ul li:after {
  content: '|';
  position: absolute;
  right: 0px;
  top: 0px;
}

.aon-blog-style-1 .post-meta ul li:last-child:after {
  display: none;
}

.aon-blog-style-1 .post-meta ul li a {
  color: #595959;
}

.post-read-more .site-button-link {
  position: relative;
  display: inline-block;
}

.post-read-more .site-button-link::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #ff8a00;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}




.sf-blog-style-1 .post-meta {
  border: 0px;
  padding: 0px;
  margin: 0px;
}

.blog-post .post-meta ul {
  margin: 0px 0px 10px 0px;
  float: none;
  list-style: none;
}

.sf-blog-style-1 .post-title {
  font-size: 24px;
}

.blog-grid.blog-post .post-date {
  width: inherit;
  background: none;
  margin: 0px;
}

.blog-list.blog-post .post-date {
  width: inherit;
  background: none;
  margin: 0px;
}

.blog-post .post-date span {
  background: none;
  padding: 0px;
  font-size: inherit;
  display: inherit;
  vertical-align: inherit;
}

.blog-post .post-meta ul li {
  color: #595959;
  position: relative;
  padding-right: 10px;
  display: inline-block;
}

.blog-post .post-meta ul li:last-child {
  padding-right: 0px;
}

.blog-post .post-meta ul li:after {
  content: '|';
  position: absolute;
  right: 0px;
  top: 0px;
}

.blog-post .post-meta ul li:last-child:after {
  display: none;
}

.blog-post .post-meta ul li a {
  color: #595959;
}



/*BLog Detail*/
.sf-blog-style-1 .post-info {
  background: none;
  padding: 30px 0px;
}

.sf-blog-style-1 .post-bx {
  border-radius: 14px;
  margin-bottom: 30px;
}

.sf-blog-style-1.blog-detail .post-bx {
  background-color: transparent;
  border-radius: 0px;
  border-bottom: 0px;
  margin-bottom: 30px;
}

.sf-blog-style-1.blog-detail.blog-post .post-date {
  width: inherit;
  background: none;
  margin: 0px;
}

.sf-detail-disc-list {
  margin: 10px 0px 20px 0px;
  list-style: decimal;
  list-style-position: inside;
}

.sf-detail-disc-list li {
  padding-bottom: 5px;
}

.sf-blog-style-1.blog-detail .post-info {
  padding: 30px 0px 0px;
}

blockquote {
  margin: 30px 0px;
}

.blog-detail blockquote {
  background-color: #ff8a00;
  color: #fff;
  padding: 50px;
}

.blog-detail blockquote:before {
  display: none;
}

.blog-detail blockquote h4 {
  font-size: 36px;
  margin-top: 0px;
  margin-bottom: 15px;
  color: #fff;
}

.blog-detail blockquote .quoter {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
}

@media only screen and (max-width:991px) {
  .blog-detail blockquote h4 {
    font-size: 30px;
  }
}

@media only screen and (max-width:575px) {
  .blog-detail blockquote {
    padding: 20px;
  }

  .blog-detail blockquote h4 {
    font-size: 22px;
  }

  .blog-detail blockquote .quoter {
    font-size: 14px;
  }
}

.blog-detail .sf-con-social-wrap {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.blog-detail .sf-con-social {
  margin: 0px;
}

@media only screen and (max-width:991px) {
  .blog-detail .post-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .blog-detail .post-title {
    font-size: 26px;
  }
}

@media only screen and (max-width:420px) {
  .blog-detail .sf-con-social-wrap {
    display: block !important;
    padding: 20px;
  }
}

.sf-con-social {
  margin: 0px;
}

.sf-con-social li {
  display: inline-block;
  margin-right: 20px;
}

.sf-con-social li:last-child {
  margin-right: 0px;
}

.sf-con-social li a {
  display: block;
  border-radius: 8px;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  padding: 12px 15px;
}

.sf-con-social li a img {
  padding-right: 5px;
}

.sf-con-social li a.sf-fb {
  background-color: #1768d4;
}

.sf-con-social li a.sf-twitter {
  background-color: #1ebce9;
}

.sf-con-social li a.sf-pinterest {
  background-color: #d63127;
}

.sf-con-social-pic span {
  display: inline-block;
  border-radius: 50%;
  border: 3px solid #ff8a00;
  overflow: hidden;
}

/*BLog Tags*/
.sf-post-tags {
  margin: 30px 0px;
  display: flex;
  justify-content: start;
}

.sf-post-tags h4 {
  margin: 0px 30px 0px 0px;
  padding-top: 10px;
}

@media only screen and (max-width:420px) {
  .sf-post-tags h4 {
    margin-bottom: 5px;
  }
}

.sf-post-tags ul {
  margin: 0px;
  list-style: none;
}

.sf-post-tags ul li {
  display: inline-block;
}

.sf-post-tags li a {
  display: block;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 12px;
  color: #595959;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid #e2e5ec;
  padding: 5px 20px;
}

.sf-pd-sm-media {
  margin: 40px 0px;
}

.sf-pd-sm-media .sf-pd-img {
  border-radius: 12px;
  overflow: hidden;
}

/*Blog post meta icon style*/
.blog-post .post-meta.sf-icon-post-meta ul li:after {
  display: none;
}

.blog-post .post-meta.sf-icon-post-meta ul li i {
  color: #ff8a00;
  margin-right: 10px;
  vertical-align: middle;
}

ol.list-num-count {
  counter-reset: li;
  padding-left: 0;
}

ol.list-num-count>li {
  position: relative;
  margin: 0 0 6px 30px;
  padding: 0px 8px;
  list-style: none;
}

ol.list-num-count>li:before {
  content: counter(li, decimal);
  counter-increment: li;
  position: absolute;
  top: 5px;
  left: -28px;
  width: 28px;
  height: 28px;
  padding: 5px;
  color: #fff;
  background: #ff8a00;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  font-family: arial;
}

.sf-post-tags2 {
  padding: 30px;
  border: 1px solid #ddd;
}

.sf-con-social-wrap2 {
  padding: 30px;
  border: 1px solid #ddd;
}

@media only screen and (max-width:420px) {
  .sf-post-tags2 {
    display: block;
    padding: 20px;
  }
}

/*= comment list = */
.comments-area {
  padding: 0;
  clear: both;
}

.comments-area .comments-title {
  text-transform: uppercase;
  font-size: 20px;
}

ol.comment-list {
  list-style: none;
  margin: 0;
}

ol.comment-list li.comment {
  position: relative;
  padding: 0;
}

ol.comment-list li.comment .comment-body {
  position: relative;
  padding: 15px 20px;
  margin-bottom: 40px;
  margin-left: 80px;
  position: relative;
  border: 1px solid #e9e9e9;
}

ol.comment-list li.comment .comment-body:before,
ol.comment-list li.comment .comment-body:after {
  content: '';
  position: absolute;
  top: 20px;
  width: 0;
  height: 0;
  border-width: 10px 12px 10px 0;
  border-style: solid;
}

ol.comment-list li.comment .comment-body:before {
  border-color: transparent #fff transparent #fff;
  z-index: 1;
  left: -12px;
  top: 22px;
}

ol.comment-list li.comment .comment-body:after {
  border-color: transparent #e9e9e9 transparent #e9e9e9;
  border-width: 12px 14px 12px 0;
  left: -14px;
}

ol.comment-list li.comment .comment-author {
  display: block;
  margin-bottom: 5px;
}

ol.comment-list li.comment .comment-author .avatar {
  position: absolute;
  top: 0;
  left: -80px;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 2px solid #FFF;
}

ol.comment-list li.comment .comment-author .fn {
  display: inline-block;
  color: #555555;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  font-style: normal;
}

ol.comment-list li.comment .comment-author .says {
  display: none;
  color: #999999;
  font-weight: 600;
}

ol.comment-list li.comment .comment-meta {
  color: #8d8d8d;
  text-transform: uppercase;
  margin-bottom: 10px;
}

ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
  font-family: "FontAwesome";
  font-size: 11px;
  vertical-align: middle;
}

ol.comment-list li.comment .comment-meta:before {
  content: "\f133";
}

ol.comment-list li.comment .reply a:before {
  content: "\f064";
  font-weight: normal;
  color: #555555;
  margin-right: 5px;
  vertical-align: middle;
}

ol.comment-list li.comment p {
  margin: 0 0 5px;
}

ol.comment-list li.comment .reply a {
  position: absolute;
  top: 50px;
  right: 30px;
  margin-top: -5px;
  color: #022278;
  font-weight: 600;
}

ol.comment-list li .children {
  list-style: none;
  margin-left: 80px;
}

ol.comment-list li .children li {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .comments-area .padding-30 {
    padding: 15px;
  }

  ol.comment-list li.comment .comment-body {
    margin-bottom: 30px;
    margin-left: 70px;
  }

  ol.comment-list li.comment .comment-author .avatar {
    left: -75px;
    height: 60px;
    width: 60px;
  }

  ol.comment-list li .children {
    margin-left: 20px;
  }

  ol.comment-list li.comment .reply a {
    position: static;
  }
}

@media only screen and (max-width: 480px) {
  ol.comment-list li.comment .comment-body {
    margin-left: 0px;
  }

  ol.comment-list li.comment .comment-author .avatar {
    left: auto;
    top: auto;
    width: 80px;
    height: auto;
    position: inherit;
  }

  ol.comment-list li .children {
    margin-left: 0px;
  }

  ol.comment-list li.comment .comment-author .fn {
    margin-top: 10px;
    display: block;
  }
}


/*================================
BLog Post Comment Section
=================================*/
.sf-blog-comment-wrap .comment-respond {
  padding: 40px;
  border: 0px;
  background: #f6fbf8;
  border: 1px solid #ddd;
  border-radius: 10px;

}

.sf-blog-comment-wrap .comments-area .comment-form p input[type="text"],
.sf-blog-comment-wrap .comments-area .comment-form p textarea {
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 50px;
  background-color: #fff;
  padding: 10px;
}

.sf-blog-comment-wrap .comments-area p:before {
  display: none;
}

.sf-blog-comment-wrap .comments-area .comment-form p textarea {
  height: 190px;
}

.sf-blog-comment-wrap .comments-area .comment-form p.comment-form-author {
  width: 50%;
}

.sf-blog-comment-wrap .comments-area .comment-form p.comment-form-email {
  width: 50%;
}

.sf-blog-comment-wrap .comments-area .comment-form p.comment-form-url {
  width: 100%;
}

.sf-blog-comment-wrap .comments-area .comment-form .sf-btn-large {
  width: auto;
}

.sf-blog-comment-wrap ol.comment-list li.comment .comment-meta::before {
  display: none;
}

.sf-blog-comment-wrap ol.comment-list li.comment .comment-body::before,
.sf-blog-comment-wrap ol.comment-list li.comment .comment-body::after {
  display: none;
}

.sf-blog-comment-wrap ol.comment-list li.comment .comment-author .fn {
  color: #222;
  font-size: 20px;
  text-transform: capitalize;
}

.sf-blog-comment-wrap ol.comment-list li.comment .comment-meta a {
  color: #000;
}

.sf-blog-comment-wrap ol.comment-list li.comment .comment-body {
  padding: 0px;
  margin-left: 130px;
  border: 0px;
}

@media only screen and (max-width:480px) {
  .sf-blog-comment-wrap ol.comment-list li.comment .comment-body {
    margin-left: 0px;
  }
}

.sf-blog-comment-wrap ol.comment-list li.comment .comment-author .avatar {
  left: -130px;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 0px;
}

.sf-blog-comment-wrap ol.comment-list li.comment .reply {
  margin-top: 15px;
}

.sf-blog-comment-wrap ol.comment-list li.comment .reply a {
  position: relative;
  top: 0px;
  right: 0px;
  margin-top: 0px;
  color: #fff;
  font-weight: normal;
  background-color: #ff8a00;
  padding: 3px 30px 3px 15px;
  border-radius: 10px;
  line-height: 0px;
}

.sf-blog-comment-wrap ol.comment-list li.comment .reply a::before {
  content: "\f178";
  font-weight: normal;
  color: #fff;
  margin-right: 10px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.sf-blog-comment-wrap .comments-area .comments-title {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 30px;
}

@media only screen and (max-width:767px) {
  .sf-blog-comment-wrap .comments-area .comments-title {
    font-size: 26px;
  }
}

/*========================
Comment Form 
==========================*/
.comment-respond {
  padding: 30px 30px;
  border: 1px solid #e9e9e9;
}

.comment-respond .comment-reply-title {
  text-transform: uppercase;
  font-size: 20px;
}

.comment-respond .comment-reply-title {
  font-size: 16px;
  font-weight: 600;
}

.comments-area .comment-form {
  margin: 0 -15px;
}

.comments-area .comment-form .comment-notes {
  display: none;
}

.comments-area .comment-form p {
  width: 33.333%;
  float: left;
  padding: 0 15px;
  margin-bottom: 30px;
  position: relative;
}

.comments-area .comment-form p.form-allowed-tags {
  width: 100%;
}

ol.comment-list li.comment .comment-respond .comment-form p {
  padding: 0 15px !important;
}

.comments-area .comment-form p label {
  display: none;
  line-height: 18px;
  margin-bottom: 10px;
}

.comments-area p:before {
  font-family: "FontAwesome";
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 0;
  font-size: 16px;
  color: #ccc;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #e1e1e1;
  text-align: center;
}

.comments-area p.comment-form-author:before {
  content: "\f007";
}

.comments-area p.comment-form-email:before {
  content: "\f0e0";
}

.comments-area p.comment-form-url:before {
  content: "\f0ac";
}

.comments-area p.comment-form-comment:before {
  content: "\f040";
}

.comments-area .comment-form p input[type="text"],
.comments-area .comment-form p textarea {
  width: 100%;
  height: 40px;
  line-height: 1.2;
  padding: 10px 10px 10px 50px;
  border: 1px solid #e1e1e1;
}

.comments-area .comment-form p.comment-form-comment {
  width: 100%;
  display: block;
  clear: both;
}

.comments-area .comment-form p textarea {
  height: 120px;
}

.comments-area .comment-form p.form-submit {
  clear: both;
  float: none;
  width: 100%;
  margin: 0;
}

.comments-area .comment-form p input[type="submit"] {
  background-color: #022278;
  border-color: #1f82bd;
  border-width: 0;
  border-bottom-width: 3px;
  border-style: solid;
  color: #fff;
  padding: 9px 20px;
  line-height: 1.42857;
  text-transform: uppercase;
}

.comments-area .comment-form p input[type="submit"]:hover,
.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active {
  background-color: #1f82bd;
  border-color: #0c6faa;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-form p {
    width: 100% !important;
    float: none;
    margin-bottom: 20px;
  }

  .comment-respond {
    padding: 20px;
  }
}









.aon-login-wrap {
  min-height: 100vh;
}

.aon-login-left {
  background: #ddd;
  background-image: url("../images/banner2/bg.jpg");
  background-size: cover;
  background-position: right center;
  width: calc(100% - 600px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.aon-login-heading {
  max-width: 1050px;
  margin: 0px 30px;
  display: flex;
  flex-wrap: wrap;
  border: 10px solid #ff8a00;
  align-items: center;
}

.aon-login-heading .aon-login-text,
.aon-login-heading .aon-login-pic {
  width: 50%;
  display: flex;
}

.aon-login-heading .aon-login-pic img {
  margin-top: -100px;
}

.aon-login-heading .aon-login-text {
  padding: 30px;
  font-size: 58px;
  line-height: 1.5;
  font-weight: 800;
  color: #fff;
  display: flex;
  align-items: center;
}

.aon-login-heading .aon-login-pic {
  display: flex;
  align-items: flex-end;
}


.login-sign-logo {
  display: table;
  margin: 0px auto;
}

.login-sign-head {
  text-align: center;
  padding: 30px 0px 30px;
}

.login-sign-head strong {
  font-size: 50px;
  line-height: 60px;
  display: block;
  color: #000;
}

.login-sign-head span {
  font-size: 18px;
  display: block;
  color: #777;
  letter-spacing: 2px;
}

.aon-login-right {
  width: 600px;
  background: #fff;
  padding-top: 30px;
}

.aon-login-form {
  width: 100%;
  padding: 30px;
}

@media only screen and (max-width:1550px) {
  .aon-login-heading .aon-login-text {
    font-size: 50px;
  }
}

@media only screen and (max-width:1440px) {
  .aon-login-left {
    width: calc(100% - 500px);
  }

  .aon-login-right {
    width: 500px;
  }
}

@media only screen and (max-width:1366px) {
  .aon-login-heading .aon-login-text {
    font-size: 40px;
  }
}

@media only screen and (max-width:1200px) {
  .aon-login-heading .aon-login-text {
    font-size: 30px;
  }
}


@media only screen and (max-width:1199px) {
  .aon-login-left {
    background-position: 75%;
  }
}

@media only screen and (max-width:1100px) {
  .aon-login-heading .aon-login-text {
    font-size: 22px;
  }
}

@media only screen and (max-width:991px) {
  .aon-login-heading .aon-login-pic img {
    margin-top: 0;
  }

  .aon-login-heading {
    max-width: 90%;
    margin: 5%;
  }

  .aon-login-wrap {
    display: block !important;
  }

  .aon-login-heading .aon-login-text {
    font-size: 50px;
  }

  .aon-login-left {
    background-position: bottom right;
    width: 100%;
    margin-bottom: 30px;
  }

  .aon-login-right {
    width: 100%;
  }

  .login-sign-head strong {
    font-size: 30px;
  }
}

@media only screen and (max-width:850px) {
  .aon-login-heading .aon-login-text {
    font-size: 40px;
  }
}


@media only screen and (max-width:767px) {
  .login-sign-head strong {
    font-size: 26px;
  }

  .aon-login-heading .aon-login-text {
    font-size: 25px;
  }
}

@media only screen and (max-width:767px) {

  .aon-login-heading .aon-login-text,
  .aon-login-heading .aon-login-pic {
    width: 100%;
  }

  .aon-login-heading .aon-login-text {
    font-size: 30px;
    text-align: center;
  }
}



@media only screen and (max-width:991px) {
  .aon-login-area .aon-custom-nav {
    display: flex;
  }
}








/* My Account Responsive */
.aon-admin-heading {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.aon-admin-heading h3 {
  margin-bottom: 10px;
}

@media only screen and (max-width:1199px) {
  .card-cost-section-block ul li {
    width: 33.33%;
  }
}

@media only screen and (max-width:991px) {
  .sf-upload-control [class*='col-'] {
    margin-bottom: 10px;
  }

  .partners-info-wrap .row [class*="col-"] .partners-info {
    padding: 15px 50px;
  }

  .card-custom-left {
    margin-bottom: 30px;
    display: block;
    padding-left: 0px;
    padding-right: 0px;
  }

  .card-custom-area {
    display: block;
  }

  .sf-appoint-doc-wrap {
    margin-bottom: 30px;
  }

  .card-reg-form-lr-wrap {
    display: block;
  }

  .card-reg-form-left {
    max-width: 100%;
  }
}

@media only screen and (max-width:768px) {
  .card-custom-left {
    margin: 0px auto;
    text-align: center;
  }

  .card-custom-left .admin-button {
    margin-top: 30px;
  }

  .card-custom-left .aon-pro-avtar-pic {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width:767px) {

  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: left !important;
  }

  div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
    padding-right: 0;
    padding-left: 0px;
  }
}

@media only screen and (max-width:540px) {
  .card-cost-section-block ul li {
    width: 50%;
  }
}

@media only screen and (max-width:460px) {
  .aon-feedback-list {
    margin-left: 30px;
  }

  .aon-feedback-date {
    position: relative;
    left: 0;
    margin-bottom: 30px;
    display: inline-block;
  }

  .aon-feedback-date::after {
    left: auto;
    right: 100%;
    top: 41px;
    border-width: 7px 12px 7px 0px;
    border-color: transparent #ff8a00 transparent transparent;
  }


  .aon-admin-heading h3 {
    font-size: 20px;
  }

  .aon-feedback-list>li::before {
    top: 70px;
  }
}

@media only screen and (max-width:375px) {
  .card-cost-section-block ul li {
    width: 100%;
  }
}

@media only screen and (max-width:360px) {
  .aon-pro-team-pic {
    width: 100%;
    height: auto;
  }
}








.side-bar .widget {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.side-bar .widget .widget-title {
  position: relative;
  padding-bottom: 15px;
}

.side-bar .widget .widget-title::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #ff8a00;
  width: 50px;
  height: 3px;
}

.side-bar .widget .form-control {
  background-color: #f9f9f9;
}

.side-bar .search-bx .input-group-btn .btn {
  background-color: #ff8a00;
  color: #fff;
  border-radius: 0px;
  height: 50px;
  padding: 0px 20px;
}

.side-bar .widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  background-color: #fff;
}

.side-bar .widget_services ul li {
  position: relative;
  padding: 10px 0px 10px 15px;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e7e7e7;
}

.side-bar .widget_services ul li::after {

  content: '\f105';
  font-family: 'FontAwesome';
  color: #ff8a00;
  position: absolute;
  left: 0px;
  top: 10px;

}

.side-bar .widget_services ul li a {
  color: #000;
  margin-left: 0px;
  padding: 0px;
  display: inline-block;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.side-bar .widget_services ul li .badge {
  background: none;
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  font-size: 12px;
  font-weight: 600;
  padding: 7px;
  background-color: #e7e7e7;
}



.side-bar .widget_archives ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  background-color: #fff;
}

.side-bar .widget_archives ul li {
  position: relative;
  padding: 10px 0px 10px 15px;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.side-bar .widget_archives ul li a {
  color: #000;
  margin-left: 0px;
  padding: 0px;
  display: inline-block;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.side-bar .widget_archives ul li::after {
  content: '';
  width: 4px;
  height: 5px;
  border-radius: 50%;
  background-color: #ff8a00;
  position: absolute;
  left: 0px;
  top: 17px;
}

.shop-item-price-slider {
  display: flex;
  white-space: nowrap;
}

.shop-item-price-slider .slider.slider-horizontal {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.shop-item-price-slider {
  white-space: nowrap;
}

.shop-item-price-slider .slider.slider-horizontal .slider-track {
  height: 8px;
  background-color: #ff8a00;
  background-image: none;
  margin-top: 0px;
  margin-left: 0px;
}

.shop-item-price-slider .slider.slider-horizontal .slider-handle {
  background-color: #ff8a00;
  background-image: none;
  width: 15px;
  height: 15px;
  top: 7px;
  margin-left: -5px;
}

@media only screen and (max-width:991px) {
  .single-product-title .post-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .single-product-title .post-title {
    font-size: 26px;
  }
}

.quantity.btn-quantity.m-b20 {
  max-width: 130px;
  display: inline-block;
  margin-right: 20px;
}

.gallery-posts-widget {
  margin-bottom: -16px;
}

.wt-product-gallery .product-view {
  position: absolute;
  top: 10px;
  right: 10px;
}

.wt-product-gallery .product-view .product-view-btn {
  background-color: #fff;
  color: #000;
  font-size: 22px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: block;
  text-align: center;
}

#sync1 {
  border: 1px solid #ddd;
  margin-bottom: 13px;
}

#sync2 .item {
  border: 1px solid #ddd;
}




/* This CSS file is unnecessary if you are not using vertical buttons functionality */
.bootstrap-touchspin .input-group-btn-vertical {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 11;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
  position: absolute;
  right: 0;
  height: 50%;
  padding: 0;
  width: 2em;
  text-align: center;
  line-height: 1;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 4px 0 0;
  top: 0;
  background: #fff;
  border-color: #ddd;
  color: #777;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 4px 0 0;
  bottom: 0;
  background: #fff;
  border-color: #ddd;
  color: #777;
}


.aon-booking-wrap {
  min-height: 100vh;
  padding: 30px 0px;
}

.aon-booking-form {
  border: 1px solid #ddd;
  padding: 30px;
  max-width: 700px;
  border-radius: 10px;
  position: relative;
}

@media only screen and (max-width:991px) {
  .aon-booking-form .aon-form-title {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {
  .aon-booking-form .aon-form-title {
    font-size: 26px;
  }
}

@media only screen and (max-width:420px) {
  .aon-booking-form .sf-radio-row {
    display: block !important;
  }

  .aon-booking-form .sf-radio-row .sf-radio-checkbox {
    width: 100% !important;
    margin-bottom: -1px;
  }

  .aon-booking-form .aon-form-top {
    display: block !important;
  }

  .aon-booking-form .aon-form-top-left {
    display: block !important;
  }
}

.sf-radio-row .checkbox {
  padding: 14px 15px;
  border: 1px solid #e8edf1;
  margin: 0px -0.5px;
}

.sf-radio-row .checkbox label {
  margin: 0px;
}

.sf-radio-row .checkbox:first-child {
  border-radius: 5px 0px 0px 5px;
}

.sf-radio-row .checkbox:last-child {
  border-radius: 0px 5px 5px 0px;
}

@media only screen and (max-width:420px) {
  .sf-radio-row {
    display: block !important;
  }

  .sf-radio-row .sf-radio-checkbox {
    width: 100% !important;
    margin-bottom: -0.5px;
  }

  .sf-radio-row .checkbox:first-child {
    border-radius: 5px 5px 0px 0px;
  }

  .sf-radio-row .checkbox:last-child {
    border-radius: 0px 0px 5px 5px;
  }
}


.aon-book-plan {
  list-style: none;
  margin: 0px;
  width: 100%;
}

.aon-book-plan li {
  background: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
}

.aon-book-plan li:last-child {
  margin-bottom: 0px;
}

.aon-book-plan li strong {
  padding-left: 10px;
  color: #222;
  display: block;
}

.aon-book-plan li p {
  padding-left: 10px;
  color: #777;
}


.aon-form-logo {
  display: table;
  margin: auto;
}

.aon-form-top {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 30px 0px 20px;
  border-radius: 5px;
  background: #f4f5f9;
}

.btn-back-to-home {
  position: absolute;
  top: 30px;
  right: 30px;
  line-height: 20px;
  border: 1px solid #ff8a00;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}

.btn-back-to-home:hover,
.btn-back-to-home:focus,
.btn-back-to-home:active {
  background: #ff8a00;
  color: #fff;
}

.aon-form-doc-pic {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 15px;
}

.aon-form-doc-pic {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
}

.aon-form-doc-info strong,
.aon-form-doc-info span {
  display: block;
}

.aon-form-doc-info strong {
  color: #222;
}

.aon-form-doc-info span {
  color: #777;
  font-size: 14px;
}

.aon-form-consul-free {
  text-align: right;
}

.aon-form-consul-free>strong,
.aon-form-consul-free>span {
  display: block;
}

.aon-form-consul-free>strong {
  color: #222;
}

.aon-form-consul-free>span {
  color: #ff8a00;
}

.aon-form-back-btn {
  background: none;
  padding: 0px;
  color: #ff8a00;
  border: none;
}

@media only screen and (max-width:768px) {
  .aon-form-logo {
    float: left;
    width: 120px;
    margin: 0;
  }

  .btn-back-to-home {
    padding: 2px 10px;
    font-size: 12px;
    top: 15px;
    right: 15px;
  }

  .aon-booking-form {
    padding: 15px;
  }
}

.aon-admin-footer {
  position: fixed;
  z-index: 9;
  background: #fff;
  border-top: 1px solid #ddd;
  bottom: 0px;
  right: 0px;
  width: calc(100% - 250px);
  padding: 10px 15px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.active .aon-admin-footer {
  width: calc(100% - 0px);
}

.aon-admin-footer .alert-success {
  padding: 3px 15px;
  margin: 0px;
  font-size: 14px;
  border-color: #329366;
  background: none;
  color: #329366;
}

@media only screen and (max-width:768px) {
  .aon-admin-footer {
    width: calc(100% - 0px);
  }

  .active .aon-admin-footer {
    width: calc(100% - 250px);
  }

  .cus-account-wrap .aon-admin-footer {
    border: 1px solid #e6ebf1;
    border-radius: 10px;
    padding: 15px 15px;
    text-align: center;
    position: static;
    display: block !important;
  }

  .aon-admin-footer-left {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width:767px) {
  .aon-admin-footer {
    border: 1px solid #e6ebf1;
    border-radius: 10px;
    padding: 15px 15px;
    text-align: center;
    position: static;
    display: block !important;
  }
}

@media only screen and (max-width:575px) {
  .aon-admin-footer .alert-success {
    margin-right: 10px;
  }

  .aon-admin-footer .aon-admin-footer-right .admin-button {
    margin-bottom: 3px;
  }
}


.table>tbody>tr {
  background: #f3f5f6 !important;
}

.table>tbody>tr:after {
  content: "";
  height: 10px;
  display: table;
  width: 100%;
}

.aon-table-custom>tbody>tr>td {
  border-bottom: 10px solid #f8f9fb;
}

.aon-table-custom>tbody>tr>td:hover {
  border-bottom: 10px solid #f4f5f6;
}

div.dataTables_wrapper div.dataTables_length select {
  background: #f3f5f6;
  min-width: 250px;
}

div.dataTables_wrapper div.dataTables_filter input {
  background: #f3f5f6;
  min-width: 250px;
}

.aon-table-custom>thead>tr>th {
  border-bottom: 1px solid #fff !important;
  border-top: 1px solid #fff !important;
}


.btn-pending {
  padding: 3px 10px;
  border: 1px solid #ff8a00;
  color: #ff8a00;
  border-radius: 5px;
}

.btn-cancelled {
  padding: 3px 10px;
  border: 1px solid #f04f53;
  color: #f04f53;
  border-radius: 5px;
}

.btn-complete {
  padding: 3px 10px;
  border: 1px solid #89cc46;
  color: #89cc46;
  border-radius: 5px;
}


/* Animate Up Down*/
.animate-v {
  -webkit-animation: upNdown1 5s infinite linear;
  animation: upNdown1 2s infinite linear;
}

@-webkit-keyframes upNdown1 {
  0% {}

  50% {
    transform: translateY(-30px);
  }

  100% {}
}

@keyframes upNdown1 {
  0% {}

  50% {
    transform: translateY(30px);
  }

  100% {}
}



.animate-curve:after {
  -webkit-animation: upNdown2 5s infinite linear;
  animation: upNdown2 2s infinite linear;
}

@-webkit-keyframes upNdown2 {
  0% {}

  50% {
    transform: translateY(-10px);
  }

  100% {}
}

@keyframes upNdown2 {
  0% {}

  50% {
    transform: translateY(10px);
  }

  100% {}
}



.animate-curve:after {
  -webkit-animation: upNdown2 5s infinite linear;
  animation: upNdown2 2s infinite linear;
}

@-webkit-keyframes upNdown2 {
  0% {}

  50% {
    transform: translateY(-10px);
  }

  100% {}
}

@keyframes upNdown2 {
  0% {}

  50% {
    transform: translateY(10px);
  }

  100% {}
}


/* Animate Up Down 2*/
.animate-v2 {
  -webkit-animation: upNdown11 5s infinite linear;
  animation: upNdown11 2s infinite linear;
}

@-webkit-keyframes upNdown11 {
  0% {}

  50% {
    transform: translateY(-5px);
  }

  100% {}
}

@keyframes upNdown11 {
  0% {}

  50% {
    transform: translateY(5px);
  }

  100% {}
}

/* Animate Up Down 2*/
.animate-v3 {
  -webkit-animation: upNdown12 5s infinite linear;
  animation: upNdown12 2s infinite linear;
}

@-webkit-keyframes upNdown12 {
  0% {}

  50% {
    transform: translateY(5px);
  }

  100% {}
}

@keyframes upNdown12 {
  0% {}

  50% {
    transform: translateY(-5px);
  }

  100% {}
}



/* Shine Animation*/

.shine-hover .shine-box {
  position: relative;
  overflow: hidden;
}

.shine-hover .shine-box::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.shine-hover .shine-box:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}


/* Roted Image */
.zoom-box:hover {
  -moz-transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -o-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}


/* Icon Verticle Animation */
.aon-icon {
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  display: block;
  margin: auto;
}



.aon-icon-effect:hover .aon-icon {
  -webkit-animation: toTopFromBottom 0.3s forwards;
  -moz-animation: toTopFromBottom 0.3s forwards;
  animation: toTopFromBottom 0.3s forwards;
}



@-webkit-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@-moz-keyframes toTopFromBottom {
  49% {
    -moz-transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes toTopFromBottom {
  49% {
    transform: translateY(-100%);
  }

  50% {
    opacity: 0;
    transform: translateY(100%);
  }

  51% {
    opacity: 1;
  }
}


#multistep_form fieldset:not(:first-of-type) {
  display: none;
}





.page-logo img {
  max-height: 80px;
}


@media only screen and (max-width:991px) {
  .section-full.p-t120.p-b90 {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width:460px) {
  .product-filter-wrap {
    display: block !important;
  }

  .product-filter-wrap .woocommerce-result-count {
    margin-bottom: 10px;
    display: block;
  }
}



#sf-provider-info .provider-social {
  margin: 0 -5px;
}

.availibility-start .form-group,
.availibility-end .form-group {
  position: relative;
}

.availibility-list {
  margin: 0px;
}

.availibility-list li {
  position: relative;
}

@media only screen and (max-width:991px) {

  .availibility-list .availibility-start,
  .availibility-list .availibility-end {
    width: 100%;
  }
}

@media only screen and (max-width:480px) {
  .aon-availibility-right .aon-availibility-edit {
    display: block;
    margin-bottom: 3px;
  }
}

.availibility-edit {
  position: absolute;
  top: 8px;
  right: 4px;
  display: table;
  width: 180px;
  height: 32px;
}

.availibility-edit>div {
  display: table-cell;
  padding: 0px 4px;
  vertical-align: middle;
}

.availibility-time-select {
  width: 100px;
}

.availibility-time-select .form-control {
  height: 32px;
  background: #f0efef;
}

.btn-time-zone {
  background: #FFF;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 32px;
  font-size: 14px;
  padding: 0px 10px;
  color: #777;
}

.btn-time-remove {
  position: absolute;
  top: 0px;
  left: 12px;
  background: #FFF;
  border: 1px solid #ea4e4e;
  border-radius: 20px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  padding: 0px;
  color: #ea4e4e;
  margin: 16px -9px;
  z-index: 4;
}

.btn-time-remove:hover {
  background: #ea4e4e;
  border: 1px solid #ea4e4e;
  color: #fff;
}

.active-zone .btn-time-zone {
  border: 1px solid #ff8a00;
  color: #ff8a00;
}

.aon-availibility-edit {
  color: #000;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #777;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 5px;
}

.aon-avai-edit-btn {
  color: #ff8a00;
  cursor: pointer;
}

.aon-availibility-body {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.add-another-time {
  border: 2px dashed #09358c;
  color: #09358c;
  text-align: center;
  margin-bottom: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}


.custom-schedule-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  display: table;
  width: 100%;
  margin-bottom: 30px;
}

.custom-schedule-box>div {
  display: table-cell;
  vertical-align: middle;
}

.custom-schedule-date {
  width: 100px;
  border: 1px solid #ff8a00;
  font-size: 40px;
  border-radius: 10px;
  text-align: center;
  padding: 15px;
  position: relative;
}

.custom-schedule-date strong {
  font-size: 50px;
  line-height: 50px;
  display: block;
  color: #ff8a00;
}

.custom-schedule-date span {
  font-size: 20px;
  line-height: 20px;
  display: block;
  color: #999;
}

.custom-schedule-date .schedule-date-trash {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  padding: 0px;
  color: #777;
  cursor: pointer;
}

.custom-schedule-date .schedule-date-edit {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  padding: 0px;
  color: #777;
  cursor: pointer;
}

.custom-schedule-time {
  padding-left: 20px;
}

.custom-schedule-time ul {
  list-style: none;
  margin: 0px;
}

.custom-schedule-time ul li {
  display: inline-block;
  cursor: pointer;
  background: #ff8a00;
  border: 1px solid #ff8a00;
  color: #fff;
  border-radius: 5px;
  padding: 3px 15px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.custom-schedule-time ul li:hover {
  background: #ff8a00;
  color: #fff;
}

@media only screen and (max-width:380px) {
  .custom-schedule-box {
    display: block;
  }

  .custom-schedule-box>div {
    display: block;
  }

  .custom-schedule-time {
    padding-left: 0px;
  }

  .custom-schedule-time ul li {
    display: block;
    margin-right: 0px;
  }
}

.custom-schedule-year {
  margin-bottom: 30px;
  position: relative;
}

.custom-schedule-year strong {
  display: inline-block;
  background: #09358c;
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.custom-schedule-year:after {
  content: "";
  position: absolute;
  top: 18px;
  left: 0px;
  height: 2px;
  background: #ddd;
  width: 100%;
}

.active-availibility-slide .cs-message-panel {
  right: 0px;
}

.edit-time-title {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.edit-time-lists {
  list-style: none;
  margin: 0px;
}

.edit-time-lists li {
  display: inline-block;
  display: inline-block;
  padding: 3px 15px;
  border: 1px solid #ff8a00;
  background: #ff8a00;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.edit-time-hide {
  opacity: 0.5;
  cursor: not-allowed;
}

.holiday-date-list {
  list-style: none;
}

.holiday-date-list li {
  display: inline-flex;
  margin-right: 10px;
  margin-bottom: 3px;
  position: relative;
}

.holiday-off-date {
  width: 100px;
  border: 1px solid #ff8a00;
  font-size: 40px;
  border-radius: 10px;
  text-align: center;
  padding: 15px;
}

.holiday-off-date strong {
  font-size: 50px;
  line-height: 50px;
  display: block;
  color: #ff8a00;
}

.holiday-off-date span {
  font-size: 20px;
  line-height: 20px;
  display: block;
  color: #999;
}

.holiday-off-date i {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  padding: 0px;
  color: #777;
  cursor: pointer;
}

.add-holiday-date {
  border: 2px dashed #09358c;
  color: #09358c;
  padding: 10px 10px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.add-holiday-date i {
  margin-right: 5px;
}

.aon-availibility-left {
  border-right: 1px solid #ddd;
  padding-right: 15px;
  min-width: 120px;
}

.aon-login-sign-tabs {
  margin: 0px 30px;
}

.aon-login-sign-tabs li {
  width: 50% !important;
}

.header-style-1 .aon-btn-signup {
  color: #fff;
}

.header-style-2 .aon-btn-signup {
  color: #000;
}

.header-style-3 .aon-btn-signup {
  color: #fff;
}

.header-style-1 .color-fill .aon-btn-signup {
  color: #000;
}

.header-style-3 .color-fill .aon-btn-signup {
  color: #000;
}

@media only screen and (max-width:767px) {
  .header-style-1 .color-fill .aon-btn-signup {
    color: #fff;
  }

  .header-style-2 .aon-btn-signup {
    color: #fff;
  }

  .header-style-3 .color-fill .aon-btn-signup {
    color: #fff;
  }
}

.sf-map-media .aon-search-wh-full>iframe {
  width: 100%;
  height: 700px;
}

.logo-pic-two {
  display: none;
}

.color-fill .logo-pic-two {
  display: block;
}

.color-fill .logo-pic-one {
  display: none;
}






/*-------------------------------------

	 Loading 

-------------------------------------*/





/*loading - 1*/
.loading-area {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: hidden;
}



.loading-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 9999;
}



.loading-pic {

  width: 100%;

  position: absolute;

  top: 50%;

  z-index: 99999;

  text-align: center;

  -webkit-transform: translateY(-50%);

  -moz-transform: translateY(-50%);

  -ms-transform: translateY(-50%);

  transform: translateY(-50%);

}







.windows8 {

  position: relative;

  width: 78px;

  height: 78px;

  margin: auto;

}



.windows8 .wBall {

  position: absolute;

  width: 74px;

  height: 74px;

  opacity: 0;

  transform: rotate(225deg);

  -o-transform: rotate(225deg);

  -ms-transform: rotate(225deg);

  -webkit-transform: rotate(225deg);

  -moz-transform: rotate(225deg);

  animation: orbit 6.96s infinite;

  -o-animation: orbit 6.96s infinite;

  -ms-animation: orbit 6.96s infinite;

  -webkit-animation: orbit 6.96s infinite;

  -moz-animation: orbit 6.96s infinite;

}



.windows8 .wBall .wInnerBall {

  position: absolute;

  width: 10px;

  height: 10px;

  background: rgb(0, 0, 0);

  left: 0px;

  top: 0px;

  border-radius: 10px;

}



.windows8 #wBall_1 {

  animation-delay: 1.52s;

  -o-animation-delay: 1.52s;

  -ms-animation-delay: 1.52s;

  -webkit-animation-delay: 1.52s;

  -moz-animation-delay: 1.52s;

}



.windows8 #wBall_2 {

  animation-delay: 0.3s;

  -o-animation-delay: 0.3s;

  -ms-animation-delay: 0.3s;

  -webkit-animation-delay: 0.3s;

  -moz-animation-delay: 0.3s;

}



.windows8 #wBall_3 {

  animation-delay: 0.61s;

  -o-animation-delay: 0.61s;

  -ms-animation-delay: 0.61s;

  -webkit-animation-delay: 0.61s;

  -moz-animation-delay: 0.61s;

}



.windows8 #wBall_4 {

  animation-delay: 0.91s;

  -o-animation-delay: 0.91s;

  -ms-animation-delay: 0.91s;

  -webkit-animation-delay: 0.91s;

  -moz-animation-delay: 0.91s;

}



.windows8 #wBall_5 {

  animation-delay: 1.22s;

  -o-animation-delay: 1.22s;

  -ms-animation-delay: 1.22s;

  -webkit-animation-delay: 1.22s;

  -moz-animation-delay: 1.22s;

}







@keyframes orbit {

  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }



  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
  }

  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
  }

  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
  }

  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
  }

  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
  }

  76% {
    opacity: 0;
    transform: rotate(900deg);
  }



  100% {
    opacity: 0;
    transform: rotate(900deg);
  }
}



@-o-keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    -o-transform: rotate(180deg);
    -o-animation-timing-function: ease-out;
  }



  7% {

    opacity: 1;

    -o-transform: rotate(300deg);

    -o-animation-timing-function: linear;

    -o-origin: 0%;

  }



  30% {

    opacity: 1;

    -o-transform: rotate(410deg);

    -o-animation-timing-function: ease-in-out;

    -o-origin: 7%;

  }



  39% {

    opacity: 1;

    -o-transform: rotate(645deg);

    -o-animation-timing-function: linear;

    -o-origin: 30%;

  }



  70% {

    opacity: 1;

    -o-transform: rotate(770deg);

    -o-animation-timing-function: ease-out;

    -o-origin: 39%;

  }



  75% {

    opacity: 1;

    -o-transform: rotate(900deg);

    -o-animation-timing-function: ease-out;

    -o-origin: 70%;

  }



  76% {

    opacity: 0;

    -o-transform: rotate(900deg);

  }



  100% {

    opacity: 0;

    -o-transform: rotate(900deg);

  }

}



@-ms-keyframes orbit {

  0% {

    opacity: 1;

    z-index: 99;

    -ms-transform: rotate(180deg);

    -ms-animation-timing-function: ease-out;

  }



  7% {

    opacity: 1;

    -ms-transform: rotate(300deg);

    -ms-animation-timing-function: linear;

    -ms-origin: 0%;

  }



  30% {

    opacity: 1;

    -ms-transform: rotate(410deg);

    -ms-animation-timing-function: ease-in-out;

    -ms-origin: 7%;

  }



  39% {

    opacity: 1;

    -ms-transform: rotate(645deg);

    -ms-animation-timing-function: linear;

    -ms-origin: 30%;

  }



  70% {

    opacity: 1;

    -ms-transform: rotate(770deg);

    -ms-animation-timing-function: ease-out;

    -ms-origin: 39%;

  }



  75% {

    opacity: 1;

    -ms-transform: rotate(900deg);

    -ms-animation-timing-function: ease-out;

    -ms-origin: 70%;

  }



  76% {

    opacity: 0;

    -ms-transform: rotate(900deg);

  }



  100% {

    opacity: 0;

    -ms-transform: rotate(900deg);

  }

}



@-webkit-keyframes orbit {

  0% {

    opacity: 1;

    z-index: 99;

    -webkit-transform: rotate(180deg);

    -webkit-animation-timing-function: ease-out;

  }



  7% {

    opacity: 1;

    -webkit-transform: rotate(300deg);

    -webkit-animation-timing-function: linear;

    -webkit-origin: 0%;

  }



  30% {

    opacity: 1;

    -webkit-transform: rotate(410deg);

    -webkit-animation-timing-function: ease-in-out;

    -webkit-origin: 7%;

  }



  39% {

    opacity: 1;

    -webkit-transform: rotate(645deg);

    -webkit-animation-timing-function: linear;

    -webkit-origin: 30%;

  }



  70% {

    opacity: 1;

    -webkit-transform: rotate(770deg);

    -webkit-animation-timing-function: ease-out;

    -webkit-origin: 39%;

  }



  75% {

    opacity: 1;

    -webkit-transform: rotate(900deg);

    -webkit-animation-timing-function: ease-out;

    -webkit-origin: 70%;

  }



  76% {

    opacity: 0;

    -webkit-transform: rotate(900deg);

  }



  100% {

    opacity: 0;

    -webkit-transform: rotate(900deg);

  }

}



@-moz-keyframes orbit {

  0% {

    opacity: 1;

    z-index: 99;

    -moz-transform: rotate(180deg);

    -moz-animation-timing-function: ease-out;

  }



  7% {

    opacity: 1;

    -moz-transform: rotate(300deg);

    -moz-animation-timing-function: linear;

    -moz-origin: 0%;

  }



  30% {

    opacity: 1;

    -moz-transform: rotate(410deg);

    -moz-animation-timing-function: ease-in-out;

    -moz-origin: 7%;

  }



  39% {

    opacity: 1;

    -moz-transform: rotate(645deg);

    -moz-animation-timing-function: linear;

    -moz-origin: 30%;

  }



  70% {

    opacity: 1;

    -moz-transform: rotate(770deg);

    -moz-animation-timing-function: ease-out;

    -moz-origin: 39%;

  }



  75% {

    opacity: 1;

    -moz-transform: rotate(900deg);

    -moz-animation-timing-function: ease-out;

    -moz-origin: 70%;

  }



  76% {

    opacity: 0;

    -moz-transform: rotate(900deg);

  }



  100% {

    opacity: 0;

    -moz-transform: rotate(900deg);

  }

}




/*Site Loader CSS*/
.cssload-wrap {
  text-align: center;
  line-height: 225px;
}

.cssload-container {
  display: inline-block;
}

.cssload-dots {
  display: inline-block;
  position: relative;
}

.cssload-dots:not(:last-child) {
  margin-right: 10px;
}

.cssload-dots:before,
.cssload-dots:after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
}

.cssload-dots:nth-child(1):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -1.04s;
  -o-animation-delay: -1.04s;
  -ms-animation-delay: -1.04s;
  -webkit-animation-delay: -1.04s;
  -moz-animation-delay: -1.04s;
  background-color: rgb(255, 136, 0);
}

.cssload-dots:nth-child(1):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -1.04s;
  -o-animation-delay: -1.04s;
  -ms-animation-delay: -1.04s;
  -webkit-animation-delay: -1.04s;
  -moz-animation-delay: -1.04s;
  background-color: rgb(44, 108, 94);
}

.cssload-dots:nth-child(2):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -2.07s;
  -o-animation-delay: -2.07s;
  -ms-animation-delay: -2.07s;
  -webkit-animation-delay: -2.07s;
  -moz-animation-delay: -2.07s;
  background-color: rgb(255, 136, 0);
}

.cssload-dots:nth-child(2):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -2.07s;
  -o-animation-delay: -2.07s;
  -ms-animation-delay: -2.07s;
  -webkit-animation-delay: -2.07s;
  -moz-animation-delay: -2.07s;
  background-color: rgb(44, 108, 94);
}

.cssload-dots:nth-child(3):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -3.11s;
  -o-animation-delay: -3.11s;
  -ms-animation-delay: -3.11s;
  -webkit-animation-delay: -3.11s;
  -moz-animation-delay: -3.11s;
  background-color: rgb(255, 136, 0);
}

.cssload-dots:nth-child(3):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -3.11s;
  -o-animation-delay: -3.11s;
  -ms-animation-delay: -3.11s;
  -webkit-animation-delay: -3.11s;
  -moz-animation-delay: -3.11s;
  background-color: rgb(44, 108, 94);
}

.cssload-dots:nth-child(4):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -4.14s;
  -o-animation-delay: -4.14s;
  -ms-animation-delay: -4.14s;
  -webkit-animation-delay: -4.14s;
  -moz-animation-delay: -4.14s;
  background-color: rgb(255, 136, 0);
}

.cssload-dots:nth-child(4):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -4.14s;
  -o-animation-delay: -4.14s;
  -ms-animation-delay: -4.14s;
  -webkit-animation-delay: -4.14s;
  -moz-animation-delay: -4.14s;
  background-color: rgb(44, 108, 94);
}

.cssload-dots:nth-child(5):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -5.18s;
  -o-animation-delay: -5.18s;
  -ms-animation-delay: -5.18s;
  -webkit-animation-delay: -5.18s;
  -moz-animation-delay: -5.18s;
  background-color: rgb(255, 136, 0);
}

.cssload-dots:nth-child(5):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -5.18s;
  -o-animation-delay: -5.18s;
  -ms-animation-delay: -5.18s;
  -webkit-animation-delay: -5.18s;
  -moz-animation-delay: -5.18s;
  background-color: rgb(44, 108, 94);
}

.cssload-dots:nth-child(6):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -6.21s;
  -o-animation-delay: -6.21s;
  -ms-animation-delay: -6.21s;
  -webkit-animation-delay: -6.21s;
  -moz-animation-delay: -6.21s;
  background-color: rgb(255, 136, 0);
}

.cssload-dots:nth-child(6):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -6.21s;
  -o-animation-delay: -6.21s;
  -ms-animation-delay: -6.21s;
  -webkit-animation-delay: -6.21s;
  -moz-animation-delay: -6.21s;
  background-color: rgb(44, 108, 94);
}

.cssload-dots:nth-child(7):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -7.25s;
  -o-animation-delay: -7.25s;
  -ms-animation-delay: -7.25s;
  -webkit-animation-delay: -7.25s;
  -moz-animation-delay: -7.25s;
  background-color: rgb(255, 136, 0);
}

.cssload-dots:nth-child(7):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -7.25s;
  -o-animation-delay: -7.25s;
  -ms-animation-delay: -7.25s;
  -webkit-animation-delay: -7.25s;
  -moz-animation-delay: -7.25s;
  background-color: rgb(44, 108, 94);
}

.cssload-dots:nth-child(8):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -8.28s;
  -o-animation-delay: -8.28s;
  -ms-animation-delay: -8.28s;
  -webkit-animation-delay: -8.28s;
  -moz-animation-delay: -8.28s;
  background-color: rgb(255, 136, 0);
}

.cssload-dots:nth-child(8):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -8.28s;
  -o-animation-delay: -8.28s;
  -ms-animation-delay: -8.28s;
  -webkit-animation-delay: -8.28s;
  -moz-animation-delay: -8.28s;
  background-color: rgb(44, 108, 94);
}

.cssload-dots:nth-child(9):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -9.32s;
  -o-animation-delay: -9.32s;
  -ms-animation-delay: -9.32s;
  -webkit-animation-delay: -9.32s;
  -moz-animation-delay: -9.32s;
  background-color: rgb(255, 136, 0);

}

.cssload-dots:nth-child(9):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -9.32s;
  -o-animation-delay: -9.32s;
  -ms-animation-delay: -9.32s;
  -webkit-animation-delay: -9.32s;
  -moz-animation-delay: -9.32s;
  background-color: rgb(44, 108, 94);
}

.cssload-dots:nth-child(10):before {
  transform: translateY(-200%);
  -o-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  animation: cssload-animBefore 1.15s linear infinite;
  -o-animation: cssload-animBefore 1.15s linear infinite;
  -ms-animation: cssload-animBefore 1.15s linear infinite;
  -webkit-animation: cssload-animBefore 1.15s linear infinite;
  -moz-animation: cssload-animBefore 1.15s linear infinite;
  animation-delay: -10.35s;
  -o-animation-delay: -10.35s;
  -ms-animation-delay: -10.35s;
  -webkit-animation-delay: -10.35s;
  -moz-animation-delay: -10.35s;
  background-color: rgb(255, 136, 0);

}

.cssload-dots:nth-child(10):after {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  animation: cssload-animAfter 1.15s linear infinite;
  -o-animation: cssload-animAfter 1.15s linear infinite;
  -ms-animation: cssload-animAfter 1.15s linear infinite;
  -webkit-animation: cssload-animAfter 1.15s linear infinite;
  -moz-animation: cssload-animAfter 1.15s linear infinite;
  animation-delay: -10.35s;
  -o-animation-delay: -10.35s;
  -ms-animation-delay: -10.35s;
  -webkit-animation-delay: -10.35s;
  -moz-animation-delay: -10.35s;
  background-color: rgb(44, 108, 94);
}




@keyframes cssload-animBefore {
  0% {
    transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  25% {
    transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  50% {
    transform: scale(1) translateY(200%);
    z-index: -1;
  }

  75% {
    transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  100% {
    transform: scale(1) translateY(-200%);
    z-index: -1;
  }
}

@-o-keyframes cssload-animBefore {
  0% {
    -o-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  25% {
    -o-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  50% {
    -o-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  75% {
    -o-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  100% {
    -o-transform: scale(1) translateY(-200%);
    z-index: -1;
  }
}

@-ms-keyframes cssload-animBefore {
  0% {
    -ms-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  25% {
    -ms-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  50% {
    -ms-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  75% {
    -ms-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  100% {
    -ms-transform: scale(1) translateY(-200%);
    z-index: -1;
  }
}

@-webkit-keyframes cssload-animBefore {
  0% {
    -webkit-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  25% {
    -webkit-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  50% {
    -webkit-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  75% {
    -webkit-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  100% {
    -webkit-transform: scale(1) translateY(-200%);
    z-index: -1;
  }
}

@-moz-keyframes cssload-animBefore {
  0% {
    -moz-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  25% {
    -moz-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  50% {
    -moz-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  75% {
    -moz-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  100% {
    -moz-transform: scale(1) translateY(-200%);
    z-index: -1;
  }
}

@keyframes cssload-animAfter {
  0% {
    transform: scale(1) translateY(200%);
    z-index: -1;
  }

  25% {
    transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  50% {
    transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  75% {
    transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  100% {
    transform: scale(1) translateY(200%);
    z-index: 1;
  }
}

@-o-keyframes cssload-animAfter {
  0% {
    -o-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  25% {
    -o-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  50% {
    -o-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  75% {
    -o-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  100% {
    -o-transform: scale(1) translateY(200%);
    z-index: 1;
  }
}

@-ms-keyframes cssload-animAfter {
  0% {
    -ms-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  25% {
    -ms-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  50% {
    -ms-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  75% {
    -ms-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  100% {
    -ms-transform: scale(1) translateY(200%);
    z-index: 1;
  }
}

@-webkit-keyframes cssload-animAfter {
  0% {
    -webkit-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  25% {
    -webkit-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  50% {
    -webkit-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  75% {
    -webkit-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  100% {
    -webkit-transform: scale(1) translateY(200%);
    z-index: 1;
  }
}

@-moz-keyframes cssload-animAfter {
  0% {
    -moz-transform: scale(1) translateY(200%);
    z-index: -1;
  }

  25% {
    -moz-transform: scale(0.7) translateY(0);
    z-index: -1;
  }

  50% {
    -moz-transform: scale(1) translateY(-200%);
    z-index: 1;
  }

  75% {
    -moz-transform: scale(1.3) translateY(0);
    z-index: 1;
  }

  100% {
    -moz-transform: scale(1) translateY(200%);
    z-index: 1;
  }
}








.datepicker-area {
  margin-top: 30px;
}

.datepicker-area .input-group-text {
  border: none;
  padding: 0px;
}

.datepicker-area .form-control {
  border-radius: 5px !important;
}

.aon-scrolling-nav {
  position: fixed;
  top: 50%;
  left: 0px;
  list-style: none;
  z-index: 99;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.aon-scrolling-nav li {
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  background: #ff8a00;
  margin: 3px 2px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  display: flex;
}

.aon-scrolling-nav li a {
  color: #fff;
  display: block;
  width: 100%;
  height: 100%;
}

.aon-scrolling-nav li a i {
  font-size: 18px;
}

.aon-scrolling-nav li span {
  opacity: 0;
  position: absolute;
  top: 4px;
  left: 100%;
  line-height: 1;
  background: #06328b;
  color: #fff;
  padding: 8px 10px;
  border-radius: 3px;
  white-space: nowrap;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.aon-scrolling-nav li span:before {
  content: "";
  position: absolute;
  top: 10px;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 5px 4px 0;
  border-color: transparent #06328b transparent transparent;
}


.aon-scrolling-nav li:hover {
  overflow: visible;
  background: #06328b;
}

.aon-scrolling-nav li.active {
  overflow: visible;
  background: #06328b;
}

.aon-scrolling-nav li:hover span {
  opacity: 1;
  left: 120%;
}

.aon-scrolling-nav li.actives span {
  opacity: 1;
  left: 120%;
}

.sf-doc-location-map {
  width: 100%;
  height: 100%;
}

.sf-doc-location-map iframe {
  width: 100%;
  height: 100%;
}






.aon-banner-wrap {
  overflow: hidden;
}

.aon-banner-pic,
.aon-banner-right {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.aon-banner-pic {
  -moz-transform: translateY(50%);
  -webkit-transform: translateY(50%);
  -o-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.aon-banner-right {
  -moz-transform: translateX(50%);
  -webkit-transform: translateX(50%);
  -o-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.aon-banner-animate .aon-banner-pic {
  -moz-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.aon-banner-animate .aon-banner-right {
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -o-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.aon-banner-wrap {
  overflow: hidden;
}

.aon-banner2-left,
.aon-banner2-pic {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.aon-banner2-pic {
  -moz-transform: translateY(50%);
  -webkit-transform: translateY(50%);
  -o-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.aon-banner2-left {
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.aon-banner2-animate .aon-banner2-pic {
  -moz-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.aon-banner2-animate .aon-banner2-left {
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  -o-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}


.aon-provi-video-box {
  border-radius: 10px;
  overflow: hidden;
  background: #000;
}

.aon-provi-video-box img {
  width: 100%;
  opacity: 0.8;
}

.sf-video-play-btn {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin: -25px;
  font-size: 24px;
  text-align: center;
  background: #ff8a00;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sf-video-play-btn i {
  color: #fff;
}

.aon-provi-video-box:hover .sf-video-play-btn {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-box-shadow: 0 0 0 50px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 50px rgba(255, 255, 255, 0.2);
}

.post-categories a {
  display: inline-block;
  border: 1px solid #ff8a00;
  padding: 5px 15px;
  color: #ff8a00;
  line-height: 1;
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.aon-med-shop-area {
  padding: 90px 0px;
  border-top: 1px solid #ddd;
}

/*404*/
.page-notfound {
  padding: 0px 30px;
  text-align: center;
}

.page-notfound .page-notfound-media {
  max-width: 500px;
  margin: 0px auto 50px;
  margin-bottom: 30px;
}

.page-notfound-content {
  margin: 0px auto;
  border: 10px solid #ff8a00;
  padding: 50px 30px;
}

.page-notfound-content .error-comment {
  font-size: 36px;
}

@media only screen and (max-width:640px) {
  .page-notfound-content .error-comment {
    font-size: 24px;
  }

  .page-notfound {
    padding: 0px;
  }

  .page-notfound-content {
    border: 5px solid #ff8a00;
    padding: 25px 20px;
  }
}

/*Comming Soon*/
.comming-soon-wrap {
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding: 30px 10px;
  align-items: center;
  background-image: url("../images/commin-soon.jpg");
  background-position: center top;
}

.comming-soon-wrap .container {
  position: relative;
  z-index: 1;
}

.comming-soon-overlay {
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1b605b+95,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(left, rgba(27, 96, 91, 1) 0%, rgba(27, 96, 91, 0.8) 45%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(27, 96, 91, 1) 0%, rgba(27, 96, 91, 0.8) 45%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(27, 96, 91, 1) 0%, rgba(27, 96, 91, 0.8) 45%, rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1b605b', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */
}

.comsoon-wrap {
  margin: 0px auto;
}

.comsoon-title {
  color: #fff;
  font-size: 50px;
  line-height: 1.1;
  font-weight: 800;
}

.comsoon-text {
  color: #fff;
  font-size: 20px;
  margin-bottom: 30px;
}

.comsoon-col {
  border: 2px solid #ff8a00;
  background: #ff8a00;
  padding: 10px;
  text-align: center;
  color: #ff8a00;
  border-radius: 5px;
}

.comsoon-col strong {
  display: block;
  font-size: 40px;
  font-weight: 800;
  color: #fff;
  line-height: 1.2;
}

.comsoon-col span {
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  line-height: 1.2;
}

.aon-news-letter {
  padding: 30px;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-top: 30px;
}

.aon-news-letter h4 {
  color: #fff;
}

.aon-news-letter .form-control {
  border-radius: 5px;
  padding-right: 100px;
}

.aon-news-letter .sf-sb-btn {
  border-radius: 0px 5px 5px 0px;
}

.sf-news-l-form.sf-news-cosoon {
  min-width: 100%;
}

.aon-bookappoint-btn,
.book-appointment-btn {
  display: block;
  width: calc(100% + 2px);
  border-radius: 5px 5px 0px 0px;
  margin: -1px -1px;
}



.aon-book-appoint-wrap {
  padding-right: 1px;
  margin-bottom: 30px;
}

.aon-book-appoint-block {
  padding: 20px;
  border: 1px solid #ebebeb;
  background: #fff;
  border-radius: 10px;
  transition: all 0.5s linear;
  position: relative;
  cursor: pointer;
}

.aon-book-appoint-block:hover {
  border: 1px solid #ff8a00;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, .1);
}

.aon-book-appoint-time {
  font-weight: 600;
  color: #ff8a00;
}

.aon-book-appoint-docname {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.aon-patient-status {
  width: 12px;
  height: 12px;
  display: table;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #4fb0df;
  border-radius: 10px;
}

.aon-patient-status.online {
  background: #5bd168;
}

.aon-patient-status.offline {
  background: #ec5052;
}

.aon-book-appoint-tooltip {
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
}

.aon-bookAppo-user {
  padding: 30px;
}

.aon-bookAppo-pic {
  width: 80px;
  height: 80px;
  float: left;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 15px;
}

.aon-bookAppo-balan {
  color: #ff8a00;
  text-decoration: underline;
  cursor: pointer;
}

.aon-bookAppo-eng {
  border: 1px solid #000;
  padding: 5px 15px;
  margin: 0px 30px 30px;
  text-align: center;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
}

.aon-bookAppo-coinfo {
  padding: 30px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.aon-bookAppo-time {
  background: #eaecf1;
  padding: 30px;
  color: #222;
}

.aon-bookAppo-plan {
  padding: 30px;
  color: #222;
  color: #000;
  font-weight: 800;
}

.aon-bokAp-plan-visit {
  color: #777;
}

.btn-primary.btn-red {
  background: #EC5052;
  border-color: #EC5052;
}

.btn-primary.btn-blue {
  background: #4FB0DF;
  border-color: #4FB0DF;
}

.btn-primary.btn-green {
  background: #5BD168;
  border-color: #5BD168;
}

.aon-book-allonof-btn {
  margin-right: 10px;
}

.aon-book-allonof-btn .btn-primary {
  font-size: 12px;
}

.aon-book-status-btn .btn-primary {
  font-size: 12px;
}

.rating-bx i {
  color: #ff8a00;
}


.cus-account-wrap {}

.cus-account-wrap #content {
  width: 100%;
}

.cus-account-wrap .content-admin-main {
  width: 100%;
  max-width: 1270px;
  margin: auto;
}

.cus-account-wrap #header-admin {
  width: 100%;
}

.cus-account-wrap .aon-admin-footer {
  width: 100%;
}


.cus-top-nav {
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 999;
}

.cus-top-nav+.content-admin-main {
  padding-top: 80px;
}

.cus-top-nav .container {
  width: 100%;
  max-width: 1270px;
}

.cus-top-nav .cus-nav {
  text-align: center;
  list-style: none;
  margin: 0px;
}

.cus-top-nav .cus-nav>li {
  padding: 0px 0px;
}

.cus-top-nav .cus-nav>li a {
  position: relative;
  display: block;
  padding: 10px 5px;
}

.cus-top-nav .cus-nav>li a:after {
  position: absolute;
  left: 0px;
  bottom: -1px;
  content: '';
  width: 0%;
  height: 2px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.cus-top-nav .cus-nav>li a:hover:after,
.cus-top-nav .cus-nav>li.active a:after {
  width: 100%;
  background-color: #ff8a00;
}

.cus-chan-pass-wrap {
  max-width: 500px;
  margin: auto;
  background: #fff;
  border-radius: 10px;
}

.cus-pai-regis-left {
  max-width: 220px;
}

@media only screen and (max-width:767px) {
  .cus-top-nav {
    position: static;
  }

  .cus-top-nav+.content-admin-main {
    padding-top: 30px !important;
  }

  .cus-top-nav .cus-nav>li {
    min-width: 50%;
    border: 1px solid #ddd;
    margin: -0.5px;
  }

  .cus-pai-regis-left {
    max-width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 0 0px 35px;
    margin: 0px 10px 30px;
  }

  .cus-pai-regis-left .aon-pro-avtar-pic {
    max-width: 220px;
    margin: auto;
  }

  .cus-pai-regis-right {
    min-width: 100%;
  }
}

@media only screen and (max-width:480px) {
  .cus-top-nav .cus-nav>li {
    min-width: 100%;
  }
}

.fc .fc-view-harness {
  flex-grow: 1;
  position: relative;
  background: #fff;
}

.fc-button-primary {
  background-color: #ff8a00 !important;
  border-color: #ff8a00 !important;
  outline: none !important;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}

@media only screen and (max-width:991px) {
  .fc .fc-toolbar.fc-header-toolbar {
    flex-wrap: wrap;
  }

  .fc-toolbar-chunk {
    width: 100% !important;
    margin-bottom: 15px;
  }

  .fc-toolbar-chunk:last-child {
    margin-bottom: 0px;
  }

  .aon-admin-heading {
    flex-wrap: wrap !important;
  }

  .aon-admin-heading-left,
  .aon-admin-heading-righr {
    width: 100%;
  }
}

@media only screen and (max-width:405px) {
  .aon-book-allonof-btn {
    margin-bottom: 15px;
  }
}




.datepicker table tr td.active.active,
.datepicker table tr td.active:hover,
.datepicker table tr td:hover {
  color: #fff;
  background-color: #ff8a00;
  border-color: #ff8a00;
}

.datepicker table tr td.active.active:hover {
  color: #fff;
  background-color: #ff8a00;
  border-color: #ff8a00;
}


.datepicker table tr td {
  font-size: 14px;
}

.datepicker table tr th {
  font-size: 12px;
}

.g-recaptcha {
  display: table;
  margin: 0px auto 20px;
}