.popup{
    position: fixed;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: slideInvoice 0.8s ease-in-out;
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 21px -2px
}

@keyframes slideInvoice {
    0% {
      top: -50%;
      transform: translate(-50%, -50%) scale(0.7);
    }
    70% {
      top: 50%;
      transform:translate(-50%, -50%) scale(0.8);
    }
    100% {
      top: 50%;
      transform:translate(-50%, -50%) scale(1);
    }
  }